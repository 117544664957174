import { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import ProcessSystemFilter from "./ProcessSystemFilter"
import ProcessSystemList from "./ProcessSystemList"

const ProcessSystem = () => {
  const history = useHistory()
  const location = useLocation()
  const urlSearchParams = new URLSearchParams(location.search)
  const selectedParam = urlSearchParams.get("selected")
  const [selected, setSelected] = useState(selectedParam || "systems")
  const [filters, setFilters] = useState({})
  const [counterUpdateKey, setCounterUpdateKey] = useState(false)
  const [cntSys, setCntSys] = useState("-")

  const validFilterParamNames = ["title", "description", "createdBy"]

  useEffect(() => {
    if (!selectedParam) {
      setSelected("systems")
    } else {
      setSelected(selectedParam)
    }
  }, [selectedParam])

  return (
    <>
      <ProcessSystemFilter
        handleCategory={(type) => {
          setSelected(type)
          setFilters({})

          urlSearchParams.set("selected", type)
          urlSearchParams.set("page", 1)

          validFilterParamNames.forEach((name) => {
            urlSearchParams.delete(name)
          })

          history.push({ search: urlSearchParams.toString() })
        }}
        handleFilter={(filters) => {
          setFilters(filters)

          urlSearchParams.set("page", 1)

          for (const [key, value] of Object.entries(filters)) {
            if (validFilterParamNames.includes(key) && value) {
              urlSearchParams.set(key, value)
            } else {
              urlSearchParams.delete(key)
            }
          }

          history.push({ search: urlSearchParams.toString() })
        }}
        counterUpdateKey={counterUpdateKey}
        selectedParam={selectedParam}
        counter={cntSys}
      />
      {selected === "systems" ? (
        <ProcessSystemList
          filters={filters}
          onDelete={() => {
            setCounterUpdateKey(Math.random())
          }}
          updateCounter={(counter) => {
            setCntSys(counter)
          }}
        />
      ) : (
        ""
      )}
    </>
  )
}

export default ProcessSystem
