import { CButton, CCard, CCardBody, CSelect } from "@coreui/react"
import { useRef, useState } from "react"
import ProcessSystemRunPreviewProcessGenealogy from "../process-system/run/preview/publish/ProcessSystemRunPreviewProcessGenealogy"
import ProductExplorerSidebar from "./sidebar/ProductExplorerSidebar"
import { setInventorySidebarExpanded, setLoading } from "src/redux/slices/uiSlice"
import getScaledCredits from "src/requests/attestation-unit/GetScaledCredits"
import getScaledCreditsShare from "src/requests/attestation-unit/GetScaledCreditsShare"
import getScaledCreditsShareExternal from "src/requests/attestation-unit/GetScaledCreditsShareExternal"
import getReport from "src/requests/GetReport"
import getReportShare from "src/requests/GetReportShare"
import getReportShareExternal from "src/requests/GetReportShareExternal"
import apiCallWrapper from "src/helpers/ApiCallWrapper"
import { useDispatch } from "react-redux"
import { getTreeMapDataFromProcesses } from "src/helpers/ChartsHelper"
import ProcessSystemRunPreviewProcessTreeMap from "../process-system/run/preview/ProcessSystemRunPreviewProcessTreeMap"
import ProcessSystemRunPreviewProcessBarChart from "../process-system/run/preview/ProcessSystemRunPreviewProcessBarChart"
import { useHistory } from "react-router-dom"
import ProductExplorerCalculator from "./ProductExplorerCalculator"
import ProcessSystemRunPreviewProcessFlow from "../process-system/run/preview/ProcessSystemRunPreviewProcessFlow"
import requestHelper from "src/helpers/RequestHelper"
import Report from "../report/Report"
import ArrowRight from "../../assets/icons/report-arrow-right.svg"
import ArrowLeft from "../../assets/icons/report-arrow-left.svg"
import carbonSigLoader from "src/assets/carbon-sig-loader.gif"
import html2canvas from "html2canvas"
import jsPDF from "jspdf"
import ButtonDownload from "src/assets/icons/button-download.svg"

const ProductExplorer = ({
  genealogy,
  showShareButton,
  chartData,
  onCancel,
  onShare,
  isUrlShare,
  isExternalShare,
  isPublic,
  shareId,
  shareType,
  password,
  showShareIcon,
}) => {
  let history = useHistory()
  const dispatch = useDispatch()
  const [sidebarExpanded, setSidebarExpanded] = useState(true)
  const [genealogyItem, setGenealogyItem] = useState({})
  const [subTree, setSubTree] = useState({})
  const [ownerCredits, setOwnerCredits] = useState([])
  const [inheritedCredits, setInheritedCredits] = useState([])
  const [report, setReport] = useState({})
  const [showCalculatorModal, setShowCalculatorModal] = useState(false)
  const [showButtons, setShowButtons] = useState(false)
  const [page, setPage] = useState(0)
  const [totalPages, setTotalPages] = useState(1)
  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false)
  const [generate, setGenerate] = useState(false)
  const targetRef = useRef(null)

  const hasSharePermission =
    requestHelper.hasRole("Admin") ||
    requestHelper.hasRole("Data Manager") ||
    requestHelper.hasRole("Attester") ||
    requestHelper.hasRole("Publisher")

  const getContainerClass = (expanded) => {
    if (expanded) {
      return "inventory-details"
    } else {
      return "inventory-details-sidebar-collapsed"
    }
  }

  const [selectedTab, setSelectedTab] = useState("genealogy")

  const getSelectedTabContent = (selectedTab) => {
    switch (selectedTab) {
      case "genealogy":
        return (
          <>
            <ProcessSystemRunPreviewProcessGenealogy
              genealogy={genealogy}
              shareType={shareType}
              showShareIcon={showShareIcon}
              onSelect={(object, subTree) => {
                setGenealogyItem({})
                setOwnerCredits([])
                setInheritedCredits([])
                setSubTree({})
                if (object.data.type === "inventory") {
                  setGenealogyItem(object.data)
                  if (object.data.published) {
                    if (!isExternalShare) {
                      const attestationUnitId = isUrlShare ? shareId : object.data.attestationUnitId
                      getReportHandler(attestationUnitId)
                      getScaledCreditsHandler(attestationUnitId, object.data.quantity)
                    } else {
                      getReportShareExternalHandler()
                      getScaledCreditsShareExternalHandler(object.data.quantity)
                    }
                  }
                } else {
                  setSubTree(subTree)
                }
                setSidebarExpanded(true)
                dispatch(setInventorySidebarExpanded(true))
              }}
              onDeselect={() => {
                setSidebarExpanded(false)
                dispatch(setInventorySidebarExpanded(false))
              }}
            />
          </>
        )
      case "sankey":
        return (
          <>
            <CCard className="cs-card">
              <CCardBody>
                <ProcessSystemRunPreviewProcessFlow
                  data={chartData?.sankeyData}
                  width={window.innerWidth - 406}
                  height={670}
                  title={`Carbon Emissions Flow for ${genealogy.title}`}
                />
              </CCardBody>
            </CCard>
          </>
        )
      case "bar-chart":
        return (
          <>
            {genealogy && (
              <ProcessSystemRunPreviewProcessBarChart
                data={chartData?.barChartData}
                title={genealogy.title}
              />
            )}
          </>
        )
      case "tree-map":
        return (
          <>
            {genealogy && (
              <ProcessSystemRunPreviewProcessTreeMap
                data={getTreeMapDataFromProcesses(chartData?.barChartData)}
                title={genealogy.title}
                width={window.innerWidth - 422}
                height={650}
              />
            )}
          </>
        )
      case "attestation-certificate":
        if (genealogyItem.published) {
          if (isPublic) {
            if (isExternalShare) {
              return (
                <>
                  {genealogy && (
                    <Report
                      showPreview={false}
                      isExternalShare={true}
                      currentPage={page}
                      onTotalPageChange={(total) => setTotalPages(total)}
                      generate={generate}
                      onFinishGenerate={(value) => {
                        setGenerate(!value)
                        setIsGeneratingPdf(!value)
                      }}
                    />
                  )}
                </>
              )
            } else {
              if (isUrlShare) {
                return (
                  <>
                    {genealogy && (
                      <Report
                        showPreview={false}
                        isUrlShare={true}
                        currentPage={page}
                        onTotalPageChange={(total) => setTotalPages(total)}
                        generate={generate}
                        onFinishGenerate={(value) => {
                          setGenerate(!value)
                          setIsGeneratingPdf(!value)
                        }}
                      />
                    )}
                  </>
                )
              } else {
                return (
                  <>
                    {genealogy && (
                      <Report
                        showPreview={false}
                        currentPage={page}
                        onTotalPageChange={(total) => setTotalPages(total)}
                        generate={generate}
                        onFinishGenerate={(value) => {
                          setGenerate(!value)
                          setIsGeneratingPdf(!value)
                        }}
                      />
                    )}
                  </>
                )
              }
            }
          } else {
            return (
              <>
                {genealogy && (
                  <Report
                    showPreview={false}
                    currentPage={page}
                    onTotalPageChange={(total) => setTotalPages(total)}
                    generate={generate}
                    onFinishGenerate={(value) => {
                      setGenerate(!value)
                      setIsGeneratingPdf(!value)
                    }}
                  />
                )}
              </>
            )
          }
        }
      default:
        return null
    }
  }

  const getScaledCreditsHandler = (id, quantity) => {
    const request = isUrlShare ? getScaledCreditsShare : getScaledCredits
    apiCallWrapper.call(request, dispatch, {
      successHandler: (jsonData) => {
        setOwnerCredits(jsonData.filter((x) => !x.inherited))
        setInheritedCredits(jsonData.filter((x) => x.inherited))
      },
      params: {
        id: id,
        quantity: quantity,
      },
      showErrorMessage: false,
    })
  }

  const getScaledCreditsShareExternalHandler = (quantity) => {
    apiCallWrapper.call(getScaledCreditsShareExternal, dispatch, {
      successHandler: (jsonData) => {
        setOwnerCredits(jsonData.filter((x) => !x.inherited))
        setInheritedCredits(jsonData.filter((x) => x.inherited))
      },
      params: {
        shareId: shareId,
        password: password,
        quantity: quantity,
      },
      showErrorMessage: false,
    })
  }

  const getReportHandler = (id) => {
    const request = isUrlShare ? getReportShare : getReport
    apiCallWrapper.call(request, dispatch, {
      successHandler: (jsonData) => {
        setReport(jsonData)
      },
      params: {
        id: id,
      },
      showErrorMessage: false,
    })
  }

  const getReportShareExternalHandler = () => {
    apiCallWrapper.call(getReportShareExternal, dispatch, {
      successHandler: (jsonData) => {
        setReport(jsonData)
      },
      params: {
        shareId: shareId,
        password: password,
      },
      showErrorMessage: false,
    })
  }

  const renderNextPreviousArrows = (show) => {
    return (
      <>
        <img
          src={ArrowLeft}
          className="footer-images"
          alt="left"
          onClick={() => {
            setPage((prev) => (prev !== 0 ? prev - 1 : 0))
          }}
        />
        <img
          src={ArrowRight}
          className="footer-images"
          alt="right"
          style={{ marginLeft: show ? "10px" : "" }}
          onClick={() => {
            setPage((prev) => (prev !== totalPages - 1 ? prev + 1 : totalPages - 1))
          }}
        />
      </>
    )
  }

  const generatePDF = (element, title) => {
    html2canvas(element, {
      scale: 2,
      backgroundColor: "#ebedef",
      ignoreElements: (element) => {
        return element.classList.contains("flow-controls")
      },
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg", 1.0)

      const imgWidth = canvas.width
      const imgHeight = canvas.height
      const pdfWidth = imgWidth * 0.43
      const pdfHeight = imgHeight * 0.43

      const pdf = new jsPDF("l", "px", [pdfWidth, pdfHeight])
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight)
      pdf.save(`${title} - ${selectedTab}.pdf`)
    })
  }

  const handleDownload = () => {
    generatePDF(targetRef.current, genealogy.title)
  }

  const tabs = [
    { id: "genealogy", name: "Genealogy" },
    { id: "sankey", name: "Sankey" },
    { id: "bar-chart", name: "Bar Chart" },
    { id: "tree-map", name: "Tree Map" },
    { id: "attestation-certificate", name: "Certificate" },
  ]

  const restrictedTabs = [
    { id: "genealogy", name: "Genealogy" },
    { id: "sankey", name: "Sankey" },
    { id: "bar-chart", name: "Bar Chart" },
    { id: "tree-map", name: "Tree Map" },
  ]

  const handleGoBack = () => {
    history.goBack()
  }

  return (
    <>
      <div className={getContainerClass(sidebarExpanded)}>
        <div className="bar-wrapper">
          <div>
            {!genealogyItem.published
              ? restrictedTabs.map((tab) => (
                  <span
                    className={`tab-item ${selectedTab === tab.id ? "active-tab" : ""}`}
                    onClick={() => {
                      setSelectedTab(tab.id)
                      setSidebarExpanded(tab.id === "genealogy")
                    }}
                  >
                    {tab.name}
                  </span>
                ))
              : tabs.map((tab) => (
                  <span
                    id={tab.id}
                    className={`tab-item ${selectedTab === tab.id ? "active-tab" : ""}`}
                    onClick={() => {
                      if (tab.id === "attestation-certificate") {
                        setShowButtons(true)
                      } else {
                        setShowButtons(false)
                      }
                      setSelectedTab(tab.id)
                      setSidebarExpanded(tab.id === "genealogy")
                    }}
                  >
                    {tab.name}
                  </span>
                ))}
            {}
          </div>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <div className="d-flex mr-4">
              {genealogyItem.published && showButtons ? renderNextPreviousArrows(true) : null}
            </div>
            <CButton
              style={{
                display: "flex",
                alignItems: "center",
                whiteSpace: "nowrap",
              }}
              className="btn btn-pill btn-save-changes mr-2"
              onClick={() => {
                setShowCalculatorModal(true)
              }}
            >
              Decarbonization Calculator
            </CButton>
            {!showButtons ? (
              <CButton
                style={{ display: "flex", alignItems: "center" }}
                className="btn btn-pill btn-save-changes mr-2"
                onClick={handleDownload}
              >
                <img src={ButtonDownload} alt="" className="mr-2" height={16} />
                Download
              </CButton>
            ) : null}
            {genealogyItem.published && showButtons ? (
              <CButton
                style={{
                  display: "flex",
                  alignItems: "center",
                  whiteSpace: "nowrap",
                }}
                className="btn btn-pill btn-save-changes mr-2"
                onClick={() => {
                  setGenerate(true)
                  setIsGeneratingPdf(true)
                }}
                disabled={isGeneratingPdf}
              >
                {isGeneratingPdf ? (
                  <img
                    src={carbonSigLoader}
                    alt="Loading Indicator"
                    height={20}
                    width={20}
                    style={{ borderRadius: "50%", marginRight: "10px", marginLeft: "-10px" }}
                  />
                ) : null}
                Save as PDF
              </CButton>
            ) : null}

            {showShareButton && hasSharePermission ? (
              <CButton
                style={{ display: "flex", alignItems: "center" }}
                className="btn btn-pill btn-save-changes mr-2"
                onClick={() => {
                  onShare()
                }}
              >
                Share
              </CButton>
            ) : (
              ""
            )}
            {!isExternalShare ? (
              <CButton
                shape="pill"
                color="secondary"
                className={"btn btn-filter-secondary shadow-sm mr-3"}
                onClick={() => {
                  onCancel()
                }}
              >
                Back
              </CButton>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="the-content" ref={targetRef}>
          {getSelectedTabContent(selectedTab)}
        </div>
      </div>
      <ProductExplorerSidebar
        ownerCredits={ownerCredits}
        inheritedCredits={inheritedCredits}
        tenantData={{}}
        genealogyItem={genealogyItem}
        attestationUnit={report}
        expanded={sidebarExpanded}
        subTree={subTree}
        isPublic={isPublic}
        isUrlShare={isUrlShare}
        isExternalShare={isExternalShare}
        shareId={shareId}
      />
      <ProductExplorerCalculator
        showModal={showCalculatorModal}
        genealogyItem={genealogyItem}
        onClose={() => setShowCalculatorModal(false)}
      />
    </>
  )
}

export default ProductExplorer
