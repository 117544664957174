import { CCol, CRow } from "@coreui/react"
import React, { useEffect, useState } from "react"
import CarbonPagination from "./CarbonPagination"

const PaginationSection = ({ totalPages, onChange, pageParam, sizeParam }) => {
  const defaultPageSize = sizeParam ? Number(sizeParam) : 10
  const defaultActivePage = pageParam ? Number(pageParam) : 1
  const [activePage, setActivePage] = useState(defaultActivePage)
  const [pageSize, setPageSize] = useState(defaultPageSize)

  const pageChange = (newPage) => {
    if (newPage) {
      setActivePage(newPage)
    }
  }

  const paginationSelect = {
    label: "Showing:",
    values: [5, 10, 20, 50],
  }

  useEffect(() => {
    onChange(activePage, pageSize)
  }, [activePage, pageSize])

  useEffect(() => {
    if (!pageParam) return
    pageChange(Number(pageParam))
  }, [pageParam])

  useEffect(() => {
    if (!sizeParam) return
    setPageSize(Number(sizeParam))
  }, [sizeParam])

  return (
    <CRow className="align-items-center">
      <CCol md="4" />
      <CCol md={"4"}>
        {totalPages && totalPages > 0 ? (
          <CarbonPagination
            activePage={activePage}
            onActivePageChange={pageChange}
            pages={totalPages}
          />
        ) : null}
      </CCol>
      <CCol
        style={{
          padding: "5px 15px",
        }}
        md="4"
      >
        <div className="form-inline justify-content-sm-end c-datatable-items-per-page">
          <label className="mfe-2">{paginationSelect.label}</label>
          <select
            className="form-control"
            onChange={(e) => {
              setPageSize(Number(e.target.value))
              setActivePage(1)
            }}
            aria-label="changes number of visible items"
            value={pageSize}
          >
            {paginationSelect.values.map((number, key) => {
              return (
                <option value={number} key={key}>
                  {number}
                </option>
              )
            })}
          </select>
        </div>
      </CCol>
    </CRow>
  )
}

export default PaginationSection
