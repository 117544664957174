import React, { Component } from "react"
import { HashRouter, Route, Switch } from "react-router-dom"
import PrivateRoute from "./PrivateRoute"
import "./scss/style.scss"
import "../src/views/report/report.css"
import "flatpickr/dist/themes/airbnb.css"
import "@fontsource/roboto"
import PublicLayout from "./containers/public/PublicLayout"
import Report from "./views/report/Report"
import envHelper from "./helpers/EnvHelper"
import { useSelector } from "react-redux"
import requestHelper from "./helpers/RequestHelper"
import { ProductFruits } from "react-product-fruits"
import useAppEnv from "./hooks/useAppEnv"
import AttestationUnitGenealogy from "./views/attestation-unit/AttestationUnitGenealogy"
import AttestationUnitGenealogyForShare from "./views/attestation-unit/AttestationUnitGenealogyForShare"
import AttestationUnitGenealogyForShareExternal from "./views/attestation-unit/AttestationUnitGenealogyForShareExternal"

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"))

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"))
const LoginSSO = React.lazy(() => import("./views/pages/login/LoginSso"))

const RegistrationWrapper = React.lazy(() => import("./views/pages/register/RegisterWrapper"))
const ConfirmTenantInvitation = React.lazy(() =>
  import("./views/tenant-onboarding/ConfirmTenantInvitation")
)
const Confirm = React.lazy(() => import("./views/pages/confirm/Confirm"))
const ConfirmExternalUser = React.lazy(() =>
  import("./views/pages/external-user/ConfirmExternalUser")
)
const RejectExternalUser = React.lazy(() =>
  import("./views/pages/external-user/RejectExternalUser")
)

const QRCodeScan = React.lazy(() => import("./views/pages/qrcode-scan/QRCodeAccess"))

const QRCodeSuccessRedirect = React.lazy(() =>
  import("./views/pages/qrcode-scan/QRCodeSuccessRedirect")
)

const QRCodeRestricted = React.lazy(() => import("./views/pages/qrcode-scan/QRCodeRestricted"))

const Workspaces = React.lazy(() => import("./views/pages/external-user/Workspaces"))
const ResetPassword = React.lazy(() => import("./views/pages/reset/ResetPassword"))
const ConfirmResetPassword = React.lazy(() => import("./views/pages/reset/ConfirmResetPassword"))
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"))
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"))
const Landing = React.lazy(() => import("./views/pages/landing/Landing"))
const RequestForInformation = React.lazy(() =>
  import("./views/library/request-for-information/RequestForInformation")
)

const App = () => {
  const user = useSelector((state) => state.user.data)
  const { isProductionEnv, currentEnvData } = useAppEnv()
  const isSandboxEnv = envHelper.isSandbox()
  const usernameHash = requestHelper.currentUserUsernameHash()

  const initializeProductFruits = (
    user,
    usernameHash,
    isSandboxEnv,
    isProductionEnv,
    currentEnvData
  ) => {
    if (!user || !usernameHash) return null

    const userInfo = {
      username: usernameHash,
      signUpAt: user.confirmationDate,
    }

    window.productFruitsReady = function () {
      const productFruitsContainer = document.querySelector(".productfruits--container")

      let style = document.createElement("style")
      style.innerHTML = `.productfruits--feedback-button { left: 0px !important; bottom: 70px !important; } 
      .productfruits--feedback-popup { left: 70px !important; }`

      productFruitsContainer.shadowRoot.appendChild(style)
    }

    if (user && usernameHash) {
      if (isSandboxEnv) {
        return (
          <ProductFruits
            workspaceCode="gTudjE7K5aFUkqa3"
            language="en"
            user={userInfo}
            config={{
              hideInAppCenterLauncher: true,
            }}
          />
        )
      }
      if (currentEnvData?.label === "staging") {
        return (
          <ProductFruits
            workspaceCode="f48PSKcAu4926aKB"
            language="en"
            user={userInfo}
            config={{
              hideInAppCenterLauncher: true,
            }}
          />
        )
      }
      if (!isSandboxEnv && isProductionEnv) {
        return (
          <ProductFruits
            workspaceCode="odOVKxozYaWstWkC"
            language="en"
            user={userInfo}
            config={{
              hideInAppCenterLauncher: true,
            }}
          />
        )
      }
    }
  }

  const isCertsUrl = () => {
    return window.location.href.startsWith("https://certs.")
  }

  return (
    <>
      {initializeProductFruits(user, usernameHash, isSandboxEnv, isProductionEnv, currentEnvData)}
      <HashRouter>
        <React.Suspense fallback={loading}>
          {isCertsUrl() ? (
            <Switch>
              <Route
                exact
                path="/"
                name="Landing Page"
                render={(props) => <Landing {...props} />}
              />
              <Route
                exact
                path="/:id"
                name="Public Inventory"
                render={(props) => {
                  const props2 = { ...props }
                  props2.isPublic = true
                  return <PublicLayout content={<AttestationUnitGenealogy {...props2} />} />
                }}
              />
              <Route
                exact
                path="/share/:shareId"
                name="Public Inventory"
                render={(props) => {
                  const props2 = { ...props }
                  props2.isPublic = true
                  return <PublicLayout content={<AttestationUnitGenealogyForShare {...props2} />} />
                }}
              />
              <Route
                exact
                path="/share-external/:shareId"
                name="Public Inventory"
                render={(props) => {
                  const props2 = { ...props }
                  props2.isPublic = true
                  return (
                    <PublicLayout
                      content={<AttestationUnitGenealogyForShareExternal {...props2} />}
                    />
                  )
                }}
              />
              <Route
                exact
                path="/public-report/get/:uid"
                name="Public Report"
                render={(props) => <PublicLayout content={<Report {...props} />} />}
              />
              <Route
                exact
                path="/public-report/share/get/:uid"
                name="Public Report"
                render={(props) => (
                  <PublicLayout content={<Report {...props} isUrlShare={true} />} />
                )}
              />
              <Route
                exact
                path="/public-report/share-external/get/:uid"
                name="Public Report"
                render={(props) => (
                  <PublicLayout content={<Report {...props} isExternalShare={true} />} />
                )}
              />
            </Switch>
          ) : (
            <Switch>
              <Route
                exact
                path="/login"
                name="Login Page"
                render={(props) => <Login {...props} />}
              />
              {envHelper.isSandbox() ? (
                <Route
                  exact
                  path="/register"
                  name="Register Page"
                  render={(props) => <RegistrationWrapper {...props} />}
                />
              ) : (
                ""
              )}
              <Route
                exact
                path="/confirm-tenant-invitation/:code"
                name="Confirm Tenant Invitation"
                render={(props) => <ConfirmTenantInvitation {...props} />}
              />
              <Route
                exact
                path="/confirm/:code"
                name="Confirm Page"
                render={(props) => <Confirm {...props} />}
              />
              <Route
                exact
                path="/confirm-external-user/:code"
                name="Confirm External User Page"
                render={(props) => <ConfirmExternalUser {...props} />}
              />
              <Route
                exact
                path="/reject-external-user/:code"
                name="Reject External User Page"
                render={(props) => <RejectExternalUser {...props} />}
              />
              <Route
                exact
                path="/qr-code-scan/:uid"
                name="QR code scanned"
                render={(props) => <QRCodeScan {...props} />}
              />
              <Route
                exact
                path="/success-redirect"
                name="Redirect after scan QR code"
                render={(props) => <QRCodeSuccessRedirect {...props} />}
              />
              <Route
                exact
                path="/restricted-access"
                name="Restricted access"
                render={(props) => <QRCodeRestricted {...props} />}
              />
              <PrivateRoute
                exact
                path="/workspaces"
                name="Workspaces"
                render={(props) => <Workspaces {...props} />}
              />
              <Route
                exact
                path="/create-reset-link"
                name="Create Reset Link"
                render={(props) => <ResetPassword {...props} />}
              />
              <Route
                exact
                path="/reset-password/:code"
                name="Reset Password"
                render={(props) => <ConfirmResetPassword {...props} />}
              />
              <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
              <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
              <Route
                exact
                path="/public/landing"
                name="Landing Page"
                render={(props) => <Landing {...props} />}
              />
              <Route
                exact
                path="/auth/sso"
                name="Login Sso"
                render={(props) => <LoginSSO {...props} />}
              />
              <Route
                exact
                path="/public/inventory/:id"
                name="Public Inventory"
                render={(props) => {
                  const props2 = { ...props }
                  props2.isPublic = true
                  return <PublicLayout content={<AttestationUnitGenealogy {...props2} />} />
                }}
              />
              <Route
                exact
                path="/public/share/:shareId"
                name="Public Inventory"
                render={(props) => {
                  const props2 = { ...props }
                  props2.isPublic = true
                  return <PublicLayout content={<AttestationUnitGenealogyForShare {...props2} />} />
                }}
              />
              <Route
                exact
                path="/public/share-external/:shareId"
                name="Public Inventory"
                render={(props) => {
                  const props2 = { ...props }
                  props2.isPublic = true
                  return (
                    <PublicLayout
                      content={<AttestationUnitGenealogyForShareExternal {...props2} />}
                    />
                  )
                }}
              />
              <Route
                exact
                path="/public/rfi/:uuid"
                name="RFI Public Form"
                render={(props) => {
                  const props2 = { ...props }
                  props2.isPublic = true
                  return <PublicLayout content={<RequestForInformation {...props2} />} />
                }}
              />
              <Route
                exact
                path="/public-report/get/:uid"
                name="Public Report"
                render={(props) => <PublicLayout content={<Report {...props} />} />}
              />
              <Route
                exact
                path="/public-report/share/get/:uid"
                name="Public Report"
                render={(props) => (
                  <PublicLayout content={<Report {...props} isUrlShare={true} />} />
                )}
              />
              <Route
                exact
                path="/public-report/share-external/get/:uid"
                name="Public Report"
                render={(props) => (
                  <PublicLayout content={<Report {...props} isExternalShare={true} />} />
                )}
              />
              <Route
                exact
                path="/generate-report"
                name="Generate Report"
                render={(props) => <div>Generate Report</div>}
              />
              <PrivateRoute path="/" name="Home" render={(props) => <TheLayout {...props} />} />
            </Switch>
          )}
        </React.Suspense>
      </HashRouter>
    </>
  )
}

export default App
