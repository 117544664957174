import apiCallWrapper from "src/helpers/ApiCallWrapper"
import { useDispatch } from "react-redux"
import "reactflow/dist/style.css"
import getAttestationUnitGenealogy from "src/requests/attestation-unit/GetAttestationUnitGenealogy"
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min"
import ProductExplorer from "../product-explorer/ProductExplorer"
import { useEffect, useState } from "react"
import objectHelper from "src/helpers/ObjectHelper"
import authHelper from "src/helpers/AuthHelper"
import getAttestationUnitShare from "src/requests/attestation-unit-share/GetAttestationUnitShare"
import getInventoryItem from "src/requests/inventory/GetInventoryItem"

const AttestationUnitGenealogy = ({ isPublic }) => {
  const dispatch = useDispatch()
  let history = useHistory()
  let { id } = useParams()
  const [genealogy, setGenealogy] = useState({})
  const [chartData, setChartData] = useState({})
  const [isOwner, setIsOwner] = useState(false)
  const [shareType, setShareType] = useState(false)
  const [isArchived, setIsArchived] = useState(false)
  const getAttestationUnitGenealogyHandler = (id) => {
    apiCallWrapper.call(getAttestationUnitGenealogy, dispatch, {
      successHandler: (jsonData) => {
        setGenealogy(jsonData.genealogy)
        setChartData(jsonData.chartData)
        setIsOwner(jsonData.genealogy.tenantId === authHelper.getTenantId())
      },
      params: {
        id: id,
      },
    })
  }

  const getAttestationUnitShareHandler = (id) => {
    apiCallWrapper.call(getAttestationUnitShare, dispatch, {
      successHandler: (jsonData) => {
        setShareType(parseInt(jsonData.shareType))
      },
      params: {
        id: id,
      },
    })
  }

  const getInventoryItemHandler = (id) => {
    apiCallWrapper.call(getInventoryItem, dispatch, {
      successHandler: (jsonData) => {
        setIsArchived(jsonData.archived)
      },
      params: {
        id: id,
      },
    })
  }

  useEffect(() => {
    getAttestationUnitGenealogyHandler(id)
    getAttestationUnitShareHandler(id)
    getInventoryItemHandler(id)

    const isMobile = () => {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
      ) {
        return true
      }
      return false
    }

    if (isMobile()) {
      window.location.href = "https://carbonsig.com/"
      return
    }
  }, [])

  return (
    <>
      {!objectHelper.isEmptyObject(genealogy) ? (
        <ProductExplorer
          genealogy={genealogy}
          showBackButton={false}
          showShareButton={isOwner && !isArchived}
          chartData={chartData}
          isPublic={isPublic}
          showShareIcon={true}
          onCancel={() => history.goBack()}
          shareType={shareType}
          onShare={() =>
            history.push({
              pathname: `/attestation-unit/share/${id}`,
              state: { genealogy: genealogy },
            })
          }
        />
      ) : (
        ""
      )}
    </>
  )
}

export default AttestationUnitGenealogy
