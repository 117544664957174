import request from "../Request"

const getInventoryItem = (params, completedCallback, errorCallback) => {
  request.call({
    path: "/inventory/api/attestation-unit/" + params.id,
    method: "get",
    completedCallback: completedCallback,
    errorCallback: errorCallback,
  })
}

export default getInventoryItem
