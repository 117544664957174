import { CCard, CCardBody } from "@coreui/react"
import HeadingList2 from "src/assets/icons/heading-list2.svg"
import numberFormat from "src/helpers/NumberFormat"
import CarbonDataTable from "src/reusable/CarbonDataTable"

const ProcessSystemRunPreviewProcessBarChart = ({ data, title }) => {
  const getBarWidth = (data, value) => {
    const allValues = [
      ...data.map((d) => d.source),
      ...data.map((d) => d.sink),
      ...data.map((d) => d.credit),
    ]
    const max = Math.max(...allValues)
    return (value * 100) / max
  }

  const getPercentage = (data, field, value) => {
    const total = data
      .map((d) => d[field])
      .reduce((acc, curr) => {
        return acc + curr
      }, 0)
    return ((value * 100) / total).toFixed(2)
  }

  const BarItem = ({ name, width, percentage, item }) => {
    const colors = { source: "#00a2e6", sink: "#00b995", credit: "#f5a623" }

    return (
      <div style={{ position: "relative", width: "100%" }}>
        <div
          style={{
            backgroundColor: colors[name],
            width: `${Math.ceil(width)}%`,
            height: 12,
            zIndex: 1,
            borderRadius: 2,
          }}
        ></div>
        <div
          style={{
            position: "relative",
            marginTop: 4,
            padding: "0 4px",
            color: "#333",
            zIndex: 2,
            lineHeight: "normal",
          }}
        >
          {item[name] !== 0
            ? `${numberFormat.localeString(item[name])} kg CO2e (${numberFormat.localeString(
                percentage
              )}%)`
            : "-"}
        </div>
      </div>
    )
  }

  return (
    <CCard className="cs-card">
      <CCardBody>
        <div className="heading mb-4">
          <img src={HeadingList2} className="heading-img" alt="icon-input" />
          Rank weight contributors list for {title}
        </div>
        <div>
          <CarbonDataTable
            items={data}
            fields={[
              {
                key: "contributor",
                label: "Contributor",
                _style: {
                  width: 300,
                },
              },
              {
                key: "process",
                label: "Process",
                _style: {
                  width: 300,
                },
              },
              {
                key: "scope",
                label: "Scope",
                _style: {
                  width: 100,
                },
              },
              {
                key: "source",
                label: "Source",
                _style: {
                  width: 300,
                },
              },
              {
                key: "sink",
                label: "Sink",
                _style: {
                  width: 300,
                },
              },
              {
                key: "credit",
                label: "Credit",
                _style: {
                  width: 300,
                  textAlign: "left",
                },
              },
            ]}
            scopedSlots={{
              source: (item) => {
                const width = getBarWidth(data, item.source)
                return (
                  <td className="carbon-table-column">
                    <BarItem
                      name="source"
                      width={width}
                      percentage={getPercentage(data, "source", item.source)}
                      item={item}
                    />
                  </td>
                )
              },
              sink: (item) => {
                const width = getBarWidth(data, item.sink)
                return (
                  <td className="carbon-table-column">
                    <BarItem
                      name="sink"
                      width={width}
                      percentage={getPercentage(data, "sink", item.sink)}
                      item={item}
                    />
                  </td>
                )
              },
              credit: (item) => {
                const width = getBarWidth(data, item.credit)
                return (
                  <td className="carbon-table-column">
                    <BarItem
                      name="credit"
                      width={width}
                      percentage={getPercentage(data, "credit", item.credit)}
                      item={item}
                    />
                  </td>
                )
              },
            }}
          />
        </div>
      </CCardBody>
    </CCard>
  )
}

export default ProcessSystemRunPreviewProcessBarChart
