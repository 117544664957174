import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  sidebarShow: "responsive",
  sidebarExpanded: false,
  inventorySidebarExpanded: true,
  asideShow: false,
  termsModalShow: false,
  loading: false,
  errorMessage: "",
  successMessage: "",
  errorType: ""
}

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setSidebarShow: (state, action) => {
      state.sidebarShow = action.payload
    },
    setSidebarExpanded: (state, action) => {
      state.sidebarExpanded = action.payload
    },
    setInventorySidebarExpanded: (state, action) => {
      state.inventorySidebarExpanded = action.payload
    },
    setAsideShow: (state, action) => {
      state.asideShow = action.payload
    },
    setTermsModalShow: (state, action) => {
      state.termsModalShow = action.payload
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setErrorMessage: (state, action) => {
      if(action.payload.message !== undefined){
        state.errorMessage = action.payload.message
        state.errorType = action.payload.errorType
      }
      else state.errorMessage = action.payload
      
    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload
    },
  },
})

export const {
  setSidebarShow,
  setSidebarExpanded,
  setInventorySidebarExpanded,
  setAsideShow,
  setTermsModalShow,
  setLoading,
  setErrorMessage,
  setSuccessMessage,
} = uiSlice.actions

export default uiSlice.reducer
