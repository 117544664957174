const stringHelper = (function () {
  return {
    ucfirst: function (str) {
      if (str) {
        return str[0].toUpperCase() + str.slice(1)
      }
      return str
    },
    formatUrl: function (str) {
      return "https://" + str.replace(/^https?:\/\//, "")
    },
  }
})()

export default stringHelper
