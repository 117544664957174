import React from "react"
import Inputs from "src/assets/icons/report-input.svg"
import Outputs from "src/assets/icons/report-output.svg"
import ReportInput from "./ReportInput"
import ReportOutput from "./ReportOutput"
import IOSplitLine from "./IOSplitLine"
import IOProcessTitle from "./IOProcessTitle"
import IOLabel from "./IOLabel"

const ReportInputsOutputs = ({ processes }) => {
  const lastProcessItem = processes.length - 1
  const getId = () => {
    return Date.now().toString(36) + Math.random().toString(36).substr(2)
  }
  return (
    <div className="display-column">
      {processes.map((process, index) => {
        const lastInputItem = process?.inputs?.length - 1
        const lastOutputItem = process?.outputs?.length - 1
        return (
          <div key={"inputs-outputs-" + index} className="display-column">
            {process.printTitle && (
              <IOProcessTitle
                title={process?.title !== undefined && process?.title}
              />
            )}
            <div className="display-row">
              <div className="display-column" style={{ width: "49%" }}>
                {process.printTitle && (
                  <IOLabel icon={Inputs} title={"Inputs"} />
                )}
                {process?.inputs !== undefined &&
                  process?.inputs.map((input, index) => {
                    return (
                      <ReportInput
                        key={getId()}
                        input={input}
                        index={index}
                        lastInputItem={lastInputItem}
                      />
                    )
                  })}
              </div>
              <div className="split-line-middle"></div>
              <div className="display-column" style={{ width: "49%" }}>
                {process.printTitle && (
                  <IOLabel icon={Outputs} title={"Outputs"} />
                )}
                {process?.outputs !== undefined &&
                  process?.outputs.map((output, index) => {
                    return (
                      <ReportOutput
                        key={getId()}
                        output={output}
                        index={index}
                        lastOutputItem={lastOutputItem}
                      />
                    )
                  })}
              </div>
            </div>
            {lastProcessItem !== index && <IOSplitLine />}
          </div>
        )
      })}
    </div>
  )
}

export default ReportInputsOutputs
