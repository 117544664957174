import React, { useEffect, useRef, useState } from "react"
import {
  CButton,
  CForm,
  CFormGroup,
  CInput,
  CInputGroup,
  CInvalidFeedback,
  CLabel,
  CModal,
  CModalBody,
} from "@coreui/react"
import * as Yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"

const AttestationUnitPasswordModal = ({ showModal, onClosed, onSubmit, errorMessage }) => {
  const validationObject = {
    password: Yup.string().required("Password is required"),
  }
  const validationSchema = Yup.object().shape(validationObject)
  const { register, handleSubmit, errors, setValue, setError, clearErrors, trigger, formState } =
    useForm({
      resolver: yupResolver(validationSchema),
    })

  const onPasswordChange = async (e) => {
    if (e.target.value && formState.isDirty) {
      await clearErrors("password")
    }
  }

  useEffect(() => {
    setValue("password", "")
  }, [])

  useEffect(() => {
    setValue("password", "")
    if (errorMessage) {
      setError("password", { type: "custom", message: errorMessage })
    } else {
      clearErrors("password")
    }
  }, [errorMessage])

  return (
    <>
      <CModal
        closeOnBackdrop={false}
        show={showModal}
        size="sm"
        onClosed={() => {
          onClosed()
        }}
      >
        <CModalBody>
          <div className="p-3">
            <CForm onSubmit={handleSubmit(onSubmit)}>
              <CFormGroup>
                <CLabel htmlFor="password">Please enter your password to proceed:</CLabel>
                <CInputGroup>
                  <CInput
                    id="password"
                    name="password"
                    placeholder="Enter password"
                    innerRef={register}
                    invalid={!!errors.password}
                    onChange={(e) => {
                      onPasswordChange(e)
                    }}
                  />
                  <CInvalidFeedback className="help-block">
                    {errors.password?.message}
                  </CInvalidFeedback>
                </CInputGroup>
              </CFormGroup>

              <div className="modal-button-container text-center">
                <div className="modal-button-wrapper">
                  <CButton type="submit" className="btn-pill btn-save-changes">
                    Login
                  </CButton>
                </div>
              </div>
            </CForm>
          </div>
        </CModalBody>
      </CModal>
    </>
  )
}

export default AttestationUnitPasswordModal
