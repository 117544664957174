import request from "../Request"

const getScaledCredits = (params, completedCallback, errorCallback) => {
  request.call({
    path: "/inventory/api/attestation-unit/scaled-credits/" + params.id + "/" + params.quantity,
    method: "get",
    completedCallback: completedCallback,
    errorCallback: errorCallback,
  })
}

export default getScaledCredits
