import CIcon from "@coreui/icons-react"
import { CSidebar, CSidebarBrand, CSidebarNav } from "@coreui/react"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { setSidebarShow } from "src/redux/slices/uiSlice"

const PublicSidebar = () => {
  const dispatch = useDispatch()
  const show = useSelector((state) => state.ui.sidebarShow)

  return (
    <CSidebar
      show={show}
      minimize={true}
      onShowChange={(val) => dispatch(setSidebarShow(val))}
    >
      <CSidebarBrand
        className="d-md-down-none"
        to="/"
        style={{ backgroundColor: "#0071AB" }}
      >
        <CIcon
          src="/carbonsig-logo-short.svg"
          className="c-sidebar-brand-minimized"
          name="sygnet"
          height={35}
        />
      </CSidebarBrand>
      <CSidebarNav style={{ backgroundColor: "#0188CE" }}></CSidebarNav>
    </CSidebar>
  )
}

export default React.memo(PublicSidebar)
