import envHelper from "src/helpers/EnvHelper"

const envsData = {
  development: { label: "development", color: "#e68080" },
  test: { label: "test", color: "#444443" },
  staging: { label: "staging", color: "#e9d671" },
  sandbox: { label: "sandbox", color: "#0057B7" },
}

const useAppEnv = () => {
  let appEnv = process.env.REACT_APP_ENV
  if (envHelper.isSandbox()) {
    appEnv = "sandbox"
  }

  const isProductionEnv = appEnv === "production"
  const currentEnvData = envsData[appEnv]

  return { currentEnvData, isProductionEnv }
}

export default useAppEnv
