import { CCard, CCardBody } from "@coreui/react"
import HeadingList from "src/assets/icons/heading-list.svg"
import D3TreeMapChart from "src/reusable/D3TreeMapChart"

const ProcessSystemRunPreviewProcessTreeMap = ({ data, title, width, height }) => {
  return (
    <CCard className="cs-card">
      <CCardBody>
        <div>
          <div className="heading mb-4">
            <img src={HeadingList} className="heading-img" alt="icon-list" />
            Tree Map for {title}
          </div>
          <div style={{ width: `${width}px`, margin: "0 auto" }}>
            <D3TreeMapChart width={width} height={height} data={data} />
          </div>
        </div>
      </CCardBody>
    </CCard>
  )
}

export default ProcessSystemRunPreviewProcessTreeMap
