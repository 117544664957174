const envHelper = (function () {
  return {
    isSandbox: function () {
      return process.env.REACT_APP_SANDBOX_ENABLED === "true"
    },
    isOnPrem: function () {
      return process.env.REACT_APP_ON_PREM_ENABLED === "true"
    },
    isLocalhost: function () {
      return (
        window.location.hostname === "localhost" ||
        window.location.hostname === "[::1]" ||
        window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
      )
    },
    getCertsUrl: function (isProductionEnv, currentEnvData) {
      const prodCertsLink = "https://certs.carbonsig.app"
      if (this.isLocalhost()) {
        return "http://localhost:3000/#/public"
      }
      if (this.isOnPrem()) {
        return "https://certs.oxy.carbonsig.app/"
      }
      if (isProductionEnv) return prodCertsLink
      switch (currentEnvData.label) {
        case "development":
          return "https://certs.dev.carbonmetrix.co"
        case "staging":
          return "https://certs.staging.carbonmetrix.co"
        case "production":
          if (this.isOnPrem()) {
            return "https://certs.oxy.carbonsig.app/"
          }
        default:
          return prodCertsLink
      }
    },
  }
})()
export default envHelper
