import jwtDecode from "jwt-decode"

const authHelper = (function () {
  return {
    logout: function () {
      localStorage.removeItem("jwt-token")
      localStorage.removeItem("jwt-refresh-token")
      window.location.href = "/"
      if (window.productFruits) {
        window.productFruits.services.destroy()
      }
    },
    logoutSso: function () {
      localStorage.removeItem("jwt-token")
      localStorage.removeItem("jwt-refresh-token")
      window.location.href =
        "https://login.microsoftonline.com/8548c2aa-a535-453d-a708-4a123f27cded/oauth2/v2.0/logout"
      if (window.productFruits) {
        window.productFruits.services.destroy()
      }
    },
    getTenantId: function () {
      let tenantId = false
      if (localStorage.getItem("jwt-token")) {
        const decodedToken = jwtDecode(localStorage.getItem("jwt-token"))
        tenantId = parseInt(decodedToken.tenantid, 10)
      }
      return tenantId
    },
    getUserId: function () {
      let userId = false
      if (localStorage.getItem("jwt-token")) {
        const decodedToken = jwtDecode(localStorage.getItem("jwt-token"))
        userId = parseInt(decodedToken.userid, 10)
      }
      return userId
    },
  }
})()

export default authHelper
