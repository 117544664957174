import React from "react"
import Attribute from "src/reusable/Attribute"

const ProductExplorerSidebarAttributes = ({ attributes }) => {

  const renderAttributes = () => {
    attributes.map((attr, index) => (
      <div className='sidebar-summary-row d-flex' key={'attr-' + index}>
        <div className='sidebar-summary-title'>{attr.attributeTitle}</div>
        <div className='sidebar-summary-value'>
          <Attribute value={attr.attributeValue} type={attr.valueType} />
        </div>
      </div>
    ))
  }

  return (
    <>
     { attributes ? (<div style={{display: 'flex', height: "100%", alignItems: 'center', justifyContent: 'center'}}>
        <p>No data available.</p>
      </div>) : (renderAttributes)}
    </>
  )
}

export default ProductExplorerSidebarAttributes
