import React from "react"
import { scaleOrdinal } from "d3"

import { sankey, sankeyCenter, sankeyLinkHorizontal } from "d3-sankey"
import numberFormat from "src/helpers/NumberFormat"

const MARGIN_Y = 25
const MARGIN_X = 5
const COLORS = ["#2799d5", "#118AE7", "#0C2F59", "#FFFFFF"]

const D3SankeyChart = ({ width, height, data }) => {
  const getNodeGroup = (node) => {
    if (node.id.startsWith("link-")) {
      return 1
    } else if (node.id.startsWith("process-") || node.id.startsWith("emission-")) {
      return 4
    } else if (data.links.map((x) => x.target.id).includes(node.id)) {
      return 3
    } else {
      return 2
    }
  }

  const allGroups = [1, 2, 3, 4]
  const colorScale = scaleOrdinal().domain(allGroups).range(COLORS)

  // Set the sankey diagram properties
  const sankeyGenerator = sankey()
    .nodeWidth(16)
    .nodePadding(12)
    .extent([
      [MARGIN_X, MARGIN_Y],
      [width - MARGIN_X, height - MARGIN_Y],
    ])
    .nodeId((node) => node.id) // Accessor function: how to retrieve the id that defines each node. This id is then used for the source and target props of links
    .nodeAlign(sankeyCenter) // Algorithm used to decide node position
    .iterations(128)

  // Compute nodes and links positions
  const { nodes, links } = sankeyGenerator(data)

  const highestValue = Math.max(...links.map((l) => l.width))
  const threshold = highestValue * 0.025

  const allNodes = nodes.map((node, index) => {
    const nodeHeight = node.y1 && node.y0 && node.y1 - node.y0
    const theHeight = nodeHeight + threshold / 2

    return (
      <g key={index}>
        <rect
          height={theHeight}
          width={sankeyGenerator.nodeWidth()}
          x={node.x0}
          y={node.y0 - threshold / 4}
          stroke="#118AE7"
          strokeWidth={getNodeGroup(node) === 4 ? 1 : 0}
          fill={colorScale(getNodeGroup(node))}
          fillOpacity={1}
          rx={0.9}
        />
      </g>
    )
  })

  const allLinks = links.map((link, i) => {
    const linkGenerator = sankeyLinkHorizontal()
    const path = linkGenerator(link)
    const node = nodes.find((node) => node.id == link.source.id)

    const theWidth = link.width < threshold ? link.width * (threshold / link.width) : link.width

    return (
      <g key={link.index}>
        <title>
          {node.name} {numberFormat.localeString(node.value)} kg CO2e
        </title>
        <path
          key={i}
          d={path || ""}
          className={"link"}
          stroke={colorScale(getNodeGroup(link))}
          fill="none"
          strokeOpacity={0.3}
          strokeWidth={theWidth}
        />
      </g>
    )
  })

  // Draw the Labels

  const allLabels = nodes.map((node, i) => {
    return (
      <text
        key={i}
        x={node.x0 && node.x0 < width / 2 ? node.x1 && node.x1 + 6 : node.x0 && node.x0 - 6}
        y={node.y1 && node.y0 && (node.y1 + node.y0) / 2}
        dy="0.35rem"
        textAnchor={node.x0 && node.x0 < width / 2 ? "start" : "end"}
        fontSize={10}
        xlinkTitle="title"
      >
        <title>
          {node.name} {numberFormat.localeString(node.value)} kg CO2e
        </title>
        <tspan
          x={node.x0 && node.x0 < width / 2 ? node.x1 && node.x1 + 6 : node.x0 && node.x0 - 6}
          y={node.y1 && node.y0 && (node.y1 + node.y0) / 2}
          fontSize={12}
          // style={{ fontWeight: "bold" }}
          dy="0.10rem"
        >
          {node.name.split(" ")[0] + "..."}
        </tspan>
        {/* <tspan
          className={"node__value"}
          x={node.x0 && node.x0 < width / 2 ? node.x1 && node.x1 + 6 : node.x0 && node.x0 - 6}
          y={node.y1 && node.y0 && (node.y1 + node.y0) / 2 + 12}
          fontSize={10}
          dy="0.30rem"
        >
          {`${node.value} kg CO2e`}
        </tspan> */}
      </text>
    )
  })

  return (
    <div>
      <svg width={width} height={height}>
        {allLinks}
        {allNodes}
        {allLabels}
      </svg>
    </div>
  )
}

export default D3SankeyChart
