import React, { useState, useRef, useEffect } from "react"
import {
  CForm,
  CFormGroup,
  CLabel,
  CInput,
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalTitle,
  CModalFooter,
  CInvalidFeedback,
} from "@coreui/react"
import HeadingGenealogy from "../../assets/icons/heading-genealogy.svg"

const InventoryGenealogyObfuscateModal = ({ showModal, onClosed, onChange }) => {
  const [title, setTitle] = useState("")
  const inputRef = useRef(null)

  useEffect(() => {
    if (showModal && inputRef.current) {
      setTimeout(() => {
        inputRef.current.focus()
      }, 200)
    }
  }, [showModal])

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault()
      submit()
    }
  }

  const submit = () => {
    if (title.trim()) {
      onChange(title)
    } else {
      alert("Title cannot be empty")
    }
  }

  return (
    <CModal
      closeOnBackdrop={false}
      style={{
        borderRadius: 0,
        padding: 20,
        paddingLeft: 30,
        paddingRight: 30,
        borderColor: "white",
      }}
      show={showModal}
      onClosed={() => {
        setTitle("")
        onClosed()
      }}
    >
      <CModalHeader closeButton className="modal-heading">
        <CModalTitle className="modal-heading-title">
          <img className="modal-heading-img" src={HeadingGenealogy} alt="genealogy-icon" />
          Change Inventory Title
        </CModalTitle>
      </CModalHeader>
      <CModalBody className="modal-body">
        <CFormGroup>
          <CLabel htmlFor="standardName">Title</CLabel>
          <CInput
            id="standardName"
            innerRef={inputRef}
            value={title}
            onChange={(e) => {
              setTitle(e.target.value)
            }}
            onKeyDown={handleKeyDown}
            invalid={title.length > 29}
          />
          <CInvalidFeedback className="help-block">
            {title.length > 29 && "Field limit is 29 characters"}
          </CInvalidFeedback>
        </CFormGroup>
      </CModalBody>
      <CModalFooter className="modal-footer-border-width">
        <div className="modal-button-container">
          <div className="modal-button-wrapper modal-button-padding-right text-align">
            <CButton
              className="btn-pill btn-cancel"
              onClick={() => {
                onClosed()
              }}
            >
              Cancel
            </CButton>
          </div>
          <div className="modal-button-wrapper modal-button-padding-left">
            <CButton
              className={"btn-pill btn-save-changes"}
              onClick={() => {
                submit()
              }}
              disabled={title.length > 29}
            >
              Save
            </CButton>
          </div>
        </div>
      </CModalFooter>
    </CModal>
  )
}

export default InventoryGenealogyObfuscateModal
