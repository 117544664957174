export const getTreeMapDataFromProcesses = (contributors) => {
  const treeMapData = {
    name: "TreeMap",
    colname: "",
    value: 0,
    children: [],
  }

  const sources = []
  const sinks = []
  const credits = []
  const sourcesColname = `Source`
  const sinksColname = `Sink`
  const creditsColname = `Credit`

  contributors.forEach((item, index) => {
    if (item.source) {
      sources.push({
        name: item.contributor,
        group: "A",
        value: item.source,
        colname: sourcesColname,
      })
    }
    if (item.sink) {
      sinks.push({
        name: item.contributor,
        group: "B",
        value: item.sink,
        colname: sinksColname,
      })
    }
    if (item.credit) {
      credits.push({
        name: item.contributor,
        group: "C",
        value: item.credit,
        colname: creditsColname,
      })
    }
  })
  if (sources.length > 0) {
    treeMapData.children.push({
      name: "Source",
      colname: "Source",
      value: 0,
      children: sources,
    })
  }

  if (sinks.length > 0) {
    treeMapData.children.push({
      name: "Sink",
      colname: "Sink",
      value: 0,
      children: sinks,
    })
  }

  if (credits.length > 0) {
    treeMapData.children.push({
      name: "Carbon Credits",
      colname: "Carbon Credits",
      value: 0,
      children: credits,
    })
  }
  return treeMapData
}
