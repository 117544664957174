import authHelper from "src/helpers/AuthHelper"
import jwtDecode from "jwt-decode"

const baseUrl = process.env.REACT_APP_GATEWAY_URL
let isRefreshingToken = false

const request = (function () {
  const getJwtToken = () => {
    return localStorage.getItem("jwt-token")
  }
  const extractErrorFromResponse = (jsonData) => {
    let errorMessage = "Something went wrong."
    jsonData = tryConvertTextResponseToJson(jsonData)
    if (jsonData && jsonData.detail) {
      errorMessage = jsonData.detail
    }
    return errorMessage
  }
  const tryConvertTextResponseToJson = (jsonData) => {
    if (typeof jsonData === "string" || jsonData instanceof String) {
      try {
        jsonData = JSON.parse(jsonData)
      } catch (e) {}
    }
    return jsonData
  }
  const generateHeaders = (jwtToken, requestType) => {
    const headers = {
      Authorization: "Bearer " + jwtToken,
      Accept: "application/json, text/uri-list, text/plain",
      "Content-Type": "application/json;charset=UTF-8",
    }
    if (requestType === "uri-list") {
      headers["Content-Type"] = "text/uri-list"
    }
    if (requestType === "multipart/form-data") {
      delete headers["Content-Type"]
    }
    return headers
  }
  const transformBody = (method, requestType, params) => {
    let body = false
    if (method !== "get") {
      if (requestType === "json") {
        body = JSON.stringify(params)
      } else {
        body = params
      }
    }
    return body
  }
  const generateFetchConfig = (method, headers, body) => {
    let fetchConfig = {
      method: method,
      headers: headers,
    }
    if (body) {
      fetchConfig.body = body
    }
    return fetchConfig
  }
  const getNewAccessToken = async () => {
    if (isRefreshingToken) return getJwtToken()

    isRefreshingToken = true
    const refreshToken = localStorage.getItem("jwt-refresh-token")

    const res = await fetch(`${baseUrl}/auth/api/auth/refresh?token=${refreshToken}`)

    if (res.status === 401 || res.status === 404) {
      authHelper.logout()
      return null
    }

    const { token } = await res.json()
    localStorage.setItem("jwt-token", token)

    isRefreshingToken = false
    return token
  }
  const onAuthFailure = async (res, config, fetchConfig) => {
    if (res.status === 401) {
      authHelper.logout()

      // const newAccessToken = await getNewAccessToken()
      // if (!newAccessToken) return

      // fetchConfig.headers["Authorization"] = `Bearer ${newAccessToken}`
      // config.errorCallback = null

      // executeFetchRequest(config, fetchConfig)
    }
  }

  const executeFetchRequest = (config, fetchConfig) => {
    return fetch(`${config.basePath}${config.path}`, fetchConfig)
      .then(async (res) => {
        onAuthFailure(res, config, fetchConfig)
        if (config.responseType === "json") {
          return [await res.json(), res.status]
        } else if (config.responseType === "text") {
          return [await res.text(), res.status]
        }
      })
      .then(([jsonData, status]) => {
        if (status >= 200 && status < 300 && config.completedCallback) {
          config.completedCallback(jsonData, status)
        } else if (status === 400 || status === 403 || status === 404) {
          let errorMessage = extractErrorFromResponse(jsonData)
          config.errorCallback(errorMessage, status, jsonData)
        }
      })
      .catch((e) => {})
  }
  const fetchRequestPromise = (config, fetchConfig) => {
    return fetch(`${config.basePath}${config.path}`, fetchConfig)
  }
  const configMerge = (config) => {
    const defaults = {
      params: {},
      completedCallback: () => {},
      errorCallback: () => {},
      method: "post",
      responseType: "json",
      requestType: "json",
      basePath: baseUrl,
      returnPromise: false,
    }
    return Object.assign({}, defaults, config)
  }
  const requiredConfig = (config) => {
    if (!config.path) {
      throw new Error("Path is required")
    }
  }
  return {
    call: function (config) {
      requiredConfig(config)
      config = configMerge(config)
      const jwtToken = getJwtToken() ? getJwtToken() : ""
      const headers = generateHeaders(jwtToken, config.requestType)
      const body = transformBody(config.method, config.requestType, config.params)
      const fetchConfig = generateFetchConfig(config.method, headers, body)
      if (config.returnPromise) {
        return fetchRequestPromise(config, fetchConfig)
      } else {
        return executeFetchRequest(config, fetchConfig)
      }
    },
  }
})()

export default request
