import React, { useEffect } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { CLink } from "@coreui/react"

//component - CoreUI / CPagination
const CarbonPagination = (props) => {
  const {
    className,
    //
    innerRef,
    addListClass,
    activePage,
    size,
    firstButton,
    previousButton,
    nextButton,
    lastButton,
    dots,
    arrows,
    doubleArrows,
    limit,
    pages,
    align,
    onActivePageChange,
    ...attributes
  } = props

  useEffect(() => {
    pages < activePage && onActivePageChange(pages, true)
  }, [pages])

  //render
  const listClasses = classNames(
    "pagination",
    size && "pagination-" + size,
    "justify-content-" + align,
    "align-middle",
    addListClass
  )

  const backArrowsClasses = classNames("page-item", activePage === 1 && "disabled")

  const nextArrowsClasses = classNames("page-item", activePage === pages && "disabled")

  const showDots = (() => {
    return dots && limit > 4 && limit < pages
  })()
  const maxPrevItems = (() => {
    return Math.floor((limit - 1) / 2)
  })()
  const maxNextItems = (() => {
    return Math.ceil((limit - 1) / 2)
  })()
  const beforeDots = (() => {
    return showDots && activePage > maxPrevItems + 1
  })()
  const afterDots = (() => {
    return showDots && activePage < pages - maxNextItems
  })()
  const computedLimit = (() => {
    return limit
    // return limit - afterDots - beforeDots
  })()
  const range = (() => {
    return activePage + maxNextItems
  })()
  const lastItem = (() => {
    return range >= pages ? pages : range - afterDots
  })()
  const itemsAmount = (() => {
    return pages < computedLimit ? pages : computedLimit
  })()
  const items = (() => {
    if (activePage - maxPrevItems <= 1) {
      return Array.from(
        {
          length: itemsAmount,
        },
        (v, i) => i + 1
      )
    } else {
      return Array.from(
        {
          length: itemsAmount,
        },
        (v, i) => {
          return lastItem - i
        }
      ).reverse()
    }
  })()

  const setPage = (number) => {
    if (number !== activePage) {
      onActivePageChange(number)
    }
  }

  const renderPreviousArrows = () => {
    return activePage > 1 ? (
      <>
        {doubleArrows && (
          <li className={backArrowsClasses} onClick={() => setPage(1)}>
            <CLink
              style={{ color: "#444443" }}
              aria-label="Go to first page"
              aria-disabled={activePage === 1}
              disabled={activePage === 1}
            >
              {firstButton}
            </CLink>
          </li>
        )}
        {arrows && (
          <li className={backArrowsClasses} onClick={() => setPage(activePage - 1)}>
            <CLink
              style={{ color: "#444443" }}
              aria-label="Go to previous page"
              aria-disabled={activePage === 1}
              disabled={activePage === 1}
            >
              {previousButton}
            </CLink>
          </li>
        )}
      </>
    ) : null
  }

  const renderNextArrows = () => {
    return activePage < pages ? (
      <>
        {arrows && (
          <li className={nextArrowsClasses} onClick={() => setPage(activePage + 1)}>
            <CLink
              style={{ color: "#444443" }}
              aria-label="Go to next page"
              aria-disabled={activePage === pages}
              disabled={activePage === pages}
            >
              {nextButton}
            </CLink>
          </li>
        )}
        {doubleArrows && (
          <li className={nextArrowsClasses} onClick={() => setPage(pages)}>
            <CLink
              style={{ color: "#444443" }}
              aria-label="Go to last page"
              aria-disabled={activePage === pages}
              disabled={activePage === pages}
            >
              {lastButton}
            </CLink>
          </li>
        )}
      </>
    ) : null
  }

  return (
    <nav
      style={{
        height: "36px",
      }}
      className={className}
      aria-label="pagination"
      {...attributes}
      ref={innerRef}
    >
      <ul
        style={{
          height: "inherit",
          width: 380,
          display: "flex",
          alignItems: "center",
        }}
        className={listClasses}
      >
        {renderPreviousArrows()}
        {/* {beforeDots && (
            <li role="separator" className="page-item disabled">
            <span className="page-link">…</span>
            </li>
          )} */}
        {items.map((i) => {
          return (
            <li
              className={`${activePage === i ? "li-active" : ""}`}
              key={i}
              onClick={(e) => setPage(i, e)}
            >
              <CLink
                style={{ color: activePage === i ? "#FFF" : "#444443" }}
                aria-label={activePage === i ? `Current page ${i}` : `Go to page ${i}`}
              >
                {i}
              </CLink>
            </li>
          )
        })}
        {/* {afterDots && (
          <li role="separator" className="page-item disabled">
          <span className="page-link">…</span>
          </li>
        )} */}
        {renderNextArrows()}
      </ul>
    </nav>
  )
}

CarbonPagination.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  //
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  activePage: PropTypes.number,
  dots: PropTypes.bool,
  arrows: PropTypes.bool,
  doubleArrows: PropTypes.bool,
  firstButton: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  previousButton: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  nextButton: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  lastButton: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  size: PropTypes.oneOf(["", "sm", "lg"]),
  align: PropTypes.oneOf(["start", "center", "end"]),
  addListClass: PropTypes.string,
  limit: PropTypes.number,
  pages: PropTypes.number,
  onActivePageChange: PropTypes.func.isRequired,
}

CarbonPagination.defaultProps = {
  activePage: 1,
  dots: true,
  arrows: true,
  doubleArrows: true,
  limit: 5,
  firstButton: <React.Fragment>&lt;&lt;</React.Fragment>,
  previousButton: <React.Fragment>&lt;</React.Fragment>,
  nextButton: <React.Fragment>&gt;</React.Fragment>,
  lastButton: <React.Fragment>&gt;&gt;</React.Fragment>,
  align: "center",
  pages: 10,
}

export default CarbonPagination
