import React from "react"
import AttestationIconPublic from "src/assets/icons/attestation-icon-public.svg"
import AttestationIconPrivate from "src/assets/icons/attestation-icon-private.svg"
import AttestationIconRestricted from "src/assets/icons/attestation-icon-restricted.svg"
import AttestationIvonAnyoneWithUrl from "src/assets/icons/anyone-with-url.svg"
import {
  shareTypePrivate,
  shareTypeRestricted,
  shareTypeAnyoneWithUrl,
  shareTypePublic,
} from "src/helpers/ShareTypeHelper"

const ShareIcon = ({ shareType, width }) => {
  const getAttestationShareIcon = (shareType) => {
    switch (shareType) {
      case shareTypeRestricted:
        return AttestationIconRestricted
      case shareTypePrivate:
        return AttestationIconPrivate
      case shareTypeAnyoneWithUrl:
        return AttestationIvonAnyoneWithUrl
      default:
        return AttestationIconPublic
    }
  }

  return (
    <img
      src={getAttestationShareIcon(shareType)}
      className="heading-img"
      alt="icon-input"
      style={{ width: `${width}px` }}
    />
  )
}

export default ShareIcon
