import request from "src/requests/Request"

const previewExportProcessSystem = (params, completedCallback, errorCallback) => {
  request.call({
    path: "/inventory/api/process-system/preview-export-process-system-data/" + params.id,
    method: "get",
    completedCallback: completedCallback,
    errorCallback: errorCallback,
    params: params,
  })
}

export default previewExportProcessSystem