import React from "react"
import stringLengthHelper from "../../helpers/StringLengthHelper"
import { CTooltip } from "@coreui/react"

const Attestation = ({ title, value, marginLeft }) => {
  return (
    <div
      style={{
        height: "40px",
        flex: "1",
        marginLeft,
      }}
    >
      <div className="processes-summary-titles-wrapper">
        <span className="titles">{title}</span>
      </div>
      <div className="processes-summary-data-wrapper">
        <CTooltip content={value} placement={"top"}>
          <span className="data">{stringLengthHelper(value, 26)}</span>
        </CTooltip>
      </div>
    </div>
  )
}

export default Attestation
