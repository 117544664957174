import React from "react"

const AttestationSplitLine = ({ innerRef }) => {
  return (
    <div
      ref={innerRef}
      style={{
        width: "100%",
        height: "1px",
        marginTop: "16px",
        marginBottom: "15px",
        backgroundColor: "#E1E1E1",
      }}
    ></div>
  )
}

export default AttestationSplitLine
