import { createSlice } from "@reduxjs/toolkit"

const stepsData = [
  { id: 0, label: "Name of Organization", completed: false },
  { id: 1, label: "Address & Contact Info", completed: false },
  { id: 2, label: "Organizational Structure", completed: false },
  { id: 3, label: "Company Website & Logo", completed: false },
  { id: 4, label: "Create Login & Invite Admins", completed: false },
  { id: 5, label: "Survey", completed: false },
]

const initialState = {
  steps: stepsData,
  currentStep: null,
  tenantOnboardingData: null,
  onboardingStarted: null,
  tenantOnboardingCountries: [],
  companyNameError: null,
}

export const tenantOnboardingSlice = createSlice({
  name: "tenantOnboarding",
  initialState,
  reducers: {
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload
    },
    setSteps: (state, action) => {
      state.steps = action.payload
    },
    setTenantOnboardingData: (state, action) => {
      state.tenantOnboardingData = action.payload
    },
    setOnboardingStarted: (state, action) => {
      state.onboardingStarted = action.payload
    },
    setTenantOnboardingCountries: (state, action) => {
      state.tenantOnboardingCountries = action.payload
    },
    setCompanyNameError: (state, action) => {
      state.companyNameError = action.payload
    },
  },
})

export const {
  setSteps,
  setCurrentStep,
  setTenantOnboardingData,
  setOnboardingStarted,
  setTenantOnboardingCountries,
  setCompanyNameError,
} = tenantOnboardingSlice.actions

export default tenantOnboardingSlice.reducer
