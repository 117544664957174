import React from "react"
import numberFormat from "../../helpers/NumberFormat"

const ReportDeclaredEmission = ({
  title,
  icon1,
  icon2,
  process,
  valueChain,
  additionalMargin,
}) => {
  return (
    <React.Fragment>
      <div
        style={{
          marginBottom: additionalMargin + "px",
        }}
        className="display-row c02-container"
      >
        <div
          style={{
            width: "104px",
          }}
        >
          <span className="declared-emissions-title">
            CO
            <span style={{ position: "relative", top: "4px", fontSize: "6px" }}>
              2
            </span>
            e {title}
          </span>
        </div>
        <div className="display-column declared-emission-rectangle-container">
          <div className="declared-emission-rectangle-wrapper">
            <img src={icon1} />
          </div>
          <div className="declared-emission-rectangle-wrapper">
            <img src={icon2} />
          </div>
        </div>
        <div className="declared-emission-fields-container">
          <div className="declared-emission-field">
            <span style={{ fontSize: "10px" }}>process:</span>
          </div>
          <div className="declared-emission-field">
            <span style={{ fontSize: "10px" }}>value chain:</span>
          </div>
        </div>
        <div className="display-column declared-emissions-data-container declared-emissions-data">
          <div className="w-100 declared-emissions-data-wrapper">
            <span
              style={{ lineHeight: "1.2" }}
              className="declared-emissions-data-span"
            >
              {numberFormat.localeString(process)} kg
            </span>
          </div>
          <div className="w-100 declared-emissions-data-wrapper">
            <span className="declared-emissions-data-span">
              {numberFormat.localeString(valueChain)} kg
            </span>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ReportDeclaredEmission
