import apiCallWrapper from "src/helpers/ApiCallWrapper"
import { useDispatch } from "react-redux"
import "reactflow/dist/style.css"
import getAttestationUnitGenealogyShareExternal from "src/requests/attestation-unit/GetAttestationUnitGenealogyShareExternal"
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min"
import ProductExplorer from "../product-explorer/ProductExplorer"
import { useEffect, useState } from "react"
import objectHelper from "src/helpers/ObjectHelper"
import authHelper from "src/helpers/AuthHelper"
import AttestationUnitPasswordModal from "./AttestationUnitPasswordModal"
import getAttestationUnitShare from "src/requests/attestation-unit-share/GetAttestationUnitShare"

const AttestationUnitGenealogyForShareExternal = () => {
  const dispatch = useDispatch()
  let history = useHistory()
  let { shareId } = useParams()
  const [genealogy, setGenealogy] = useState({})
  const [chartData, setChartData] = useState({})
  const [isOwner, setIsOwner] = useState(false)
  const [showPasswordModal, setShowPasswordModal] = useState(false)
  const [password, setPassword] = useState(false)
  const [shareType, setShareType] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)

  const getStorageKey = (shareId) => {
    return `attestation-unit-external-share-${shareId}`
  }

  const getPassword = (shareId) => {
    const storageKey = getStorageKey(shareId)
    return localStorage.getItem(storageKey)
  }

  const updatePassword = (shareId, password) => {
    const storageKey = getStorageKey(shareId)
    return localStorage.setItem(storageKey, password)
  }

  const getAttestationUnitGenealogyHandler = (shareId, password) => {
    setErrorMessage("")
    apiCallWrapper.call(getAttestationUnitGenealogyShareExternal, dispatch, {
      successHandler: (jsonData) => {
        setGenealogy(jsonData.genealogy)
        setChartData(jsonData.chartData)
        setIsOwner(jsonData.genealogy.tenantId === authHelper.getTenantId())
        getAttestationUnitShareHandler(jsonData.genealogy.attestationUnitId)
      },
      errorHandler: (e, status, jsonData) => {
        if (status === 404) {
          history.replace("/404")
        }
        setShowPasswordModal(true)
        updatePassword(shareId, "")
        setPassword(false)
        setErrorMessage(jsonData.detail)
      },
      params: {
        shareId: shareId,
        password: password,
      },
      showErrorMessage: false,
    })
  }

  const getAttestationUnitShareHandler = (id) => {
    apiCallWrapper.call(getAttestationUnitShare, dispatch, {
      successHandler: (jsonData) => {
        setShareType(parseInt(jsonData.shareType))
      },
      params: {
        id: id,
      },
    })
  }

  useEffect(() => {
    const pwd = getPassword(shareId)
    setPassword(pwd)
    getAttestationUnitGenealogyHandler(shareId, pwd)
    if (!pwd) {
      setShowPasswordModal(true)
    }
  }, [])

  return (
    <>
      {!objectHelper.isEmptyObject(genealogy) ? (
        <ProductExplorer
          genealogy={genealogy}
          showBackButton={false}
          showShareButton={isOwner}
          showShareIcon={true}
          chartData={chartData}
          onCancel={() => history.goBack()}
          isUrlShare={false}
          isExternalShare={true}
          shareId={shareId}
          isPublic={true}
          password={password}
          shareType={shareType}
        />
      ) : (
        ""
      )}
      <AttestationUnitPasswordModal
        showModal={showPasswordModal}
        onClosed={() => {}}
        onSubmit={(formData) => {
          setShowPasswordModal(false)
          updatePassword(shareId, formData.password)
          setPassword(formData.password)
          getAttestationUnitGenealogyHandler(shareId, formData.password)
        }}
        errorMessage={errorMessage}
      />
    </>
  )
}

export default AttestationUnitGenealogyForShareExternal
