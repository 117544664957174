import request from "../Request"

const getProcessSystems = (params, completedCallback, errorCallback) => {
  let path = "/inventory/api/process-system/filter"
  const queryParams = new URLSearchParams(params)
  path += "?"
  path += queryParams.toString()
  request.call({
    path: path,
    method: "get",
    completedCallback: completedCallback,
    errorCallback: errorCallback,
    params: {},
  })
}

export default getProcessSystems
