import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from "@coreui/react"

const TheModal = ({ show, title, message, headerIcon, icon, onClose, btnTitle, btnClasses }) => {

  const renderMessage = (message) => {
    if(message.includes("\n")){
      return <pre>
      <span className="modal-span">{message}</span>
    </pre>
    } else {
      return <div>
      <span className="modal-span">{message}</span>
    </div>
    }
  }

  renderMessage(message)
  return (
    <CModal
      closeOnBackdrop={false}
      style={{
        borderRadius: 0,
        padding: 20,
        paddingLeft: 30,
        paddingRight: 30,
        borderColor: "white",
        bottom: 20,
      }}
      show={show}
      onClose={onClose}
    >
      <CModalHeader className="modal-heading">
        <CModalTitle className="span-heading">
          <img className="heading-img" src={headerIcon} />
          {title}
        </CModalTitle>
      </CModalHeader>
      <CModalBody className="pt-0 pb-0">
        {icon && (
          <div className="d-flex">
            <img
              src={icon}
              style={{ margin: "30px auto 0 auto" }}
              className="heading-img"
              alt="icon-list"
            />
          </div>
        )}
        <div className="w-100 text-center mt-4 mb-2">
          {renderMessage(message)}
        </div>
      </CModalBody>
      <CModalFooter className="modal-footer-border-width d-flex justify-content-center">
        <CButton className={btnClasses} onClick={onClose}>
          {btnTitle}
        </CButton>
      </CModalFooter>
    </CModal>
  )
}

export default TheModal
