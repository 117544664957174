import request from "../Request"

const getScaledCreditsShare = (params, completedCallback, errorCallback) => {
  request.call({
    path:
      "/inventory/api/attestation-unit/scaled-credits/share/" + params.id + "/" + params.quantity,
    method: "get",
    completedCallback: completedCallback,
    errorCallback: errorCallback,
  })
}

export default getScaledCreditsShare
