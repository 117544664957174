import React from "react"
import moment from "moment"

const ProductExplorerSidebarProductOwner = ({ attestationUnit }) => {
  const dateFormat = (date) => {
    if (date) {
      const d = new Date(date)
      return moment(d).format("M/D/YY HH:mm UTC")
    } else {
      return ""
    }
  }

  return (
    <>
      <div>
        <div className="sidebar-credit-heading">Certificate Info</div>
        <div>
          <div className="sidebar-credit-row d-flex">
            <div className="sidebar-credit-title">Generated at</div>
            <div className="sidebar-credit-value">
              {dateFormat(attestationUnit?.header?.generatedOn)}
            </div>
          </div>
          <div className="sidebar-credit-row d-flex">
            <div className="sidebar-credit-title">Receipt confirmed</div>
            <div className="sidebar-credit-value">
              {dateFormat(attestationUnit?.header?.confirmedOn)}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="sidebar-credit-heading">Tenant Info</div>
        <div>
          <div className="sidebar-credit-row d-flex">
            <div className="sidebar-credit-title">Name</div>
            <div className="sidebar-credit-value">{attestationUnit?.header?.tenantTitle}</div>
          </div>
          <div className="sidebar-credit-row d-flex">
            <div className="sidebar-credit-title">ID#</div>
            <div className="sidebar-credit-value">{attestationUnit?.header?.tenantId}</div>
          </div>
        </div>
      </div>
      <div>
        <div className="sidebar-credit-heading">Site Address &amp; Contact Info</div>
        <div>
          <div className="sidebar-credit-row">{attestationUnit?.header?.tenantAddress}</div>
          <div className="sidebar-credit-row">{attestationUnit?.header?.tenantPhone}</div>
        </div>
      </div>
    </>
  )
}

export default ProductExplorerSidebarProductOwner
