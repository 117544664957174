import React from "react"
import numberFormat from "src/helpers/NumberFormat"
import stringHelper from "src/helpers/StringHelper"

const ProductExplorerSidebarProcessFlows = ({ subTree }) => {
  const subTreeNotEmpty = (subTree) => {
    return JSON.stringify(subTree) !== "{}"
  }

  const displayIntensity = (co2Intensity, unit, externalLci, obfuscated) => {
    if (obfuscated) {
      return "Redacted data"
    } else if (externalLci) {
      return `${stringHelper.ucfirst(externalLci)} licensed data`
    } else {
      return `${numberFormat.localeString(co2Intensity)} kg CO₂e/${unit}`
    }
  }

  const calculateIntensity = (co2Emission, co2SinksEmission, quantity) => {
    return quantity ? (co2Emission - co2SinksEmission) / quantity : 0
  }

  return (
    <>
      {subTreeNotEmpty(subTree) ? (
        <>
          <div className="sidebar-credit-heading">Inputs</div>
          {subTree.children.map((input, index) => (
            <>
              <div key={"input-" + index}>
                <div className="sidebar-credit-row d-flex">
                  <div className="sidebar-credit-title">{input.title}</div>
                  <div className="sidebar-credit-value">
                    {displayIntensity(
                      calculateIntensity(input.co2Emission, input.co2SinksEmission, input.quantity),
                      input.unit,
                      input.externalLci,
                      input.obfuscated
                    )}
                  </div>
                </div>
              </div>
            </>
          ))}
          <div className="sidebar-credit-heading">Outputs</div>

          <div>
            <div className="sidebar-credit-row d-flex">
              <div className="sidebar-credit-title">{subTree.title}</div>
              <div className="sidebar-credit-value">
                {numberFormat.localeString(
                  calculateIntensity(
                    subTree.co2Emission,
                    subTree.co2SinksEmission,
                    subTree.quantity
                  )
                )}{" "}
                kg CO
                <sub>2</sub>e/
                {subTree.unit}
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  )
}

export default ProductExplorerSidebarProcessFlows
