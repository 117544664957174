import React from "react"
import numberFormat from "src/helpers/NumberFormat"

const ReportProductSpecifications = ({ spec, index, innerRef }) => {
  return (
    <div
      style={{ height: "16px" }}
      ref={innerRef}
      className="data data-specs"
      key={"spec-" + index}
    >
      {spec.title}:{" "}
      {isNaN(spec.value) || spec.value === ""
        ? spec.value
        : numberFormat.localeString(Number(spec.value))}
    </div>
  )
}

export default ReportProductSpecifications
