import apiCallWrapper from "src/helpers/ApiCallWrapper"
import { useDispatch } from "react-redux"
import "reactflow/dist/style.css"
import getAttestationUnitGenealogyShare from "src/requests/attestation-unit/GetAttestationUnitGenealogyShare"
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min"
import ProductExplorer from "../product-explorer/ProductExplorer"
import { useEffect, useState } from "react"
import objectHelper from "src/helpers/ObjectHelper"
import authHelper from "src/helpers/AuthHelper"
import getAttestationUnitShare from "src/requests/attestation-unit-share/GetAttestationUnitShare"

const AttestationUnitGenealogyForShare = () => {
  const dispatch = useDispatch()
  let history = useHistory()
  let { shareId } = useParams()
  const [genealogy, setGenealogy] = useState({})
  const [chartData, setChartData] = useState({})
  const [isOwner, setIsOwner] = useState(false)
  const [shareType, setShareType] = useState(false)

  const getAttestationUnitGenealogyHandler = (id) => {
    apiCallWrapper.call(getAttestationUnitGenealogyShare, dispatch, {
      successHandler: (jsonData) => {
        setGenealogy(jsonData.genealogy)
        setChartData(jsonData.chartData)
        setIsOwner(jsonData.genealogy.tenantId === authHelper.getTenantId())
        getAttestationUnitShareHandler(jsonData.genealogy.attestationUnitId)
      },
      params: {
        id: id,
      },
    })
  }

  const getAttestationUnitShareHandler = (id) => {
    apiCallWrapper.call(getAttestationUnitShare, dispatch, {
      successHandler: (jsonData) => {
        setShareType(parseInt(jsonData.shareType))
      },
      params: {
        id: id,
      },
    })
  }

  useEffect(() => {
    getAttestationUnitGenealogyHandler(shareId)
  }, [])

  return (
    <>
      {!objectHelper.isEmptyObject(genealogy) ? (
        <ProductExplorer
          genealogy={genealogy}
          showBackButton={false}
          showShareIcon={true}
          showShareButton={isOwner}
          chartData={chartData}
          onCancel={() => history.goBack()}
          isUrlShare={true}
          shareId={shareId}
          isPublic={true}
          shareType={shareType}
        />
      ) : (
        ""
      )}
    </>
  )
}

export default AttestationUnitGenealogyForShare
