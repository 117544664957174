import request from "../Request"

const getEmissions = (params, completedCallback, errorCallback) => {
  request.call({
    path: "/inventory/api/emission",
    method: "get",
    completedCallback: completedCallback,
    errorCallback: errorCallback,
    params: params,
  })
}

export default getEmissions
