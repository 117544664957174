import request from "./Request"

const getReportShareExternal = (params, completedCallback, errorCallback) => {
  request.call({
    path: "/inventory/api/attestation-unit/report/share-external",
    method: "post",
    completedCallback: completedCallback,
    errorCallback: errorCallback,
    params: params,
  })
}

export default getReportShareExternal
