import React from "react"
import envHelper from "src/helpers/EnvHelper"

const ReportFooter = ({ page, totalPages, id, reportId }) => {
  return (
    <div key={"footer-" + id} className="display-row footer-container">
      <div className="footer-wrapper-first">
        <span className="footer-text">
          Full carbon attestation document, methodology, and data is available
          at certs.CarbonSig.app/#/{envHelper.isSandbox() ? null : reportId}, as provided by the product owner.
          CarbonSig assumes no legal liability over the accuracy of product
          owner claims. This certificate details a summary of production
          processes. For more information and guiding principles, visit
          CarbonSig.com
        </span>
      </div>
      <div className="footer-wrapper-second">
        <span className="page-font">
          {page}/{totalPages}
        </span>
      </div>
    </div>
  )
}

export default ReportFooter
