import { configureStore } from "@reduxjs/toolkit"
import tenantOnboardingReducer from "./slices/tenantOnboardingSlice"
import uiReducer from "./slices/uiSlice"
import workspacesReducer from "./slices/workspacesSlice"
import userReducer from "./slices/userSlice"
import objectSlice from "./slices/objectSlice"
import lciSlice from "./slices/lciSlice"

export const store = configureStore({
  reducer: {
    ui: uiReducer,
    workspaces: workspacesReducer,
    tenantOnboarding: tenantOnboardingReducer,
    user: userReducer,
    object: objectSlice,
    lci: lciSlice,
  },
})
