import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CForm,
  CFormGroup,
  CInput,
  CInvalidFeedback,
  CLabel,
  CRow,
} from "@coreui/react"
import { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { useLocation } from "react-router-dom"
import HeadingSearch from "src/assets/icons/heading-search.svg"
import ArrowDown from "../../../assets/icons/arrow-down.svg"
import ArrowLeft from "../../../assets/icons/arrow-left.svg"

const ProcessSystemFilter = ({
  handleFilter,
  handleCategory,
  counterUpdateKey,
  selectedParam,
  counter,
}) => {
  const location = useLocation()
  const [showCardContent, setShowCardContent] = useState(true)
  const [selected, setSelected] = useState(selectedParam || "systems")
  const selectedKey = useRef(selectedParam || "systems")
  const { register, handleSubmit, errors, reset, setValue } = useForm({
    shouldUnregister: false,
  })

  function onSubmit(formData) {
    if (typeof handleFilter === "function") {
      Object.keys(formData).forEach((k) => (formData[k] = formData[k].trim()))
      handleFilter(formData, selectedKey.current)
    }
  }

  const selectedClass = (name) => (name === selected ? "filter-category-selected" : "")

  useEffect(() => {}, [counterUpdateKey])

  const urlSearchParams = new URLSearchParams(location.search)
  const titleParam = urlSearchParams.get("title") || ""
  const descriptionParam = urlSearchParams.get("description") || ""
  const createdByParam = urlSearchParams.get("createdBy") || ""

  const validFilterParamNames = ["title", "description", "createdBy"]

  const initialFilters = {
    title: "",
    description: "",
    createdBy: "",
  }

  useEffect(() => {
    if (!selectedParam) return
    setSelected(selectedParam)
    selectedKey.current = selectedParam

    urlSearchParams.forEach((value, name) => {
      if (validFilterParamNames.includes(name)) {
        initialFilters[name] = value
      }
    })

    setValue("title", initialFilters.title)
    setValue("description", initialFilters.description)
    setValue("createdBy", initialFilters.createdBy)
  }, [selectedParam, titleParam, descriptionParam, createdByParam])

  const resetFiltersHandler = () => {
    validFilterParamNames.forEach((name) => {
      initialFilters[name] = ""
      setValue(name, "")
    })
  }

  return (
    <>
      <CCard className="cs-card">
        <CCardBody>
          <div className={showCardContent ? "heading mb-4" : "heading-collapse"}>
            <img
              src={HeadingSearch}
              style={{ marginBottom: "5px" }}
              className="heading-img"
              alt="icon-list"
            />
            <img
              src={showCardContent ? ArrowDown : ArrowLeft}
              style={{
                marginTop: showCardContent ? "20px" : "10px",
                cursor: "pointer",
              }}
              onClick={() => setShowCardContent(!showCardContent)}
              align="right"
              alt="icon-list"
            />
            Search Systems
          </div>
          {showCardContent && (
            <>
              <CRow className="mb-4 no-gutters">
                <CCol md="12">
                  <div
                    className={"filter-category " + selectedClass("systems")}
                    onClick={() => {
                      setSelected("systems")
                      selectedKey.current = "systems"
                      handleCategory("systems")
                    }}
                  >
                    Templates ({counter})
                  </div>
                </CCol>
              </CRow>
              <CForm onSubmit={handleSubmit(onSubmit)}>
                <CFormGroup>
                  <CRow>
                    <CCol md="3">
                      <CFormGroup>
                        <CLabel htmlFor="title">Name</CLabel>
                        <CInput
                          id="title"
                          name="title"
                          placeholder="Enter Title"
                          innerRef={register}
                          invalid={!!errors.title}
                        />
                        <CInvalidFeedback className="help-block">
                          {errors.title?.message}
                        </CInvalidFeedback>
                      </CFormGroup>
                    </CCol>
                    <CCol md="3">
                      <CFormGroup>
                        <CLabel htmlFor="description">Description</CLabel>
                        <CInput
                          id="description"
                          name="description"
                          placeholder="Enter Description"
                          innerRef={register}
                          invalid={!!errors.description}
                        />
                        <CInvalidFeedback className="help-block">
                          {errors.description?.message}
                        </CInvalidFeedback>
                      </CFormGroup>
                    </CCol>
                    <CCol md="3">
                      <CFormGroup>
                        <CLabel htmlFor="createdBy">Process Run By</CLabel>
                        <CInput
                          id="createdBy"
                          name="createdBy"
                          placeholder="Enter Process Run By"
                          innerRef={register}
                          invalid={!!errors.createdBy}
                        />
                        <CInvalidFeedback className="help-block">
                          {errors.createdBy?.message}
                        </CInvalidFeedback>
                      </CFormGroup>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="12">
                      <div className="d-flex justify-content-end mt-3">
                        <CButton
                          shape="pill"
                          className={"btn btn-filter btn-filter-secondary mr-3 shadow-sm"}
                          onClick={resetFiltersHandler}
                        >
                          Reset
                        </CButton>
                        <CButton
                          type="submit"
                          shape="pill"
                          className={"btn btn-filter btn-filter-success shadow-sm"}
                        >
                          Search
                        </CButton>
                      </div>
                    </CCol>
                  </CRow>
                </CFormGroup>
              </CForm>
            </>
          )}
        </CCardBody>
      </CCard>
    </>
  )
}

export default ProcessSystemFilter
