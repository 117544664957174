import React from "react"
import numberFormat from "src/helpers/NumberFormat"
import stringLengthHelper from "../../helpers/StringLengthHelper"
import { CTooltip } from "@coreui/react"

const CarbonCredit = ({ carbon, index, innerRef }) => {
  return (
    <div key={"carbon-credit-" + index} ref={innerRef}>
      <div className="display-row">
        <div className="head-label-wrapper">
          <CTooltip content={carbon.title} placement={"top"}>
            <span className="head-label">
              {stringLengthHelper(carbon.title, 80)}
            </span>
          </CTooltip>
        </div>
      </div>
      <div className="display-row">
        <div className="data-label-wrapper-first">
          <span className="head-label">
            {numberFormat.localeString(carbon.co2)} kg CO
            <span style={{ position: "relative", top: "4px", fontSize: "6px" }}>
              2
            </span>
            e
          </span>
        </div>
        <div className="data-label-wrapper-second">
          <CTooltip content={carbon.distributor} placement={"top"}>
            <span className="head-label">
              {stringLengthHelper(carbon.distributor, 35)}
            </span>
          </CTooltip>
        </div>
        <div className="data-label-wrapper-last">
          <span className="head-label">{carbon.vintage}</span>
        </div>
      </div>
      <div className="display-row">
        <div
          style={{
            height: "16px",
            display: "flex",
            width: "100%",
          }}
        >
          <CTooltip content={carbon.serialNumber} placement={"top"}>
            <span
              className="head-label"
              style={{
                fontStyle: "italic",
              }}
            >
              S/N: {stringLengthHelper(carbon.serialNumber, 95)}
            </span>
          </CTooltip>
        </div>
      </div>
    </div>
  )
}

export default CarbonCredit
