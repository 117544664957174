import { createSlice } from "@reduxjs/toolkit"

const initialState = {}

export const objectSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    putObject: (state, action) => {
      state[action.payload.id] = action.payload
    },
  },
})

export const { putObject } = objectSlice.actions

export default objectSlice.reducer
