import React from "react"
import CheckList from "src/assets/icons/report-check-list.svg"
import Rectangle1 from "src/assets/icons/report-rectangle-1.svg"
import Rectangle2 from "src/assets/icons/report-rectangle-2.svg"
import Rectangle3 from "src/assets/icons/report-rectangle-3.svg"
import numberFormat from "src/helpers/NumberFormat"
import EmissionChart from "src/reusable/EmissionChart"
import ReportDeclaredEmission from "./ReportDeclaredEmission"
import HeadSectionTitle from "./HeadSectionTitle"

const ReportDeclaredEmissions = ({
  co2EmissionsProcess,
  co2EmissionsValueChain,
  co2SourcesProcess,
  co2SourcesValueChain,
  co2RemovalsProcess,
  co2RemovalsValueChain,
  co2OffsetsByOwner,
  co2OffsetsValueChain,
  co2FromProduction,
  intensity,
  co2WithOffsets,
  intensityWithOffsets,
  quantity,
  unit,
  innerRef,
}) => {
  return (
    <div ref={innerRef} className="display-row declared-emission-container">
      <div className="display-column declared-emission-wrapper-1">
        <HeadSectionTitle
          title={"Attested Emissions"}
          icon={CheckList}
          additionalMarginBottom={"72"}
          imgPaddingTopBottom={"2.5"}
          imgPaddingLeftRight={"2.5"}
        />
        <ReportDeclaredEmission
          title={"sources"}
          icon1={Rectangle1}
          icon2={Rectangle1}
          process={co2SourcesProcess}
          valueChain={co2SourcesValueChain}
          additionalMargin={"16"}
        />
        <ReportDeclaredEmission
          title={"sinks"}
          icon1={Rectangle2}
          icon2={Rectangle2}
          process={co2RemovalsProcess}
          valueChain={co2RemovalsValueChain}
          additionalMargin={"24"}
        />
        <ReportDeclaredEmission
          title={"credits"}
          icon1={Rectangle3}
          icon2={Rectangle3}
          process={co2OffsetsByOwner}
          valueChain={co2OffsetsValueChain}
        />
      </div>
      <div className="display-column chart-container">
        <div
          className="display-row chart-wrapper"
          style={{
            marginBottom: "40px",
          }}
        >
          <div className="chart-title display-column">
            <span>
              Embodied CO
              <span style={{ position: "relative", top: "4px", fontSize: "8px" }}>2</span>e:
            </span>
            <span>Intensity:</span>
          </div>
          <div className="chart-data display-column">
            <span>{numberFormat.localeString(co2FromProduction)} kg</span>
            <span>
              {numberFormat.localeString(intensity)} kg CO
              <span style={{ position: "relative", top: "4px", fontSize: "8px" }}>2</span>
              e/{unit}
            </span>
          </div>
        </div>
        <div className="w-100" style={{ height: "200px" }}>
          <EmissionChart
            emissionsProcess={co2SourcesProcess}
            emissionsValue={co2SourcesValueChain}
            sinksProcess={co2RemovalsProcess}
            sinksValue={co2RemovalsValueChain}
            offsetsOwner={co2OffsetsByOwner}
            offsetsValue={co2OffsetsValueChain}
            config={{
              containerWidth: 424,
              containerHeight: 160,
              emissionHeight: 96,
              offsetsHeight: 40,
              lineHeight: 11,
              linePadding: 3,
            }}
          />
        </div>
        <div className="display-row chart-wrapper">
          <div className="chart-title display-column">
            <span>
              Embodied CO
              <span style={{ position: "relative", top: "4px", fontSize: "8px" }}>2</span>e with
              credits:
            </span>
            <span>Intensity:</span>
          </div>
          <div className="chart-data display-column">
            <span>{numberFormat.localeString(co2WithOffsets)} kg</span>
            <span>
              {numberFormat.localeString(intensityWithOffsets)} kg CO
              <span style={{ position: "relative", top: "4px", fontSize: "8px" }}>2</span>
              e/{unit}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReportDeclaredEmissions
