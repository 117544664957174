import React from "react"
import numberFormat from "src/helpers/NumberFormat"
import stringHelper from "src/helpers/StringHelper"

const ProductExplorerSidebarSummary = ({ genealogyItem, ownerCredits, inheritedCredits }) => {
  console.log({ genealogyItem })
  const calculateNetEmission = (genealogyItem) => {
    const credits = [...ownerCredits, ...inheritedCredits]
    const co2Credit = credits?.map((x) => x.co2Emission).reduce((x, y) => x + y, 0)
    return genealogyItem?.co2Emission - genealogyItem?.co2SinksEmission - co2Credit
  }

  const calculateNetIntensity = (genealogyItem) => {
    return genealogyItem?.quantity
      ? calculateNetEmission(genealogyItem) / genealogyItem?.quantity
      : 0
  }

  const calculateIntensity = (genealogyItem) => {
    return genealogyItem?.quantity
      ? (genealogyItem?.co2Emission - genealogyItem?.co2SinksEmission) / genealogyItem?.quantity
      : 0
  }

  const shouldShowSection = (genealogyItem) => {
    return (
      (!genealogyItem.obfuscated || genealogyItem.obfuscatedProcess) && !genealogyItem.externalLci
    )
  }

  const calculateCredits = (genealogyItem) => {
    return numberFormat.localeString(
      genealogyItem.co2Emission -
        genealogyItem.co2SinksEmission -
        calculateNetEmission(genealogyItem)
    )
  }

  return (
    <>
      <div className="sidebar-summary-row d-flex">
        <div className="sidebar-summary-title">Name</div>
        <div className="sidebar-summary-value">{genealogyItem.title}</div>
      </div>
      {genealogyItem.root && shouldShowSection(genealogyItem) && (
        <div className="sidebar-summary-row d-flex">
          <div className="sidebar-summary-title">Quantity</div>
          <div className="sidebar-summary-value">
            {numberFormat.localeString(genealogyItem.quantity)} {genealogyItem.unit}
          </div>
        </div>
      )}
      <div>
        <div className="sidebar-summary-row d-flex">
          <div className="sidebar-summary-title">
            CO<sub>2</sub>e Emissions
          </div>
          <div className="sidebar-summary-value">
            {numberFormat.localeString(genealogyItem.co2Emission - genealogyItem.co2SinksEmission)}{" "}
            kg CO<sub>2</sub>e
          </div>
        </div>
      </div>

      {shouldShowSection(genealogyItem) && (
        <div>
          <div className="sidebar-summary-row d-flex">
            <div className="sidebar-summary-title">
              CO<sub>2</sub>e per Unit
            </div>
            <div className="sidebar-summary-value">
              {numberFormat.localeString(calculateIntensity(genealogyItem))} kg CO<sub>2</sub>e /{" "}
              {genealogyItem.unit}
            </div>
          </div>

          <div>
            <div className="sidebar-summary-row d-flex">
              <div className="sidebar-summary-title">
                CO<sub>2</sub>e Sources
              </div>
              <div className="sidebar-summary-value">
                {numberFormat.localeString(genealogyItem.co2Emission)} kg CO<sub>2</sub>e
              </div>
            </div>
          </div>

          <div>
            <div className="sidebar-summary-row d-flex">
              <div className="sidebar-summary-title">
                CO<sub>2</sub>e Sinks
              </div>
              <div className="sidebar-summary-value">
                {numberFormat.localeString(genealogyItem.co2SinksEmission)} kg CO<sub>2</sub>e
              </div>
            </div>
          </div>
          <div className="sidebar-summary-row d-flex">
            <div className="sidebar-summary-title">
              CO<sub>2</sub>e Credits
            </div>
            <div className="sidebar-summary-value">
              {calculateCredits(genealogyItem)} kg CO<sub>2</sub>e
            </div>
          </div>
          <div className="sidebar-summary-row d-flex">
            <div className="sidebar-summary-title">
              CO<sub>2</sub>e Emissions Net
            </div>
            <div className="sidebar-summary-value">
              {numberFormat.localeString(calculateNetEmission(genealogyItem))} kg CO<sub>2</sub>e
            </div>
          </div>
          <div className="sidebar-summary-row d-flex">
            <div className="sidebar-summary-title">
              CO<sub>2</sub>e per Unit Net
            </div>
            <div className="sidebar-summary-value">
              {numberFormat.localeString(calculateNetIntensity(genealogyItem))} kg CO<sub>2</sub>e /{" "}
              {genealogyItem.unit}
            </div>
          </div>
        </div>
      )}
      {genealogyItem.externalLci && !genealogyItem.obfuscated ? (
        <div className="sidebar-summary-row d-flex">
          <div className="sidebar-summary-title">
            {stringHelper.ucfirst(genealogyItem.externalLci)} licensed data
          </div>
        </div>
      ) : (
        ""
      )}
      {genealogyItem.obfuscated && !genealogyItem.obfuscatedProcess ? (
        <div className="sidebar-summary-row d-flex">
          <div className="sidebar-summary-title">Redacted data</div>
        </div>
      ) : (
        ""
      )}
    </>
  )
}

export default ProductExplorerSidebarSummary
