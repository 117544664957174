import React from "react"

const ProductExplorerSidebarAttestation = ({ summary, thirdPartyCertifications }) => {
  return (
    <>
      <div className="sidebar-credit-heading">Declaration</div>
      <div className="sidebar-credit-row">{summary?.declaration ?? " -- "}</div>
      <div className="sidebar-credit-heading">Allocation Rules</div>
      <div className="sidebar-credit-row">{summary?.allocationRules ?? " -- "}</div>
      <div className="sidebar-credit-heading">Cut-Off Rules</div>
      <div className="sidebar-credit-row">{summary?.cutOffRules ?? " -- "}</div>
      <div className="sidebar-credit-heading">Calculation Methodology</div>
      <div className="sidebar-credit-row">{summary?.calculationMethodology ?? " -- "}</div>
      <div className="sidebar-credit-heading">Third Party Verification</div>
      <div className="sidebar-credit-row d-flex">
        <div className="sidebar-credit-title" style={{ flex: 2 }}>
          Verified by 3rd party
        </div>
        <div className="sidebar-credit-value-verification" style={{ flex: 1 }}>
          {summary?.thirdPartyVerified ? "Yes" : "No"}
        </div>
      </div>
      <div className="sidebar-credit-row d-flex">
        {summary?.thirdPartyVerified && (
          <>
            <div className="sidebar-credit-title">3rd party name</div>
            <div className="sidebar-credit-value-verification">
              {summary?.thirdPartyVerifiedName ?? " -- "}
            </div>
          </>
        )}
      </div>
      {thirdPartyCertifications && thirdPartyCertifications.length > 0 && (
        <>
          <div className="sidebar-credit-heading">Third Party Certification</div>
          {thirdPartyCertifications?.map((cert) => {
            return (
              <div className="sidebar-credit-row d-flex">
                <div className="sidebar-credit-value">{cert.title}</div>
              </div>
            )
          })}
        </>
      )}
    </>
  )
}

export default ProductExplorerSidebarAttestation
