import React from "react"
import numberFormat from "src/helpers/NumberFormat"
import stringHelper from "../../helpers/StringHelper"
import SectionSplitLine from "./SectionSplitLine"
import stringLengthHelper from "../../helpers/StringLengthHelper"
import { CTooltip } from "@coreui/react"

const ReportOutput = ({ output, index, innerRef, lastOutputItem }) => {
  const displayIntensity = (output) => {
    const co2 = output?.co2
    const unit = output?.unit
    const externalLci = output?.externalLci
    if (externalLci) {
      return <span className="data-label">{stringHelper.ucfirst(externalLci)} licensed data</span>
    } else {
      return (
        <span className="data-label">
          {numberFormat.localeString(co2)} kg CO
          <span style={{ position: "relative", top: "4px", fontSize: "6px" }}>2</span>
          e/{unit}
        </span>
      )
    }
  }

  return (
    <div key={"output-" + index}>
      <div ref={innerRef}>
        <div className="object-label-wrapper">
          <CTooltip content={output.title} placement={"top"}>
            <span className="labels">{stringLengthHelper(output.title, 40)}</span>
          </CTooltip>
        </div>
        <div className="display-row" style={{ height: "14px" }}>
          <div className="object-data-wrapper">{displayIntensity(output)}</div>
          {/*<div style={{ backgroundColor: "red" }} className="object-id-wrapper">
          <span className="data-label">&nbsp;</span>
        </div>*/}
        </div>
      </div>
      {lastOutputItem === index ? "" : <SectionSplitLine />}
    </div>
  )
}

export default ReportOutput
