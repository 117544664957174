import jwtDecode from "jwt-decode"

const requestHelper = (function () {
  return {
    buildEntityUrl: function (entityType, entityId) {
      return `/${entityType}/${entityId}`
    },
    isCurrentTenant: function (tenantId) {
      const token = localStorage.getItem("jwt-token")
      if (token) {
        const decoded = jwtDecode(token)
        return parseInt(decoded.tenantid, 10) === parseInt(tenantId, 10)
      } else {
        return false
      }
    },
    isSuperadmin: function () {
      const token = localStorage.getItem("jwt-token")
      let result = false
      if (token) {
        const decoded = jwtDecode(token)
        result = decoded.sub === "superadmin"
      }
      return result
    },
    isAdminRole: function () {
      const token = localStorage.getItem("jwt-token")
      if (token) {
        const decoded = jwtDecode(token)
        const roles = decoded.auth.map((x) => x.authority)
        return roles.includes("Admin") || roles.includes("Superadmin")
      } else {
        return false
      }
    },
    currentUserId: function () {
      const token = localStorage.getItem("jwt-token")
      if (token) {
        const decoded = jwtDecode(token)
        return decoded.userid
      } else {
        return null
      }
    },
    currentUserUsernameHash: function () {
      const token = localStorage.getItem("jwt-token")
      if (token) {
        const decoded = jwtDecode(token)
        return decoded.usernameHash
      } else {
        return null
      }
    },
    currentTenantTitle: function () {
      const token = localStorage.getItem("jwt-token")
      if (token) {
        const decoded = jwtDecode(token)
        return decoded.tenant
      } else {
        return false
      }
    },
    currentTenantId: function () {
      const token = localStorage.getItem("jwt-token")
      if (token) {
        const decoded = jwtDecode(token)
        return decoded.tenantid
      } else {
        return null
      }
    },
    mainTenantId: function () {
      const token = localStorage.getItem("jwt-token")
      if (token) {
        const decoded = jwtDecode(token)
        return decoded.maintenantid ? Number(decoded.maintenantid) : null
      } else {
        return null
      }
    },
    isLocalEnv: function () {
      return window.location.hostname === "localhost"
    },
    hasRole: function (role) {
      const token = localStorage.getItem("jwt-token")
      let result = false
      if (token) {
        const decoded = jwtDecode(token)
        const roles = decoded.auth.map((x) => x.authority)
        return roles.includes(role)
      }
      return result
    },
    hasPermission: function (permission) {
      const token = localStorage.getItem("jwt-token")
      let result = false
      if (token) {
        const decoded = jwtDecode(token)
        if (decoded.permissions) {
          return decoded.permissions.includes(permission)
        }
      }
      return result
    },
    userRoles: function () {
      const token = localStorage.getItem("jwt-token")
      let roles = []
      if (token) {
        const decoded = jwtDecode(token)
        roles = decoded.auth.map((x) => x.authority)
        return roles
      }
      return roles
    },
  }
})()
export default requestHelper
