import {
  CButton,
  CCol,
  CForm,
  CFormGroup,
  CInvalidFeedback,
  CLabel,
  CRow,
  CTextarea,
} from "@coreui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import HeadingList from "src/assets/icons/heading-list.svg"
import apiCallWrapper from "src/helpers/ApiCallWrapper"
import getSitesForCurrentTenant from "src/requests/GetSitesForCurrentTenant"
import * as Yup from "yup"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

const ProcessSystemEditDetails = ({
  onClose,
  onSubmit,
  onNameChange,
  onDescriptionChange,
  onSiteChange,
  onDeclarationChange,
  onAllocationChange,
  onCutOffRulesChange,
  initialData,
  isEditable,
  processSystemExists,
}) => {
  let history = useHistory()

  const dispatch = useDispatch()
  const currentData = useRef({
    name: "",
    description: "",
    declaration: "",
    allocation: "",
    cutOffRules: "",
  })

  const [name, setName] = useState(
    initialData.title === undefined ? "Untitled System" : initialData.title ?? ""
  )
  const [site, setSite] = useState("")
  const [description, setDescription] = useState("")
  const [declaration, setDeclaration] = useState("")
  const [cutOffRules, setCutOffRules] = useState("")
  const [allocationRules, setAllocationRules] = useState("")

  const handleNameChange = (value) => {
    setName(value)
  }
  const handleSiteChange = (value) => {
    setSite(value)
  }
  const handleDescriptionChange = (value) => {
    setDescription(value)
  }
  const handleDeclarationChange = (value) => {
    setDeclaration(value)
  }
  const handleAllocationRulesChange = (value) => {
    setAllocationRules(value)
  }
  const handleCutOfRulesChange = (value) => {
    setCutOffRules(value)
  }

  const [showCardContent, setShowCardContent] = useState(true)

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .trim()
      .required("Process name is required")
      .max(65, "Field limit is 65 characters"),
    description: Yup.string()
      .trim()
      .required("Description is required")
      .max(10000, "Field limit is 10000 characters"),
    declaration: Yup.string()
      .trim()
      .required("Declaration is required")
      .max(10000, "Field limit is 10000 characters"),
    allocationRules: Yup.string()
      .trim()
      .required("Allocation Rules is required")
      .max(10000, "Field limit is 10000 characters"),
    cutOffRules: Yup.string()
      .trim()
      .required("Cut Off Rules is required")
      .max(10000, "Field limit is 10000 characters"),
  })

  const {
    register,
    handleSubmit,
    reset,
    errors,
    trigger,
    control,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  })

  function onSubmitInner(formData) {
    onSubmit(formData)
  }

  const [sites, setSites] = useState([])

  const getSitesForCurrentTenantHandler = () => {
    apiCallWrapper.call(getSitesForCurrentTenant, dispatch, {
      successHandler: (jsonData) => {
        setSites(jsonData.filter((site) => !site.archived))
      },
    })
  }

  const checkIfSiteIsArchived = () => {
    const theSite = sites.find((site) => site.id === initialData.site)
    if (!theSite || theSite.archived) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    getSitesForCurrentTenantHandler()
  }, [])

  useEffect(() => {
    if (initialData.site && checkIfSiteIsArchived()) {
      handleSiteChange(null)
      reset({ ...initialData, site: null })
      setName(initialData.title)
      setDescription(initialData.description)
      setDeclaration(initialData.declaration)
      setCutOffRules(initialData.cutOffRules)
      setAllocationRules(initialData.allocationRules)
      setSite(null)
    } else {
      reset({
        ...initialData,
        title: initialData.title === undefined ? "Untitled System" : initialData.title,
      })
      setName(initialData.title === undefined ? "Untitled System" : initialData.title)
      setDescription(initialData.description ?? "")
      setSite(initialData.site ?? "")
      setDeclaration(initialData.declaration ?? "")
      setCutOffRules(initialData.cutOffRules ?? "")
      setAllocationRules(initialData.allocationRules ?? "")
    }
  }, [sites, initialData])

  const containerClass = (showCardContent) => {
    let className = "mt-4"
    if (!showCardContent) {
      className += " hidden-submittable"
    }
    return className
  }

  return (
    <>
      <div className={showCardContent ? "heading mb-4" : "heading-collapse"}>
        <img src={HeadingList} className="heading-img" alt="icon-list" />
        System Properties
      </div>
      <CForm
        id="pt-details-form2"
        onSubmit={handleSubmit(onSubmitInner)}
        className={containerClass(showCardContent)}
      >
        <CRow className="align-items-start">
          <CCol>
            <CFormGroup>
              <CLabel htmlFor="title">System Name</CLabel>
              <CTextarea
                style={{ resize: "none" }}
                id="title"
                name="title"
                placeholder="Enter system name"
                innerRef={register}
                invalid={!!errors.title}
                onChange={(e) => {
                  handleNameChange(e.target.value)
                }}
                disabled={!isEditable}
              />

              <CInvalidFeedback className="help-block">{errors.title?.message}</CInvalidFeedback>

              <CFormGroup className={"mt-2"}>
                <CLabel htmlFor="description">Description</CLabel>
                <CTextarea
                  style={{ resize: "none", height: "120px" }}
                  id="description"
                  name="description"
                  placeholder="Enter a description for the process system"
                  innerRef={register}
                  invalid={!!errors.description}
                  onChange={(e) => {
                    trigger("description")
                    if (!!errors.description) {
                      handleDescriptionChange(null)
                    } else {
                      handleDescriptionChange(e.target.value)
                    }
                  }}
                  disabled={!isEditable}
                />
                <CInvalidFeedback className="help-block">
                  {errors.description?.message}
                </CInvalidFeedback>
              </CFormGroup>
            </CFormGroup>
            <CFormGroup>
              <CLabel htmlFor="declaration">Declaration</CLabel>
              <CTextarea
                id="declaration"
                name="declaration"
                placeholder="Enter declaration"
                innerRef={register}
                invalid={!!errors.declaration}
                readOnly={false}
                onChange={(e) => {
                  trigger("declaration")
                  if (!!errors.declaration) {
                    handleDeclarationChange(null)
                  } else {
                    handleDeclarationChange(e.target.value)
                  }
                }}
                style={{ height: "120px", resize: "none" }}
              />
              <CInvalidFeedback className="help-block">
                {errors.declaration?.message}
              </CInvalidFeedback>
            </CFormGroup>
            <CFormGroup>
              <CLabel htmlFor="allocationRules">Allocation Rules</CLabel>
              <CTextarea
                id="allocationRules"
                name="allocationRules"
                placeholder="Enter allocation rules"
                innerRef={register}
                invalid={!!errors.allocationRules}
                readOnly={false}
                onChange={(e) => {
                  trigger("allocationRules")
                  if (!!errors.allocationRules) {
                    handleAllocationRulesChange(null)
                  } else {
                    handleAllocationRulesChange(e.target.value)
                  }
                }}
                style={{ height: "120px", resize: "none" }}
              />
              <CInvalidFeedback className="help-block">
                {errors.allocationRules?.message}
              </CInvalidFeedback>
            </CFormGroup>
            <CFormGroup>
              <CLabel htmlFor="cutOffRules">Cut-Off Rules</CLabel>
              <CTextarea
                id="cutOffRules"
                name="cutOffRules"
                placeholder="Enter cut-off rules"
                innerRef={register}
                invalid={!!errors.cutOffRules}
                readOnly={false}
                onChange={(e) => {
                  trigger("cutOffRules")
                  if (!!errors.cutOffRules) {
                    handleCutOfRulesChange(null)
                  } else {
                    handleCutOfRulesChange(e.target.value)
                  }
                }}
                style={{ height: "120px", resize: "none" }}
              />
              <CInvalidFeedback className="help-block">
                {errors.cutOffRules?.message}
              </CInvalidFeedback>
            </CFormGroup>
          </CCol>
        </CRow>
        <div className="text-center my-3">
          <>
            <CButton
              shape="pill"
              color="secondary"
              className={"btn btn-submit-large btn-filter-secondary shadow-sm mr-3"}
              style={{ width: "150px" }}
              onClick={() => {
                reset({
                  ...initialData,
                  title:
                    currentData.current.name ||
                    (initialData.title === undefined ? "Untitled System" : initialData.title),
                  description: currentData.current.description || initialData.description,
                })
                if (!processSystemExists) {
                  history.push("/")
                }
                onClose()
              }}
            >
              {processSystemExists ? "Cancel Changes" : "Cancel"}
            </CButton>
            {isEditable ? (
              <CButton
                shape="pill"
                color="success"
                className={"btn btn-submit-large shadow-sm mr-3"}
                style={{ width: "150px" }}
                disabled={!isValid}
                onClick={() => {
                  currentData.current.name = name
                  currentData.current.description = description
                  currentData.current.declaration = declaration
                  currentData.current.allocation = allocationRules
                  currentData.current.cutOffRules = cutOffRules
                  onNameChange(name)
                  onSiteChange(sites[0].id)
                  onDescriptionChange(description)
                  onDeclarationChange(declaration)
                  onAllocationChange(allocationRules)
                  onCutOffRulesChange(cutOffRules)
                  onSubmit()
                }}
              >
                {processSystemExists ? "Save Changes" : "Create Template"}
              </CButton>
            ) : null}
          </>
        </div>
      </CForm>
    </>
  )
}

export default ProcessSystemEditDetails
