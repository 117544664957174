import React from 'react'
import {
  CHeader,
  CHeaderBrand,
  CHeaderNav,
  CHeaderNavItem
} from '@coreui/react'

const PublicHeader = () => {
  return (
    <CHeader withSubheader>
      <CHeaderBrand className="mx-auto d-lg-none" to="/">
      </CHeaderBrand>

      <CHeaderNav className="d-md-down-none mr-auto">
        <CHeaderNavItem className="px-3" >

        </CHeaderNavItem>
      </CHeaderNav>

      <CHeaderNav className="px-3">
      </CHeaderNav>
    </CHeader>
  )
}

export default PublicHeader
