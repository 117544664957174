import { freeSet } from "@coreui/icons"
import CIcon from "@coreui/icons-react"
import { CTooltip } from "@coreui/react"
import { useEffect, useState } from "react"
import ProductExplorerSidebarSummary from "./ProductExplorerSidebarSummary"
import ProductExplorerSidebarProductOwner from "./ProductExplorerSidebarProductOwner"
import ProductExplorerSidebarCredits from "./ProductExplorerSidebarCredits"
import ProductExplorerSidebarSUV from "./ProductExplorerSidebarSUV"
import ProductExplorerSidebarProcessFlows from "./ProductExplorerSidebarProcessFlows"
import ProductExplorerSidebarSiteEmissions from "./ProductExplorerSidebarSiteEmissions"
import ProductExplorerSidebarAttributes from "./ProductExplorerSidebarAttributes"
import ProductExplorerSidebarAttestation from "./ProductExplorerSidebarAttestation"
import IconReport from "src/assets/icons/icon-report.svg"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

const ProductExplorerSidebar = ({
  ownerCredits,
  inheritedCredits,
  tenantData,
  genealogyItem,
  attestationUnit,
  subTree,
  expanded,
  isUrlShare,
  isExternalShare,
  isPublic,
  shareId,
}) => {
  const [activeSection, setActiveSection] = useState("")
  const history = useHistory()

  const inventorySelected = (genealogyItem) => {
    return !genealogyItem || genealogyItem?.type === "inventory"
  }

  const publishedSelected = (genealogyItem) => {
    return genealogyItem && genealogyItem.type === "inventory" && genealogyItem.published
  }

  const changeActiveSelection = () => {
    if (inventorySelected(genealogyItem)) {
      setActiveSection("summary")
    } else {
      setActiveSection("process-flows")
    }
  }

  useEffect(() => {
    changeActiveSelection()
  }, [genealogyItem])

  useEffect(() => {
    if (expanded) {
      changeActiveSelection()
    } else {
      setActiveSection("")
    }
  }, [expanded])

  const sections = {
    summary: {
      title: "Object Details",
      content: (
        <ProductExplorerSidebarSummary
          genealogyItem={genealogyItem}
          ownerCredits={ownerCredits}
          inheritedCredits={inheritedCredits}
        />
      ),
    },
    attributes: {
      title: "Attributes",
      content: <ProductExplorerSidebarAttributes attributes={[]} />,
    },
    "product-owner": {
      title: "Product Owner",
      content: <ProductExplorerSidebarProductOwner attestationUnit={attestationUnit} />,
    },
    attestation: {
      title: "Attestation",
      content: (
        <ProductExplorerSidebarAttestation
          summary={attestationUnit?.summary}
          thirdPartyCertifications={attestationUnit?.reportCertifications}
        />
      ),
    },
    "carbon-credits": {
      title: "Carbon Credits",
      content: (
        <ProductExplorerSidebarCredits
          ownerCredits={ownerCredits}
          inheritedCredits={inheritedCredits}
          tenantData={tenantData}
        />
      ),
    },
    suv: {
      title: "Emissions Category",
      content: <ProductExplorerSidebarSUV genealogyItem={genealogyItem} />,
    },
    "site-emissions": {
      title: "Direct Emissions",
      content: <ProductExplorerSidebarSiteEmissions subTree={subTree} />,
    },
    "process-flows": {
      title: "Process Details",
      content: <ProductExplorerSidebarProcessFlows subTree={subTree} />,
    },
  }

  const getInventoryInfoItemClass = (section, activeSection) => {
    let className = "inventory-sidebar-menu-item"
    if (section === activeSection) {
      className += " inventory-sidebar-menu-item-selected"
    }
    return className
  }

  const getInventoryActionItemClass = (disabled) => {
    let className = "inventory-sidebar-menu-item"
    if (disabled) {
      className += " inventory-sidebar-menu-item-disabled"
    }
    return className
  }

  const getSidebarClass = (expanded) => {
    let className = "inventory-sidebar d-flex"
    if (!expanded) {
      className += " inventory-sidebar-collapsed"
    }
    return className
  }

  const getInventoryMenuItems = () => {
    return (
      <>
        <CTooltip content="Object Details" placement="left">
          <div
            className={getInventoryInfoItemClass("summary", activeSection)}
            key={"menu-item-1"}
            onClick={() => {
              if (expanded) {
                setActiveSection("summary")
              }
            }}
          >
            <CIcon content={freeSet.cilApplications} customClasses="inventory-sidebar-menu-icon" />
          </div>
        </CTooltip>
        <CTooltip content="Attributes" placement="left">
          <div
            className={getInventoryInfoItemClass("attributes", activeSection)}
            key={"menu-item-2"}
            onClick={() => {
              if (expanded) {
                setActiveSection("attributes")
              }
            }}
          >
            <CIcon content={freeSet.cilTags} customClasses="inventory-sidebar-menu-icon" />
          </div>
        </CTooltip>
        {publishedSelected(genealogyItem) ? (
          <>
            <CTooltip content="Product Owner" placement="left">
              <div
                className={getInventoryInfoItemClass("product-owner", activeSection)}
                onClick={() => {
                  if (expanded) {
                    setActiveSection("product-owner")
                  }
                }}
              >
                <CIcon content={freeSet.cilHouse} customClasses="inventory-sidebar-menu-icon" />
              </div>
            </CTooltip>
            <CTooltip content="Attestation" placement="left">
              <div
                className={getInventoryInfoItemClass("attestation", activeSection)}
                key={"menu-item-3"}
                onClick={() => {
                  if (expanded) {
                    setActiveSection("attestation")
                  }
                }}
              >
                <CIcon
                  content={freeSet.cilSpreadsheet}
                  customClasses="inventory-sidebar-menu-icon"
                />
              </div>
            </CTooltip>
            <CTooltip content="Carbon Credits" placement="left">
              <div
                className={getInventoryInfoItemClass("carbon-credits", activeSection)}
                key={"menu-item-4"}
                onClick={() => {
                  if (expanded) {
                    setActiveSection("carbon-credits")
                  }
                }}
              >
                <CIcon content={freeSet.cilTask} customClasses="inventory-sidebar-menu-icon" />
              </div>
            </CTooltip>
          </>
        ) : (
          ""
        )}
        <CTooltip content="Emissions Category" placement="left">
          <div
            className={getInventoryInfoItemClass("suv", activeSection)}
            key={"menu-item-5"}
            onClick={() => {
              if (expanded) {
                setActiveSection("suv")
              }
            }}
          >
            <CIcon content={freeSet.cilChartPie} customClasses="inventory-sidebar-menu-icon" />
          </div>
        </CTooltip>
      </>
    )
  }

  const getProcessMenuItems = () => {
    return (
      <>
        <CTooltip content="Process Details" placement="left">
          <div
            className={getInventoryInfoItemClass("process-flows", activeSection)}
            key={"menu-item-6"}
            onClick={() => {
              if (expanded) {
                setActiveSection("process-flows")
              }
            }}
          >
            <CIcon content={freeSet.cilLan} customClasses="inventory-sidebar-menu-icon" />
          </div>
        </CTooltip>
        <CTooltip content="Direct Emissions" placement="left">
          <div
            className={getInventoryInfoItemClass("site-emissions", activeSection)}
            key={"menu-item-7"}
            onClick={() => {
              if (expanded) {
                setActiveSection("site-emissions")
              }
            }}
          >
            <CIcon content={freeSet.cilFactory} customClasses="inventory-sidebar-menu-icon" />
          </div>
        </CTooltip>
      </>
    )
  }

  return (
    <>
      <div className={getSidebarClass(expanded)}>
        {expanded ? (
          <div className="inventory-sidebar-container">
            <div className="inventory-sidebar-title">{sections[activeSection]?.title}</div>
            <div className="inventory-sidebar-content">{sections[activeSection]?.content}</div>
          </div>
        ) : (
          ""
        )}
        <div className="inventory-sidebar-menu d-flex">
          {
            <div className="inventory-sidebar-menu-info">
              <div key={"sidebar-menu-inventory"}>
                {inventorySelected(genealogyItem) ? getInventoryMenuItems() : getProcessMenuItems()}
              </div>
            </div>
          }
          {/* <div className="inventory-sidebar-menu-actions">
            <div>
              <div className="inventory-sidebar-menu-item"></div>
              <div className="inventory-sidebar-menu-item"></div>
              {genealogyItem.published ? (
                <CTooltip content="View Emissions Certificate" placement="left">
                  <div
                    className={getInventoryActionItemClass(!genealogyItem.published)}
                    onClick={() => {
                      if (genealogyItem.published) {
                        const attestationUnitId =
                          isUrlShare || isExternalShare ? shareId : genealogyItem.attestationUnitId
                        if (isPublic) {
                          if (isExternalShare) {
                            history.push("/public-report/share-external/get/" + attestationUnitId)
                          } else {
                            if (isUrlShare) {
                              history.push("/public-report/share/get/" + attestationUnitId)
                            } else {
                              history.push("/public-report/get/" + attestationUnitId)
                            }
                          }
                        } else {
                          history.push("/report/get/" + attestationUnitId)
                        }
                      }
                    }}
                  >
                    <img
                      src={IconReport}
                      className="inventory-sidebar-menu-icon"
                      alt="icon-report"
                    />
                  </div>
                </CTooltip>
              ) : (
                ""
              )}
            </div>
          </div> */}
        </div>
      </div>
    </>
  )
}

export default ProductExplorerSidebar
