import request from "../Request"

const deleteProcessSystem = (params, completedCallback, errorCallback) => {
  request.call({
    path: "/inventory/api/process-system/" + params.id,
    method: "delete",
    completedCallback: completedCallback,
    errorCallback: errorCallback,
    responseType: "text",
  })
}

export default deleteProcessSystem
