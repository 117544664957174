import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from "@coreui/react"
import { useState } from "react"
import HeadingCarbonLogo from "../assets/icons/heading-carbon-sig-logo.svg"

const TermsOfServiceModal = ({ show, onClose, onAgree, showAgreeButton }) => {
  const [scrolledToBottom, setScrolledToBottom] = useState(false)

  if (!showAgreeButton) {
    showAgreeButton = false
  }
  if (!onAgree) {
    onAgree = () => {}
  }
  const handleScroll = (e) => {
    const bottomStart = e.target.clientHeight - 5
    const bottomEnd = e.target.clientHeight + 5
    const scrollPosition = e.target.scrollHeight - e.target.scrollTop

    const bottom = scrollPosition > bottomStart && scrollPosition < bottomEnd

    if (bottom) {
      setScrolledToBottom(true)
    }
  }

  return (
    <CModal
      closeOnBackdrop={false}
      style={{
        borderRadius: 0,
        padding: 20,
        paddingLeft: 30,
        paddingRight: 30,
        borderColor: "white",
        bottom: 20,
      }}
      className="tos-modal"
      show={show}
      onClose={onClose}
    >
      <CModalHeader className="modal-heading">
        <CModalTitle className="span-heading">
          <img className="heading-img" src={HeadingCarbonLogo} alt="logo" />
          Terms of Service
        </CModalTitle>
      </CModalHeader>
      <CModalBody>
        <div className="tos-modal-text my-2" onScroll={handleScroll}>
          Welcome to CarbonSig! These Terms of Service (these “Terms”) of Carbon Finance Labs, LLC
          (“Company,” “we,” or “us”) are an agreement that describes your rights and
          responsibilities as a CarbonSig user. This page explains the terms by which you may access
          and use our online services, website, software, and documentation provided on or in
          connection with the service (collectively, the “Service”). By accessing or using the
          Service, or by clicking a button or checking a box marked “I Agree” (or something
          similar), you signify that you have read, understood, and agree to be bound by these
          Terms, whether or not you are a registered user of our Service. You also understand and
          acknowledge that your personal information will be collected, used, and otherwise
          processed in accordance with our Privacy Notice, available at [insert URL]. Company
          reserves the right to modify these Terms and will provide notice of these changes as
          described below. These Terms apply to all visitors, users, and others who access the
          Service (“Users ”), including any Users who may access the Service via our third-party
          reseller partners (each a “Reseller”), and any Resellers who access the Service. These
          Terms and any written agreement by which you sign up for the Service (“Order Form(s)”)
          (or, if applicable, your written agreement with a Reseller) together with all documents
          referenced herein form the “Agreement” between you and us.
          <br />
          PLEASE READ THESE TERMS CAREFULLY TO ENSURE THAT YOU UNDERSTAND EACH PROVISION. THESE
          TERMS CONTAIN A MANDATORY INDIVIDUAL ARBITRATION PROVISION IN SECTION 15.2 (THE
          “ARBITRATION AGREEMENT”) AND CLASS ACTION/JURY TRIAL WAIVER PROVISION IN SECTION 15.3 (THE
          “CLASS ACTION/JURY TRIAL WAIVER”) THAT REQUIRE, UNLESS YOU OPT OUT PURSUANT TO THE
          INSTRUCTIONS IN SECTION 15.2, THE EXCLUSIVE USE OF FINAL AND BINDING ARBITRATION ON AN
          INDIVIDUAL BASIS TO RESOLVE DISPUTES BETWEEN YOU AND US, INCLUDING ANY CLAIMS THAT AROSE
          OR WERE ASSERTED BEFORE YOU AGREED TO THESE TERMS. TO THE FULLEST EXTENT PERMITTED BY LAW,
          YOU EXPRESSLY WAIVE YOUR RIGHT TO SEEK RELIEF IN A COURT OF LAW AND TO HAVE A JURY TRIAL
          ON YOUR CLAIMS, AS WELL AS YOUR RIGHT TO PARTICIPATE AS A PLAINTIFF OR CLASS MEMBER IN ANY
          CLASS, COLLECTIVE, PRIVATE ATTORNEY GENERAL OR REPRESENTATIVE ACTION OR PROCEEDING.
          <br />
          1. &nbsp;&nbsp;&nbsp; Our Service Company provides a cloud based software platform (the
          “Platform”) that allows Users to model, record, and transfer carbon emissions associated
          with their production of goods and services via Certificates and facilitates interactions
          between Users regarding the transfer and viewing of Certificates. As used herein, a
          “Certificate” means a report of carbon emissions associated with one or more products
          generated by or on behalf of a User via the Platform.
          <br />
          1.1 &nbsp;&nbsp;&nbsp; Eligibility; Authorized Representatives This is a contract between
          you and Company. “You” or “Customer” is either you, if you are an individual entering into
          the Agreement on your own behalf, or the organization that you represent in agreeing to
          the Agreement. You must read and agree to these Terms before using the Company Service. If
          you do not agree, you may not use the Service. You may use the Service only if you can
          form a binding contract with Company, and only in compliance with these Terms and all
          applicable local, state, national, and international laws, rules and regulations. If you
          signed up for access to the Service using your corporate email domain or are otherwise
          using the Service on behalf of a business entity or other organization, the business
          entity or other organization on whose behalf you signed up is the Customer/you. By signing
          up on behalf of your business entity or other organization, you represent and warrant that
          you have all right, power, and authority to bind such entity or organization to the
          Agreement. Any use or access to the Service by anyone under 18 is strictly prohibited and
          in violation of these Terms. The Service is not available to any Users previously removed
          from the Service by Company. If the Agreement authorizes the resale or supply of the
          Service under a reseller program, then all references to “you” or “Customer” in these
          Terms shall be deemed to include Reseller (except as may be expressly modified by a
          separate written Reseller agreement). Individuals authorized by you to access the Service
          (each an “Authorized Representative”) may submit content or information to the Service,
          which constitutes User Content (defined below), and you will have the sole right and
          responsibility for managing your use of it. You will be solely responsible for all of the
          acts and omissions of your Authorized Representatives in relation to the Service and the
          Agreement. You will (a) inform Authorized Representatives of all your policies and
          practices that are relevant to their use of the Service; and (b) obtain all rights,
          permissions or consents from Authorized Representatives and your other personnel that are
          necessary to grant the rights and licenses in the Agreement and for the lawful use and
          transmission of User Content and the operation of the Service.
          <br />
          1.2 &nbsp;&nbsp;&nbsp; Limited License Subject to your complete and ongoing compliance
          with these Terms, you are hereby granted a non-exclusive, limited, non-transferable,
          freely revocable license to use the Service as permitted by the features of the Service
          and in accordance with the Documentation (defined below). From time to time we may make
          available product documentation for the Service, including usage guidelines and principles
          (the “Documentation”) via a method of our choosing (e.g., via the Service). We grant to
          you a non-sublicensable, non-transferable, non-exclusive, limited license for you and your
          Authorized Representatives to use the Documentation to support your use of the Service.
          Company reserves all rights not expressly granted herein in the Service and the Company
          Content (as defined below). Company may terminate this license at any time for any reason
          or no reason.
          <br />
          1.3 &nbsp;&nbsp;&nbsp; User Accounts To access most features of the Service, you must
          register for an account. Your account on the Service (your “User Account”) gives you
          access to certain services and functionality that we may establish and maintain from time
          to time and in our sole discretion. You are solely responsible for maintaining the
          confidentiality of your account and password, and you accept responsibility for all
          activities that occur under your account. If you connect to the Service via a Reseller,
          you give us permission to access and use your information provided to such Reseller for
          purposes of establishing your User Account and you authorize such Reseller to take certain
          actions on the Platform on your behalf, subject to the terms of your agreement with
          Reseller. IF YOU CONNECT TO THE SERVICE VIA A RESELLER, COMPANY WILL NOT BE A PARTY TO OR
          HAVE ANY OBLIGATION TO MONITOR ANY INTERACTION BETWEEN YOU AND ANY RESELLER AND COMPANY
          SHALL HAVE NO LIABILITY FOR YOUR INTERACTIONS WITH SUCH RESELLERS, OR FOR ANY RESELLERS’
          ACTION OR INACTION. You may never use another User’s User Account without permission. When
          creating your User Account, you may be required to provide us with some information about
          yourself, such as a username, email address, or other contact information. You must
          provide accurate and complete information, and you must keep this information up to date.
          When you register, you will be asked to provide a password. You must keep your User
          Account password secure. We encourage you to use “strong” passwords (passwords that use a
          combination of upper and lower case letters, numbers and symbols) with your User Account.
          You must notify Company immediately of any breach of security or unauthorized use of your
          User Account. Company will not be liable for any losses caused by any unauthorized use of
          your User Account. You may control your User profile and how you interact with the Service
          by changing the settings in your settings page. By providing Company your email address
          you consent to our using the email address to send you Service-related notices, such as
          notices of requests from other Users, notices of Service-related disruptions, and any
          notices required by law, in lieu of communication by postal mail. We may also use your
          email address to send you other messages, such as changes to features of the Service and
          special offers. If you do not want to receive such email messages, you may opt out. Opting
          out may prevent you from receiving email messages regarding updates, improvements, or
          offers.
          <br />
          1.4 &nbsp;&nbsp;&nbsp; Changes to the Service We may, without prior notice, change the
          Service; stop providing the Service or features of the Service, to you or to Users
          generally; or create usage limits for the Service. We may permanently or temporarily
          terminate or suspend your access to the Service without notice and liability for any
          reason, including if in our sole determination you violate any provision of these Terms,
          or for no reason. Upon termination for any reason or no reason, you continue to be bound
          by these Terms.
          <br />
          1.5 &nbsp;&nbsp;&nbsp; Beta Products Occasionally, we look for beta testers to help us
          test our new features. These features will be identified as “beta” or “pre-release,” or
          words or phrases with similar meanings (each, a “Beta Product”). Beta Products are made
          available on an “as is,” and “as available” basis and, to the extent permitted under
          applicable law, without any warranties or contractual commitments we make for the Service.
          <br />
          1.6 &nbsp;&nbsp;&nbsp; Trials If designated on the applicable Order Form, you may use the
          Service in accordance with this Agreement for the limited trial period designated on the
          Order Form or such other period as may be specified by Company (the “Trial Period”) (such
          Service plan, a “Trial”). Trials are permitted solely for your internal use during the
          Trial Period subject to the terms of the applicable Order Form. Trials may not include all
          features or functionality offered as part of the Service. NOTWITHSTANDING ANYTHING TO THE
          CONTRARY THIS AGREEMENT. WE DO NOT PROVIDE ANY WARRANTY OR INDEMNIFICATION OF ANY KIND
          WITH RESPECT TO THE SERVICE DURING THE TRIAL PERIOD.
          <br />
          1.7 &nbsp;&nbsp;&nbsp; Service Location The Service is primarily controlled and operated
          from facilities in the United States. Company makes no representations that the Service is
          appropriate or available for use in other locations. Those who access or use the Service
          from other jurisdictions do so at their own volition and are entirely responsible for
          compliance with all applicable United States and local laws and regulations, including but
          not limited to export and import regulations. You may not use the Service if you are a
          resident of a country embargoed by the United States, or are a foreign person or entity
          blocked or denied by the United States government. Unless otherwise explicitly stated, all
          materials found on the Service are solely directed to individuals, companies, or other
          entities located in the United States.
          <br />
          2. &nbsp;&nbsp;&nbsp; Use of the Service
          <br />
          2.1 &nbsp;&nbsp;&nbsp; General You must comply with the Agreement and ensure that your
          Authorized Representatives comply with the Agreement. We may review conduct for compliance
          purposes, but we have no obligation to do so. If we believe there is a violation of the
          Agreement that can be remedied by your removal of certain User Content (defined below), we
          may ask you to take direct action rather than intervene. However, we reserve the right to
          take further appropriate action, when we deem it reasonably appropriate if you do not take
          appropriate action, or if we believe there is a credible risk of harm to us, the Service,
          Authorized Representatives, other Users, or any other third parties.
          <br />
          2.2 &nbsp;&nbsp;&nbsp; Service Rules You agree not to engage in any of the following
          prohibited activities: (a) copying, distributing, or disclosing any part of the Service in
          any medium, including without limitation by any automated or non-automated “scraping”; (b)
          using any automated system, including without limitation “robots,” “spiders,” “offline
          readers,” etc., to access the Service in a manner that sends more request messages to the
          Company servers than a human can reasonably produce in the same period of time by using a
          conventional on-line web browser (except that Company grants the operators of public
          search engines revocable permission to use spiders to copy publicly available materials
          from the Service for the sole purpose of and solely to the extent necessary for creating
          publicly available searchable indices of the materials, but not caches or archives of such
          materials); (c) transmitting spam, chain letters, or other unsolicited email; (d)
          attempting to interfere with, compromise the system integrity or security or decipher any
          transmissions to or from the servers running the Service; (e) taking any action that
          imposes, or may impose at our sole discretion an unreasonable or disproportionately large
          load on our infrastructure; (f) uploading invalid data, viruses, worms, or other software
          agents through the Service; (g) collecting or harvesting any personally identifiable
          information, including account names, from the Service; (h) using the Service for any
          commercial solicitation purposes; (i) impersonating another person or otherwise
          misrepresenting your affiliation with a person or entity, conducting fraud, hiding or
          attempting to hide your identity; (j) interfering with the proper working of the Service;
          (k) accessing any content on the Service through any technology or means other than those
          provided or authorized by the Service; (l) bypassing the measures we may use to prevent or
          restrict access to the Service, including without limitation features that prevent or
          restrict use or copying of any content or enforce limitations on use of the Service or the
          content therein; or (m) otherwise using the Service except as expressly directed in the
          Agreement and in accordance with the Documentation.
          <br />
          3. &nbsp;&nbsp;&nbsp; User Content
          <br />
          3.1 &nbsp;&nbsp;&nbsp; Definition The Service allows Users to submit, provide, display,
          model, upload or otherwise make available carbon emissions data, and other related
          information and content (any such materials a User submits, provides, displays, models,
          uploads, or otherwise makes available on the Service is referred to as “User Content”).
          Some areas of the Service allow Users to publish, transfer, or otherwise make public
          certain User Content via Certificates or contribute certain User Content to the Service
          for use by other Users (any such materials a User publishes, transfers, makes public or
          contributes for use by other Users is referred to as “Public Content”). Any portion of the
          User Content that you do not publish, transfer or otherwise make available to the other
          Users is referred to as “Private Content”.
          <br />
          3.2 &nbsp;&nbsp;&nbsp; Proprietary Rights in User Content WE CLAIM NO OWNERSHIP RIGHTS
          OVER USER CONTENT CREATED BY YOU. THE USER CONTENT YOU CREATE REMAINS YOURS. However, you
          understand that certain portions of the Service may allow other Users to view, edit,
          share, and/or otherwise interact with your Public Content. By publishing or sharing Public
          Content through the Service, you agree to allow others to view, edit, share, and/or
          interact with your Public Content in accordance with these Terms. You are under no
          obligation to publish or share your User Content as Public Content and we will not make
          your Private Content available as Public Content without your affirmative action
          authorizing such publishing or sharing. If you do not agree to abide by the applicable
          terms for your Public Content, then you should not authorize such publishing or sharing.
          Company has the right (but not the obligation) in its sole discretion to remove any User
          Content that is shared via the Service. You shall retain all right, title and interest
          (including any and all Intellectual Property Rights (defined below)) in and to the Private
          Content. By submitting, uploading, posting, displaying, providing, publishing, sharing, or
          otherwise making available any Private Content on or through the Service, you expressly
          grant, and you represent and warrant that you have all rights necessary to grant, to
          Company a non-exclusive, worldwide, irrevocable, transferable, sublicensable (through
          multiple tiers), fully paid-up, royalty-free right and license to use, copy store,
          transmit, modify, and display the Private Content in order to: (a) provide the Service to
          you; (b) improve Company’s products and services, to develop new products and services,
          and for its other internal business purposes; and (c) perform such other actions as
          authorized or instructed by you. By submitting, uploading, posting, displaying, providing,
          publishing, sharing, or otherwise making available any Public Content, including
          Certificates, on or through the Service, you expressly grant, and you represent and
          warrant that you have all rights necessary to grant, to Company a royalty-free,
          sublicensable (through multiple tiers), transferable, perpetual, irrevocable,
          non-exclusive, worldwide license to use, reproduce, modify, publish, list information
          regarding, edit, translate, distribute, syndicate, publicly perform, publicly display, and
          make derivative works of all such Public Content and your name, voice, and/or likeness as
          contained in your Public Content, in whole or in part, and in any form, media or
          technology, whether now known or hereafter developed, for use in connection with the
          Service and Company’s (and its successors’ and affiliates’) business, including without
          limitation for promoting and redistributing part or all of the Service (and derivative
          works thereof) in any media formats and through any media channels. You also hereby grant
          each User of the Service a non-exclusive license to access your Public Content, and to
          use, reproduce, distribute, display and perform such Public Content as permitted through
          the functionality of the Service and under these Terms.
          <br />
          3.3 &nbsp;&nbsp;&nbsp; Warranties Regarding User Content For the purposes of these Terms,
          “Intellectual Property Rights” means all patent rights, copyright rights, mask work
          rights, moral rights, rights of publicity, trademark, trade dress and service mark rights,
          goodwill, trade secret rights and other intellectual property rights as may now exist or
          hereafter come into existence, and all applications therefore and registrations, renewals
          and extensions thereof, under the laws of any state, country, territory or other
          jurisdiction. In connection with your User Content, you affirm, represent and warrant the
          following: • You have the written consent of each and every identifiable natural person in
          the User Content, if any, to use such person’s name or likeness in the manner contemplated
          by the Service and these Terms, and each such person has released you from any liability
          that may arise in relation to such use. • You have obtained and are solely responsible for
          obtaining all consents as may be required by law to post any User Content relating to
          third parties. • Your User Content and Company’s use thereof as contemplated by these
          Terms and the Service will not violate any law or violate, misappropriate or infringe any
          rights of any third party, including but not limited to any Intellectual Property Rights
          and privacy rights. • Company may exercise the rights to your User Content granted under
          these Terms without liability for payment of any guild fees, residuals, payments, fees, or
          royalties payable under any collective bargaining agreement or otherwise. • You will not
          upload or make available through our Service: abusive, harassing, tortious, defamatory,
          vulgar, obscene, libelous, invasive of another’s privacy, hateful racially, ethnically, or
          otherwise objectionable content; fake or impostor profiles; illegal content or content in
          furtherance of harmful or illegal activities; malicious programs or code; any person’s
          personal information without their consent; and/or spam, machine-generated content, or
          unsolicited messages. • [To the best of your knowledge, ]All your User Content and other
          information that you provide to us or submit on the Platform is truthful and accurate. •
          [•]
          <br />
          3.4 &nbsp;&nbsp;&nbsp; Responsibility for User Content We are not responsible for any User
          Content or the way Customer or its Authorized Representatives choose to use the Service to
          store or process any User Content. You represent and agree that you are solely responsible
          for (a) providing notices and obtaining consents as legally required from Customer or its
          Authorized Representatives for the collection, use, processing and transfer of your User
          Content in connection with the Service; and (b) ensuring compliance with all laws in all
          jurisdictions that may apply to your User Content provided hereunder, including but not
          limited to all applicable international, federal, state, provincial and local laws, rules,
          and regulations relating to data privacy and security. Unless otherwise agreed to in
          writing, Customer may not submit any User Content that includes a social security number,
          passport number, driver’s license number, or similar identifier, credit card or debit card
          number, or any other information which may be subject to specific data privacy and
          security laws including, but not limited to, the Gramm-Leach-Bliley Act (GLBA), the Health
          Insurance Portability and Accountability Act (HIPAA), the Health Information Technology
          for Economic and Clinical Health Act (HiTECH), the Family Educational Rights and Privacy
          Act of 1974 (FERPA), the Children’s Online Privacy Protection Act (COPPA), or the General
          Data Protection Regulation (GDPR) or any other data which is considered to be sensitive or
          which could give rise to notification obligations under applicable data breach
          notification laws. We do not make any representations as to the adequacy of the Service to
          process your User Content or to satisfy any legal or compliance requirements which may
          apply to your User Content, other than as described herein and for the limited purpose of
          transacting on the Platform as described in Section 1 of these Terms. Company takes no
          responsibility and assumes no liability for any User Content that you or any other User or
          third party posts, sends, or otherwise makes available over the Service. You agree that we
          are only acting as a passive conduit for the online distribution and publication of Users’
          User Content. You understand and agree that you may be exposed to User Content that is
          inaccurate, objectionable, or otherwise unsuited to your purpose, and you agree that
          Company shall not be liable for any damages you allege to incur as a result of or relating
          to any User Content.
          <br />
          4. &nbsp;&nbsp;&nbsp; Our Proprietary Rights Except for your User Content, the Service and
          all materials therein or transferred thereby, including, without limitation, software,
          images, text, graphics, illustrations, logos, patents, trademarks, service marks,
          copyrights, photographs, audio, videos, and User Content belonging to other Users, and the
          Documentation, and any intellectual property Company develops hereunder, and any
          derivatives thereof (the “Company Content”), and all Intellectual Property Rights related
          thereto, are the exclusive property of Company and its licensors (including other Users
          who post User Content to the Service). Except as explicitly provided herein, nothing in
          these Terms shall be deemed to create a license in or under any such Intellectual Property
          Rights, and you agree not to sell, license, rent, modify, distribute, copy, reproduce,
          transmit, publicly display, publicly perform, publish, adapt, edit or create derivative
          works from any Company Content. Use of the Company Content, including any Company Content
          embodied in the Certificates, for any purpose not expressly permitted by these Terms is
          strictly prohibited. In the event that you submit suggestions, comments, feedback or any
          other ideas about the Service, including without limitation about how to improve any
          aspect of the Service or our products, including the Platform (“Feedback”), you hereby
          assign to Company all right, title and interest in and to the Feedback, including all
          Intellectual Property Rights therein, and acknowledge that Company: (a) shall own such
          Feedback; (b) has no obligation to review your Feedback; (c) may implement and distribute
          any portion of your Feedback for any purpose in any way, without any compensation to you;
          and (d) has no obligation to keep your Feedback confidential.
          <br />
          5. &nbsp;&nbsp;&nbsp; User Transactions on the Platform; Certificates
          <br />
          5.1 &nbsp;&nbsp;&nbsp; Generating a Certificate You understand that once you publish a
          Certificate, that Certificate enters the public domain and is visible to the public via
          the Service. Each Certificate is verified on a blockchain, and once published the
          Certificate cannot be modified or deleted by you. You represent and warrant that, before
          you publish a Certificate, you will ensure that all User Content embodied in the
          Certificate complies with these Terms. [•]
          <br />
          5.2 &nbsp;&nbsp;&nbsp; Transferring Certificates The Platform allows Users (each a
          “Transferor”) to transfer Certificates to other Users (each a “Transferee”) through the
          Service. A Transferor may transfer Certificates to Transferees at its discretion. Such
          transfers may be subject to third-party arrangements between Transferors and Transferees.
          You agree that if you choose to transfer a Certificate via the Service you are responsible
          for determining the terms and conditions applicable to your transfer of the Certificate to
          the applicable Transferee. Each Certificate may only be transferred using the
          functionality of the Service (and subject to any associated fees). By transferring a
          Certificate to a Transferee, you represent and warrant that: (a) you are the sole owner of
          all rights, including any credits or environmental instruments, transferred in connection
          with the Certificate; and (b) such rights have not previously been transferred by you to
          any other person. [Company takes no responsibility and assumes no liability for any
          arrangements that you or any other User enter into regarding the transfer of a
          Certificate.]
          <br />
          5.3 &nbsp;&nbsp;&nbsp; [Use of Certificates] We grant you a license to use our
          Intellectual Property Rights embodied in the Certificates solely as permitted through the
          functionality of the Service and under these Terms. Without limiting the generality of the
          foregoing, you agree not to sell, license, rent, modify, distribute, copy, reproduce,
          transmit, publicly display, publicly perform, publish, adapt, edit or create derivative
          works of any Certificates except as permitted through the functionality of the Service and
          by these Terms. You acknowledge and agree that you will not use any Certificates off of
          the Platform. Any fraudulent statement, omission, or other misstatement in connection with
          a Certificate, or fraudulent use or misuse of a Certificate in violation of these Terms
          may result in the Certificate, including all rights embodied thereby, being revoked and
          the Certificate being voided.
          <br />
          5.4 &nbsp;&nbsp;&nbsp; No Warranties We do not verify the accuracy of or make any claims
          to the accuracy of the Certificates or any information linked to or associated with any
          Certificate. We disclaim all liability for any misrepresentation, misuse, double counting
          or duplicate pledging of any Certificate (or environmental instruments embodied thereby).
          <br />
          6. &nbsp;&nbsp;&nbsp; Paid Services
          <br />
          6.1 &nbsp;&nbsp;&nbsp; Billing Policies. Certain aspects of the Service may be provided
          for a fee or other charge. If you elect to use paid aspects of the Service, you agree to
          the pricing and payment terms included in the Order Form.
          <br />
          6.2 &nbsp;&nbsp;&nbsp; No Refunds; Cancellation. You may cancel your User Account upon
          thirty (30) days’ notice; however, there are no refunds for cancellation. In the event
          that Company suspends or terminates your User Account or these Terms for any reason Terms,
          you understand and agree that you shall receive no refund or exchange for any
          Certificates, any rights (including environmental instruments) embodied by any
          Certificates, any unused time on a subscription, any license or subscription fees for any
          portion of the Service, any content, data or rights (including environmental instruments)
          associated with your User Account, or for anything else. Upon the termination or
          suspension of your User Account for any reason: (a) your Private Content will be archived;
          (b) your Public Content will remain publicly available via the Service; (c) all pending
          Certificate transfer requests will be revoked, and (d) your rights to receive or transfer
          Certificates are revoked. Any carbon credits associated with your User Account cannot be
          removed from the Service, but may be transferred to another User prior to the suspension
          or termination of your User Account.
          <br />
          6.3 &nbsp;&nbsp;&nbsp; [Automatic Renewal of Subscription Fees. Unless the Order Form
          states otherwise, your subscription to the Service (as set forth in the Order Form) will
          automatically renew upon the expiration of the initial subscription period for subsequent
          subscription periods of the same period as the initial subscription period, unless and
          until a party provides the other party written notice of its intent not to renew the
          Agreement at least thirty (30) days prior to the end of the then-current subscription
          period. IF YOU SIGN UP FOR A SUBSCRIPTION, THE CORRESPONDING FEES AT THE RATE AS SET FORTH
          IN THE ORDER FORM WILL AUTOMATICALLY RENEW EVERY MONTH ON A CONTINUOUS BASIS UNLESS AND
          UNTIL YOU NOTIFY US THAT YOU WANT TO CANCEL YOUR SUBSCRIPTION. ANY NOTICE OF CANCELLATION
          MUST BE SUBMITTED IN WRITING AND SENT TO [LEGAL@COMPANY.COM] AND WILL BE EFFECTIVE IN THE
          MONTH FOLLOWING THE MONTH YOU PROVIDE US NOTICE. YOU UNDERSTAND THAT UNLESS AND UNTIL YOU
          NOTIFY US OF YOUR CANCELLATION, YOUR SUBSCRIPTION AND ALL CORRESPONDING FEES WILL
          AUTOMATICALLY RENEW, AND YOU AUTHORIZE US OR OUR AUTHORIZED THIRD PARTY PAYMENT PROVIDER
          (WITHOUT NOTICE TO YOU, UNLESS REQUIRED BY APPLICABLE LAW) TO CHARGE YOU THE APPLICABLE
          SUBSCRIPTION FEES AND ANY TAXES, USING ANY ELIGIBLE PAYMENT METHOD WE HAVE ON RECORD FOR
          YOU.]
          <br />
          6.4 &nbsp;&nbsp;&nbsp; Payment Information; Taxes. We accept various payment methods,
          including through Stripe, including, without limitation, Mastercard, Visa, and American
          Express. By using our Service, you agree to be bound by Stripe’s Services Agreement
          available at https://stripe.com/us/legal. All information that you provide in connection
          with a purchase or transaction with the Service must be accurate, complete, and current.
          You agree to pay all charges incurred by users of your payment method used in connection
          with a purchase or transaction or other monetary transaction interaction with the Service
          at the prices in effect when such charges are incurred. You will pay all applicable taxes,
          if any, relating to any such purchases and transactions.
          <br />
          6.5 &nbsp;&nbsp;&nbsp; California Residents. The provider of services is set forth herein.
          If you are a California resident, in accordance with Cal. Civ. Code §1789.3, you may
          report complaints to the Complaint Assistance Unit of the Division of Consumer Services of
          the California Department of Consumer Affairs by contacting them in writing at 1625 North
          Market Blvd., Suite N 112 Sacramento, CA 95834, or by telephone at (800) 952-5210 or (916)
          445-1254.
          <br />
          7. &nbsp;&nbsp;&nbsp; Authorization to Contact You expressly consent and agree that
          Company can contact you using written, electronic, or verbal means, including by manual
          dialing, emails, leaving prerecorded/artificial voice messages or using an automatic
          telephone dialing system to call or text your mobile/cellular telephone number, as
          necessary to complete transactions requested by you and to service your account and as the
          law allows, even if those phone numbers are registered by you on any federal or state
          Do-Not-Call/Do-Not-email registry.
          <br />
          8. &nbsp;&nbsp;&nbsp; Security We care about the integrity and security of your personal
          information and Private Content. We use commercially reasonable physical, managerial, and
          technical safeguards to preserve the integrity and security of your Private Content and
          your personal information. However, we cannot guarantee that unauthorized third parties
          will never be able to defeat our security measures or use your Private Content or personal
          information for improper purposes. You acknowledge that you provide your Private Content
          and personal information at your own risk.
          <br />
          9. &nbsp;&nbsp;&nbsp; DMCA Notice Since we respect artist and content owner rights, it is
          Company’s policy to respond to alleged infringement notices that comply with the Digital
          Millennium Copyright Act of 1998 (“DMCA”). If you believe that your copyrighted work has
          been copied in a way that constitutes copyright infringement and is accessible via the
          Service, please notify Company’s copyright agent as set forth in the DMCA. For your
          complaint to be valid under the DMCA, you must provide the following information in
          writing: 1. An electronic or physical signature of a person authorized to act on behalf of
          the copyright owner; 2. Identification of the copyrighted work that you claim has been
          infringed; 3. Identification of the material that is claimed to be infringing and where it
          is located on the Service; 4. Information reasonably sufficient to permit Company to
          contact you, such as your address, telephone number, and, e-mail address; 5. A statement
          that you have a good faith belief that use of the material in the manner complained of is
          not authorized by the copyright owner, its agent, or law; and 6. A statement, made under
          penalty of perjury, that the above information is accurate, and that you are the copyright
          owner or are authorized to act on behalf of the owner. The above information must be
          submitted to the following DMCA Agent: Attn: DMCA Notice Company, Inc. Address: [insert]
          [insert] Tel.: [insert] Email: [copyright@company.com] UNDER FEDERAL LAW, IF YOU KNOWINGLY
          MISREPRESENT THAT ONLINE MATERIAL IS INFRINGING, YOU MAY BE SUBJECT TO CRIMINAL
          PROSECUTION FOR PERJURY AND CIVIL PENALTIES, INCLUDING MONETARY DAMAGES, COURT COSTS, AND
          ATTORNEYS’ FEES. Please note that this procedure is exclusively for notifying Company and
          its affiliates that your copyrighted material has been infringed. The preceding
          requirements are intended to comply with Company’s rights and obligations under the DMCA,
          including 17 U.S.C. §512(c), but do not constitute legal advice. It may be advisable to
          contact an attorney regarding your rights and obligations under the DMCA and other
          applicable laws. In accordance with the DMCA and other applicable law, Company has adopted
          a policy of terminating, in appropriate circumstances, Users who are deemed to be repeat
          infringers. Company may also at its sole discretion limit access to the Service and/or
          terminate the User Accounts of any Users who infringe any intellectual property rights of
          others, whether or not there is any repeat infringement.
          <br />
          10. &nbsp;&nbsp;&nbsp; Third Party Terms
          <br />
          10.1 &nbsp;&nbsp;&nbsp; Third-Party Links and Information The Service may contain links to
          third-party materials that are not owned or controlled by Company, or allow Users to embed
          content from other websites, apps or other properties that are not owned or controlled by
          Company. Company does not endorse or assume any responsibility for any such third-party
          sites, information, materials, products, or services. You should take precautions when
          downloading files from all websites to protect your devices from viruses and other
          destructive programs. If you access a third-party website, information, material, or
          service from the Service or share your User Content on or through any third-party website
          or service, you do so at your own risk, and you understand that these Terms and Company’s
          Privacy Notice do not apply to your use of such sites. You expressly relieve Company from
          any and all liability arising from your use of any third-party website, service, or
          content, including without limitation User Content submitted by other Users.
          <br />
          10.2 &nbsp;&nbsp;&nbsp; Third-Party Components All or some portions of the Service may be
          subject to additional and/or separate terms and conditions, including but not limited to
          open source software license and other third-party software license terms and conditions
          (“Third-Party Components”). Notwithstanding anything in this Agreement to the contrary, to
          the extent that any such Third-Party Components are distributed by Company to you, all
          such Third-Party Components are made available on an “as is” basis without warranty of any
          kind. To the extent there is a conflict between the terms and conditions applicable to any
          such Third-Party Components and this Agreement, the Third-Party Components terms and
          conditions shall control.
          <br />
          10.3 &nbsp;&nbsp;&nbsp; Third-Party Products and Services Company may from time to time
          make third-party products, services, including proprietary data, provided with, integrated
          with, or incorporated into the Service (“Third-Party Products”) available to Users or
          Company may allow for certain Third-Party Products to be integrated with the Service. For
          purposes of this Agreement, such Third-Party Products are subject to their own terms and
          conditions. To the extent there is a conflict between the terms and conditions applicable
          to any such Third-Party Product and this Agreement, the Third-Party Product terms and
          conditions shall control. You are under no obligation to use any Third-Party Products. If
          you do not agree to abide by the applicable terms for any such Third-Party Products, then
          you should not install, download or use such Third-Party Products. ALL SUCH THIRD-PARTY
          PRODUCTS ARE MADE AVAILABLE ON AN “AS IS” BASIS WITHOUT WARRANTY OF ANY KIND. IF YOU USE
          ANY THIRD-PARTY PRODUCTS, COMPANY WILL NOT BE RESPONSIBLE FOR ANY ACT OR OMISSION OF ANY
          PROVIDER OF SUCH THIRD-PARTY PRODUCTS. YOU ACKNOWLEDGE AND AGREES THAT COMPANY WILL HAVE
          NO RESPONSIBILITY OR LIABILITY FOR THE ACTS OR OMISSIONS TAKEN UNDER YOUR USER ACCOUNT IN
          CONNECTION WITH ANY THIRD-PARTY PRODUCTS.
          <br />
          11. &nbsp;&nbsp;&nbsp; Indemnity You agree to defend, indemnify and hold harmless Company
          and its subsidiaries, agents, licensors, managers, and other affiliated companies, and
          their employees, contractors, agents, officers and directors, from and against any and all
          claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including
          but not limited to attorney’s fees) arising from: (a) your use of and access to the
          Service, including any data or content transmitted or received by you; (b) your violation
          of any term of this Agreement, including without limitation your breach of any of the
          representations and warranties above; (c) your violation of any third-party right,
          including without limitation any right of privacy or Intellectual Property Rights; (d)
          your violation of any applicable law, rule or regulation; (e) User Content or any content
          that is submitted via your User Account including without limitation misleading, false, or
          inaccurate information; (f) your willful misconduct; or (g) any other party’s access and
          use of the Service with your unique username, password or other appropriate security code.
          <br />
          12. &nbsp;&nbsp;&nbsp; No Warranty THE SERVICE IS PROVIDED ON AN “AS IS” AND “AS
          AVAILABLE” BASIS. USE OF THE SERVICE IS AT YOUR OWN RISK. TO THE MAXIMUM EXTENT PERMITTED
          BY APPLICABLE LAW, THE SERVICE IS PROVIDED WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS
          OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
          FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. NO ADVICE OR INFORMATION, WHETHER ORAL OR
          WRITTEN, OBTAINED BY YOU FROM COMPANY OR THROUGH THE SERVICE WILL CREATE ANY WARRANTY NOT
          EXPRESSLY STATED HEREIN. WITHOUT LIMITING THE FOREGOING, COMPANY, ITS SUBSIDIARIES, ITS
          AFFILIATES, AND ITS LICENSORS DO NOT WARRANT THAT THE CONTENT IS ACCURATE, RELIABLE OR
          CORRECT; THAT THE SERVICE WILL MEET YOUR REQUIREMENTS; THAT THE SERVICE WILL BE AVAILABLE
          AT ANY PARTICULAR TIME OR LOCATION, UNINTERRUPTED OR SECURE; THAT ANY DEFECTS OR ERRORS
          WILL BE CORRECTED; OR THAT THE SERVICE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. ANY
          CONTENT DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS DOWNLOADED AT
          YOUR OWN RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR
          MOBILE DEVICE OR LOSS OF DATA THAT RESULTS FROM SUCH DOWNLOAD OR YOUR USE OF THE SERVICE.
          YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS, AND THOSE MADE ON YOUR BEHALF BY YOUR
          AUTHORIZED REPRESENTATIVES, WITH OTHER USERS VIA THE SERVICE. COMPANY WILL NOT BE A PARTY
          TO OR IN ANY WAY MONITOR ANY TRANSACTION BETWEEN YOU, YOUR AUTHORIZED REPRESENTATIVES AND
          ANY OTHER USER OR THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. WE RESERVE THE RIGHT, BUT
          HAVE NO OBLIGATION, TO MONITOR DISPUTES BETWEEN YOU AND OTHER USERS OR THIRD-PARTY
          PROVIDERS OF PRODUCTS OR SERVICES, AND WE SHALL HAVE NO LIABILITY FOR YOUR INTERACTIONS
          WITH OTHER USERS OR THIRD PARTIES, OR FOR ANY USER’S ACTION OR INACTION. COMPANY MAKES NO
          REPRESENTATIONS ABOUT AND DOES NOT GUARANTEE, AND YOU AGREE NOT TO HOLD COMPANY
          RESPONSIBLE FOR: (A) THE QUALITY, SAFETY, ACCURACY OR LEGALITY OF ANY CERTIFICATE OR OTHER
          INFORMATION PROVIDED BY A USER OR ANY OTHER PRODUCTS OR SERVICES PROVIDED BY THIRD
          PARTIES; (B) ANY OTHER USER-TO-USER OR USER-TO-THIRD PARTY INTERACTION; (C) A USER’S
          FAILURE TO PROVIDE A CERTIFICATE OR ANY OTHER PROMISED INFORMATION, OR A THIRD PARTY’S
          FAILURE TO PROVIDE ANY PRODUCTS OR SERVICES OR ANY OTHER PROMISED INFORMATION; (D) THE
          QUALIFICATIONS, BACKGROUND, OR IDENTITIES OF USERS OR OTHER THIRD PARTIES; (E) THE ABILITY
          OF A USER TO PAY SUMS DUE IN CONNECTION WITH ANY SERVICES OR PRODUCTS PROVIDED IN
          CONNECTION WITH THE SERVICE; OR (F) ANY USER CONTENT, AND/OR OTHER STATEMENTS OR POSTS OF
          USERS. YOU HEREBY IRREVOCABLY AND FOREVER WAIVE ANY AND ALL CLAIMS YOU MAY HAVE WITH
          RESPECT TO THE FOREGOING. COMPANY OPERATES AS AN ONLINE PLATFORM THAT RECORDS CARBON
          EMISSIONS DATA AND TRANSFERS OF CLAIMS AND RIGHTS RELATED TO SUCH DATA BETWEEN. THE
          SERVICE IS INTENDED AS AN INFORMATION RESOURCE AND NOT AN ADVISORY SERVICE; IT IS YOUR
          RESPONSIBILITY TO DETERMINE THE QUALITY AND CREDIBILITY OF INFORMATION OBTAINED, AS WELL
          AS THE REASONABLENESS OF RELYING ON SUCH INFORMATION FOR A PARTICULAR USE. YOU
          ACKNOWLEDGE, AGREE, AND UNDERSTAND THAT YOU ARE SOLELY RESPONSIBLE FOR ASSESSING AND
          VERIFYING ANY INFORMATION ABOUT, OR PROVIDED BY, OTHER USERS AND OTHER THIRD PARTIES,
          INCLUDING ANY CERTIFICATES OR OTHER USER CONTENT. YOU HEREBY ACKNOWLEDGE THAT, EXCEPT AS
          EXPRESSLY PROVIDED HEREIN, COMPANY DOES NOT SUPERVISE, SCOPE, DIRECT, CONTROL OR MONITOR,
          AND EXPRESSLY DISCLAIMS (TO THE EXTENT PERMITTED BY LAW) ANY RESPONSIBILITY AND LIABILITY
          FOR, THE CERTIFICATES AND ANYTHING ELSE A USER OR THIRD PARTY MAY OFFER OR PROVIDE THROUGH
          OR IN CONNECTION WITH THE SERVICE INCLUDING BUT NOT LIMITED TO, ANY WARRANTY OR CONDITION
          OF GOOD AND WORKMANLIKE SERVICES, WARRANTY OR CONDITION OF QUALITY OR FITNESS FOR A
          PARTICULAR PURPOSE, OR COMPLIANCE WITH ANY LAW, STATUTE, ORDINANCE, REGULATION, OR CODE.
          FEDERAL LAW, SOME STATES, PROVINCES AND OTHER JURISDICTIONS DO NOT ALLOW THE EXCLUSION AND
          LIMITATIONS OF CERTAIN IMPLIED WARRANTIES, SO THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.
          THESE TERMS GIVE YOU SPECIFIC LEGAL RIGHTS, AND YOU MAY ALSO HAVE OTHER RIGHTS WHICH VARY
          FROM STATE TO STATE. THE DISCLAIMERS AND EXCLUSIONS UNDER THESE TERMS WILL NOT APPLY TO
          THE EXTENT PROHIBITED BY APPLICABLE LAW.
          <br />
          13. &nbsp;&nbsp;&nbsp; Limitation of Liability TO THE MAXIMUM EXTENT PERMITTED BY
          APPLICABLE LAW, IN NO EVENT SHALL COMPANY, ITS AFFILIATES, AGENTS, DIRECTORS, EMPLOYEES,
          SUPPLIERS OR LICENSORS BE LIABLE FOR ANY INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL,
          CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING WITHOUT LIMITATION DAMAGES FOR LOSS OF
          PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES, ARISING OUT OF OR RELATING TO THE
          USE OF, OR INABILITY TO USE, THE SERVICE. UNDER NO CIRCUMSTANCES WILL COMPANY BE
          RESPONSIBLE FOR ANY DAMAGE, LOSS OR INJURY RESULTING FROM HACKING, TAMPERING OR OTHER
          UNAUTHORIZED ACCESS OR USE OF THE SERVICE OR YOUR ACCOUNT OR THE INFORMATION CONTAINED
          THEREIN. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, COMPANY ASSUMES NO LIABILITY
          OR RESPONSIBILITY FOR ANY (A) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT; (B) PERSONAL
          INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO OR USE
          OF OUR SERVICE; (C) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND
          ALL PERSONAL INFORMATION STORED THEREIN; (D) ANY INTERRUPTION OR CESSATION OF TRANSMISSION
          TO OR FROM THE SERVICE; (E) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE THAT MAY BE
          TRANSMITTED TO OR THROUGH OUR SERVICE BY ANY THIRD PARTY; (F) ANY ERRORS OR OMISSIONS IN
          ANY CONTENT OR FOR ANY LOSS OR DAMAGE INCURRED AS A RESULT OF THE USE OF ANY CONTENT
          POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE THROUGH THE SERVICE; AND/OR (G)
          USER CONTENT OR THE DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY THIRD PARTY. IN NO
          EVENT SHALL COMPANY, ITS AFFILIATES, AGENTS, DIRECTORS, EMPLOYEES, SUPPLIERS, OR LICENSORS
          BE LIABLE TO YOU FOR ANY CLAIMS, PROCEEDINGS, LIABILITIES, OBLIGATIONS, DAMAGES, LOSSES OR
          COSTS IN AN AMOUNT EXCEEDING THE AMOUNT YOU PAID TO COMPANY HEREUNDER DURING THE 12 MONTH
          PERIOD PRIOR TO WHEN THE CLAIM AROSE, OR $50.00, WHICHEVER IS GREATER. THIS LIMITATION OF
          LIABILITY SECTION APPLIES WHETHER THE ALLEGED LIABILITY IS BASED ON CONTRACT, TORT,
          NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER BASIS, EVEN IF COMPANY HAS BEEN ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGE. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
          INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY
          TO YOU. THESE TERMS GIVE YOU SPECIFIC LEGAL RIGHTS, AND YOU MAY ALSO HAVE OTHER RIGHTS
          WHICH VARY FROM STATE TO STATE. THE DISCLAIMERS, EXCLUSIONS, AND LIMITATIONS OF LIABILITY
          UNDER THESE TERMS WILL NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.
          <br />
          14. &nbsp;&nbsp;&nbsp; Governing Law, Arbitration, and Class Action/Jury Trial Waiver
          <br />
          14.1 &nbsp;&nbsp;&nbsp; Governing Law. You agree that: (a) the Service shall be deemed
          solely based in Delaware; and (b) the Service shall be deemed a passive one that does not
          give rise to personal jurisdiction over us, either specific or general, in jurisdictions
          other than Delaware. These Terms shall be governed by the internal substantive laws of the
          State of Delaware, without respect to its conflict of laws principles. The parties
          acknowledge that these Terms evidence a transaction involving interstate commerce.
          Notwithstanding the preceding sentences with respect to the substantive law, the Federal
          Arbitration Act (9 U.S.C. §§ 1-16) (“FAA”) governs the interpretation and enforcement of
          the Arbitration Agreement in Section 15.2 and preempts all state laws to the fullest
          extent permitted by law. If the FAA is found to not apply to any issue that arises from or
          relates to the Arbitration Agreement, then that issue shall be resolved under and governed
          by the law of your state of residence. The application of the United Nations Convention on
          Contracts for the International Sale of Goods is expressly excluded. You agree to submit
          to the exclusive personal jurisdiction of the federal and state courts located in Delaware
          for any actions for which we retain the right to seek injunctive or other equitable relief
          in a court of competent jurisdiction to prevent the actual or threatened infringement,
          misappropriation or violation of a our copyrights, trademarks, trade secrets, patents, or
          other intellectual property or proprietary rights, as set forth in the Arbitration
          provision below, including any provisional relief required to prevent irreparable harm.
          You agree that Delaware is the proper and exclusive forum for any appeals of an
          arbitration award or for trial court proceedings in the event that the arbitration
          provision below is found to be unenforceable.
          <br />
          14.2 &nbsp;&nbsp;&nbsp; Arbitration. READ THIS SECTION CAREFULLY BECAUSE IT REQUIRES THE
          PARTIES TO ARBITRATE THEIR DISPUTES AND LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF
          FROM COMPANY. This Section 15.2 applies to and governs any dispute, controversy, or claim
          between you and Company that arises out of or relates to, directly or indirectly: (a)
          these Terms, including the formation, existence, breach, termination, enforcement,
          interpretation, validity, or enforceability thereof; (b) access to or use of the Service,
          including receipt of any advertising or marketing communications; (c) any transactions
          through, by, or using the Service; or (d) any other aspect of your relationship or
          transactions with Company, directly or indirectly, as a consumer (“Claim” or collectively,
          “Claims”). The Arbitration Agreement shall apply, without limitation, to all Claims that
          arose or were asserted before or after your agreement to these Terms. If you are a new
          User, you can reject and opt-out of this Arbitration Agreement within 30 days of accepting
          these Terms by emailing Company at [legal@company.com] with your first and last name and
          stating your intent to opt-out of the Arbitration Agreement. Note that opting out of this
          Arbitration Agreement does not affect any other part of these Terms, including the
          provisions regarding controlling law or in which courts any disputes must be brought. For
          any Claim, you agree to first contact us at [legal@company.com] and attempt to resolve the
          dispute with us informally. In the unlikely event that Company has not been able to
          resolve a Claim after sixty (60) days, we each agree to resolve any Claim exclusively
          through binding arbitration by AAA before a single arbitrator (the “Arbitrator”), under
          the Expedited Procedures then in effect for AAA (the “Rules”), except as provided herein.
          In the event of any conflict between the Rules and this Arbitration Agreement, this
          Arbitration Agreement shall control. AAA may be contacted at www.adr.org, where the Rules
          are also available. The arbitration will be conducted in the U.S. county where you live or
          Delaware, unless you and Company agree otherwise. If you are using the Service for
          commercial purposes, each party will be responsible for paying any AAA filing,
          administrative and arbitrator fees in accordance with AAA rules, and the award rendered by
          the Arbitrator shall include costs of arbitration, reasonable attorneys’ fees and
          reasonable costs for expert and other witnesses. If you are an individual using the
          Service for non-commercial purposes: (x) AAA may require you to pay a fee for the
          initiation of your case, unless you apply for and successfully obtain a fee waiver from
          AAA; (y) the award rendered by the Arbitrator may include your costs of arbitration, your
          reasonable attorney’s fees, and your reasonable costs for expert and other witnesses; and
          (z) you may sue in a small claims court of competent jurisdiction without first engaging
          in arbitration, but this does not absolve you of your commitment to engage in the informal
          dispute resolution process. Any judgment on the award rendered by the Arbitrator may be
          entered in any court of competent jurisdiction. You and Company agree that the Arbitrator,
          and not any federal, state, or local court or agency, shall have exclusive authority to
          resolve any disputes relating to the interpretation, applicability, enforceability or
          formation of this Arbitration Agreement, including any claim that all or any part of this
          Arbitration Agreement is void or voidable. The Arbitrator shall also be responsible for
          determining all threshold arbitrability issues, including issues relating to whether these
          Terms, any provision of these Terms, is unconscionable or illusory and any defense to
          arbitration, including waiver, delay, laches, unconscionability, or estoppel. Nothing in
          this Section shall be deemed as: preventing Company from seeking injunctive or other
          equitable relief from the courts as necessary to prevent the actual or threatened
          infringement, misappropriation, or violation of our data security, Intellectual Property
          Rights or other proprietary rights; or preventing you from asserting claims in small
          claims court, if your claims qualify and so long as the matter remains in such court and
          advances on only an individual (non-class, non-representative) basis. If this Arbitration
          Agreement is found to be void, unenforceable, or unlawful, in whole or in part, the void,
          unenforceable, or unlawful provision, in whole or in part, shall be severed. Severance of
          the void, unenforceable, or unlawful provision, in whole or in part, shall have no impact
          on the remaining provisions of the Arbitration Agreement, which shall remain in force, or
          the parties’ ability to compel arbitration of any remaining claims on an individual basis
          pursuant to the Arbitration Agreement. Notwithstanding the foregoing, if the Class
          Action/Jury Trial Waiver is found to be void, unenforceable, or unlawful, in whole or in
          part, because it would prevent you from seeking public injunctive relief, then any dispute
          regarding the entitlement to such relief (and only that relief) must be severed from
          arbitration and may be litigated in a civil court of competent jurisdiction. All other
          claims for relief subject to arbitration under this Arbitration Agreement shall be
          arbitrated under its terms, and the parties agree that litigation of any dispute regarding
          the entitlement to public injunctive relief shall be stayed pending the outcome of any
          individual claims in arbitration.
          <br />
          14.3 &nbsp;&nbsp;&nbsp; Class Action/Jury Trial Waiver. WITH RESPECT TO ALL PERSONS AND
          ENTITIES, REGARDLESS OF WHETHER THEY HAVE OBTAINED OR USED THE SERVICE FOR PERSONAL,
          COMMERCIAL OR OTHER PURPOSES, ALL CLAIMS MUST BE BROUGHT IN THE PARTIES’ INDIVIDUAL
          CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS ACTION, COLLECTIVE
          ACTION, PRIVATE ATTORNEY GENERAL ACTION OR OTHER REPRESENTATIVE PROCEEDING. THIS WAIVER
          APPLIES TO CLASS ARBITRATION, AND, UNLESS WE AGREE OTHERWISE, THE ARBITRATOR MAY NOT
          CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS. YOU AND COMPANY AGREE THAT THE ARBITRATOR MAY
          AWARD RELIEF ONLY TO AN INDIVIDUAL CLAIMANT AND ONLY TO THE EXTENT NECESSARY TO PROVIDE
          RELIEF ON YOUR INDIVIDUAL CLAIM(S). ANY RELIEF AWARDED MAY NOT AFFECT OTHER COMPANY USERS.
          YOU AND COMPANY FURTHER AGREE THAT, BY ENTERING INTO THESE TERMS, YOU AND COMPANY ARE EACH
          WAIVING THE RIGHT TO A TRIAL BY JURY OR TO BRING, JOIN, OR PARTICIPATE IN A CLASS ACTION,
          COLLECTIVE ACTION, PRIVATE ATTORNEY GENERAL ACTION, OR OTHER REPRESENTATIVE PROCEEDING OF
          ANY KIND AS A PLAINTIFF OR CLASS MEMBER.
          <br />
          15. &nbsp;&nbsp;&nbsp; General
          <br />
          15.1 &nbsp;&nbsp;&nbsp; Assignment. These Terms, and any rights and licenses granted
          hereunder, may not be transferred or assigned by you, but may be assigned by Company
          without restriction, including to a corporate affiliate or in connection with a merger,
          acquisition, corporate reorganization, or sale of all or substantially all of its assets.
          Any attempted transfer or assignment in violation hereof shall be null and void.
          <br />
          15.2 &nbsp;&nbsp;&nbsp; Notification Procedures and Changes to these Terms. Company may
          provide notifications, whether such notifications are required by law or are for marketing
          or other business related purposes, to you via email notice, written or hard copy notice,
          or through posting of such notice on our website, as determined by Company in our sole
          discretion. Company reserves the right to determine the form and means of providing
          notifications to our Users, provided that you may opt out of certain means of notification
          as described in these Terms. Company is not responsible for any automatic filtering you or
          your network provider may apply to email notifications we send to the email address you
          provide us. Company may, in its sole discretion, modify or update these Terms from time to
          time, and so you should review this page periodically. When we change these Terms in a
          material manner, we will update the ‘last modified’ date at the top of this page and
          notify you that material changes have been made to these Terms. Your continued use of the
          Service after any such change constitutes your acceptance of the new Terms of Service. If
          you do not agree to any of the terms of these Terms or any future Terms of Service, do not
          use or access (or continue to access) the Service.
          <br />
          15.3 &nbsp;&nbsp;&nbsp; Entire Agreement/Severability. These Terms, together with any
          amendments and any additional agreements you may enter into with Company in connection
          with the Service, shall constitute the entire agreement between you and Company concerning
          the Service. Except as otherwise stated in Section 15.2, if any provision of these Terms
          is deemed invalid by a court of competent jurisdiction, the invalidity of such provision
          shall not affect the validity of the remaining provisions of these Terms, which shall
          remain in full force and effect.
          <br />
          15.4 &nbsp;&nbsp;&nbsp; No Waiver. No waiver of any term of these Terms shall be deemed a
          further or continuing waiver of such term or any other term, and Company’s failure to
          assert any right or provision under these Terms shall not constitute a waiver of such
          right or provision.
          <br />
          15.5 &nbsp;&nbsp;&nbsp; Contact. Please contact us at [legal@company.com] with any
          questions regarding these Terms.
        </div>
      </CModalBody>
      <CModalFooter className="modal-footer-border-width d-flex justify-content-center">
        <CButton className="btn-pill btn-cancel" onClick={onClose}>
          Close
        </CButton>
        {showAgreeButton ? (
          <CButton className="btn-pill btn-use" onClick={onAgree} disabled={!scrolledToBottom}>
            Agree
          </CButton>
        ) : (
          ""
        )}
      </CModalFooter>
    </CModal>
  )
}

export default TermsOfServiceModal
