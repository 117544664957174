import React from "react"
import numberFormat from "src/helpers/NumberFormat"
import ProcessChart from "../../reusable/ProcessChart"
import stringLengthHelper from "../../helpers/StringLengthHelper"
import { CTooltip } from "@coreui/react"

const ReportProcess = ({ process, co2, co2Sinks, id, innerRef }) => {
  return (
    <div ref={innerRef} key={"process-" + id} id={id}>
      <div className="display-row process-head-text-container">
        <div className="process-head-text">
          <CTooltip content={process.title} placement={"top"}>
            <span>{stringLengthHelper(process.title, 65)}:&nbsp;</span>
          </CTooltip>
        </div>
        <div className="process-head-text">
          <span style={{ marginLeft: "auto", marginRight: "0px" }}>
            {numberFormat.localeString(process.co2 - process.co2Sinks)} kg
          </span>
        </div>
      </div>
      <div className="display-row">
        <div className="display-column">
          <div className="display-row process-data-container">
            <div className="process-title display-column">
              <span>
                C0<span className="process-emissions">2</span>e emissions, process:
              </span>
              <span>
                C0<span className="process-emissions">2</span>e emissions, value chain:
              </span>
            </div>
            <div className="process-data-wrapper display-column">
              <span className="process-data">
                {numberFormat.localeString(process.co2Process)} kg
              </span>
              <span className="process-data">
                {numberFormat.localeString(process.co2ValueChain)} kg
              </span>
            </div>
            <ProcessChart
              emissionTotal={co2}
              emissionProcess={process.co2Process}
              emissionValue={process.co2ValueChain}
              colorProcess={"#00A2E6"}
              colorValue={"#00A2E6"}
            />
          </div>
        </div>
        <div className="display-column">
          {process.co2SinksProcess || process.co2SinksValueChain ? (
            <div className="display-row process-data-container">
              <div className="process-data-wrapper display-column">
                <span className="process-data">
                  {numberFormat.localeString(process.co2SinksProcess)} kg
                </span>
                <span className="process-data">
                  {numberFormat.localeString(process.co2SinksValueChain)} kg
                </span>
              </div>
              <ProcessChart
                emissionTotal={co2Sinks}
                emissionProcess={process.co2SinksProcess}
                emissionValue={process.co2SinksValueChain}
                colorProcess={"#00B995"}
                colorValue={"#00B995"}
                rotate={true}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  )
}

export default ReportProcess
