import React from "react"
import Inventory from "src/assets/icons/report-inventory.svg"
import numberFormat from "src/helpers/NumberFormat"
import HeadSectionTitle from "./HeadSectionTitle"
import stringLengthHelper from "../../helpers/StringLengthHelper"
import { CTooltip } from "@coreui/react"

const ReportInventory = ({
  assignedTo,
  owner,
  title,
  quantity,
  quantityUnit,
  innerRef,
  companyLogo,
}) => {
  return (
    <div ref={innerRef} className="display-row">
      <div style={{ width: "472px" }} className="display-column">
        <HeadSectionTitle
          title={"Attestation of"}
          icon={Inventory}
          imgPaddingTopBottom={"0"}
          imgPaddingLeftRight={"4"}
        />
        <div className="inventory-data-wrapper">
          <div className="inventory-title display-column">
            <span>Product:</span>
            <span>Quantity:</span>
            <span>Assigned to:</span>
            <span>Attested by:</span>
          </div>
          <div className="inventory-content display-column">
            <CTooltip content={title} placement="top">
              <span
                style={{
                  color: "#0188CE",
                }}
              >
                {stringLengthHelper(title, 40)}
              </span>
            </CTooltip>
            <span>{numberFormat.localeString(quantity) + " " + quantityUnit}</span>
            <span>{assignedTo || <>&nbsp;</>}</span>
            <span>{owner}</span>
          </div>
        </div>
      </div>
      <div className="inventory-logo-container">
        <div className="inventory-logo-wrapper">
          <img src={companyLogo} alt={`${owner} company logo`} id="company-logo" />
        </div>
      </div>
    </div>
  )
}

export default ReportInventory
