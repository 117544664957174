import { useDispatch, useSelector } from "react-redux"
import CarbonSigLogo from "src/assets/icons/carbon-sig-logo.svg"
import HeadingCarbonLogo from "src/assets/icons/heading-carbon-sig-logo.svg"
import { setSuccessMessage } from "src/redux/slices/uiSlice"
import TheModal from "./TheModal"

const TheSuccessModal = () => {
  const dispatch = useDispatch()
  const successMessage = useSelector((state) => state.ui.successMessage)

  const handleClose = () => dispatch(setSuccessMessage(""))

  return (
    <div style={{ zIndex: 9999 }}>
      <TheModal
        show={successMessage !== ""}
        onClose={handleClose}
        title="Success!"
        message={successMessage}
        headerIcon={HeadingCarbonLogo}
        icon={CarbonSigLogo}
        btnTitle="Continue"
        btnClasses="btn-pill btn-filter btn-success"
      />
    </div>
  )
}

export default TheSuccessModal
