import { CSelect } from "@coreui/react"
import { useEffect, useState } from "react"
import HeadingList from "src/assets/icons/heading-list.svg"
import objectHelper from "src/helpers/ObjectHelper"
import D3SankeyChart from "src/reusable/D3SankeyChart"

const ProcessSystemRunPreviewProcessFlow = ({ data, title, width, height }) => {
  const [selectedSankeyType, setSelectedSankeyType] = useState("source")
  const [sankeySourceData, setSankeySourceData] = useState({})
  const [sankeySinkData, setSankeySinkData] = useState({})

  const prepareSankeyData = (sankeyData, type) => {
    let result = {}
    if (sankeyData) {
      const sankeyDataCopy = objectHelper.deepCopy(sankeyData)
      sankeyDataCopy.links = sankeyDataCopy.links.filter((x) => x.type === type)
      sankeyDataCopy.nodes = sankeyDataCopy.nodes.filter(
        (x) =>
          sankeyDataCopy.links.some((y) => y.source === x.id) ||
          sankeyDataCopy.links.some((y) => y.target === x.id)
      )
      result = sankeyDataCopy
    }
    return result
  }

  const isSankeyDataEmpty = (data) => {
    return !data?.nodes?.length
  }

  const getSankeyChart = (selectedSankeyType, sankeySourceData, sankeySinkData) => {
    const currentData = selectedSankeyType === "source" ? sankeySourceData : sankeySinkData
    if (!isSankeyDataEmpty(currentData)) {
      return <D3SankeyChart width={width} height={height} data={currentData} />
    } else {
      return <></>
    }
  }

  useEffect(() => {
    setSankeySourceData(prepareSankeyData(data, "source"))
    setSankeySinkData(prepareSankeyData(data, "sink"))
  }, [])

  return (
    <>
      <div className="heading mb-4 d-flex justify-content-between">
        <div>
          <img src={HeadingList} className="heading-img" alt="icon-list" />
          {title}
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <CSelect
            onChange={(e) => {
              setSelectedSankeyType(e.target.value)
            }}
          >
            <option value={"source"}>Sources</option>
            <option value={"sink"}>Sinks</option>
          </CSelect>
        </div>
      </div>
      <div style={{ width: `${width}px`, margin: "0 auto" }}>
        {getSankeyChart(selectedSankeyType, sankeySourceData, sankeySinkData)}
      </div>
    </>
  )
}

export default ProcessSystemRunPreviewProcessFlow
