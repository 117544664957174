import React from "react"
import numberFormat from "src/helpers/NumberFormat"
import Arrow from "./Arrow"

const EmissionChart = ({
  emissionsProcess,
  emissionsValue,
  sinksProcess,
  sinksValue,
  offsetsOwner,
  offsetsValue,
  config = {},
}) => {
  const defaults = {
    containerWidth: 290,
    containerHeight: 103,
    emissionHeight: 60,
    offsetsHeight: 38,
    lineHeight: 12,
    linePadding: 0,
    minWidth: 10,
    colorEmissionProcess: "#00A2E6",
    colorEmissionValue: "#00A2E6",
    colorSinksProcess: "#00B995",
    colorSinksValue: "#00B995",
    colorOffsetsOwner: "#F5A623",
    colorOffsetsValue: "#F5A623",
  }
  config = { ...defaults, ...config }

  const emissionsTotal = emissionsProcess + emissionsValue
  const sinksTotal = sinksProcess + sinksValue
  const offsetsTotal = offsetsOwner + offsetsValue
  const sinksAndOffsetsTotal = sinksTotal + offsetsTotal
  const emissionsNet = emissionsTotal - sinksAndOffsetsTotal
  const emissionsWithSinksTotal = emissionsTotal - sinksTotal

  const hasEmissionsOrOffsets = () => {
    return emissionsTotal || sinksTotal || offsetsTotal
  }

  const getLargestEmissionValue = (emissionsTotal, sinksAndOffsetsTotal) => {
    return Math.max(emissionsTotal, sinksAndOffsetsTotal)
  }

  const getEmissionsTotalWidth = (emissionsTotal, sinksAndOffsetsTotal, containerWidth) => {
    let width = containerWidth
    const largestEmissionValue = getLargestEmissionValue(emissionsTotal, sinksAndOffsetsTotal)
    if (emissionsTotal < largestEmissionValue) {
      width = (containerWidth * emissionsTotal) / largestEmissionValue
    }
    return width
  }

  const getSinksTotalWidth = (emissionsTotal, sinksAndOffsetsTotal, containerWidth) => {
    let width = containerWidth
    const largestEmissionValue = getLargestEmissionValue(emissionsTotal, sinksAndOffsetsTotal)
    if (sinksTotal < largestEmissionValue) {
      width = (containerWidth * sinksTotal) / largestEmissionValue
    }
    return width
  }

  const getOffsetsTotalWidth = (emissionsTotal, sinksAndOffsetsTotal, containerWidth) => {
    let width = containerWidth
    const largestEmissionValue = getLargestEmissionValue(emissionsTotal, sinksAndOffsetsTotal)
    if (offsetsTotal < largestEmissionValue) {
      width = (containerWidth * offsetsTotal) / largestEmissionValue
    }
    return width
  }

  let emissionsTotalWidth = getEmissionsTotalWidth(
    emissionsTotal,
    sinksAndOffsetsTotal,
    config.containerWidth
  )

  let sinksTotalWidth = getSinksTotalWidth(
    emissionsTotal,
    sinksAndOffsetsTotal,
    config.containerWidth
  )

  let offsetsTotalWidth = getOffsetsTotalWidth(
    emissionsTotal,
    sinksAndOffsetsTotal,
    config.containerWidth
  )

  let sinksAndOffsetsTotalWidth = sinksTotalWidth + offsetsTotalWidth

  const getEmissionsProcessWidth = (emissionsProcess, emissionsTotal, emissionTotalWidth) => {
    let width = 0
    if (emissionsTotal) {
      width = (emissionTotalWidth * emissionsProcess) / emissionsTotal
    }
    return parseInt(Math.ceil(width), 10)
  }

  const getEmissionsValueWidth = (emissionsValue, emissionsTotal, emissionTotalWidth) => {
    let width = 0
    if (emissionsTotal) {
      width = (emissionTotalWidth * emissionsValue) / emissionsTotal
    }
    return parseInt(Math.ceil(width), 10)
  }

  const getSinksProcessWidth = () => {
    let width = 0
    if (sinksTotal) {
      width = (sinksAndOffsetsTotalWidth * sinksProcess) / sinksAndOffsetsTotal
    }
    return parseInt(Math.ceil(width), 10)
  }

  const getSinksValueWidth = () => {
    let width = 0
    if (sinksTotal) {
      width = (sinksAndOffsetsTotalWidth * sinksValue) / sinksAndOffsetsTotal
    }
    return parseInt(Math.ceil(width), 10)
  }

  const getOffsetsOwnerWidth = () => {
    let width = 0
    if (offsetsTotal) {
      width = (sinksAndOffsetsTotalWidth * offsetsOwner) / sinksAndOffsetsTotal
    }
    return parseInt(Math.ceil(width), 10)
  }

  const getOffsetsValueWidth = () => {
    let width = 0
    if (offsetsTotal) {
      width = (sinksAndOffsetsTotalWidth * offsetsValue) / sinksAndOffsetsTotal
    }
    return parseInt(Math.ceil(width), 10)
  }

  let emissionProcessWidth = getEmissionsProcessWidth(
    emissionsProcess,
    emissionsTotal,
    emissionsTotalWidth
  )
  let emissionValueWidth = getEmissionsValueWidth(
    emissionsValue,
    emissionsTotal,
    emissionsTotalWidth
  )
  let sinksProcessWidth = getSinksProcessWidth()
  let sinksValueWidth = getSinksValueWidth()
  let offsetOwnerWidth = getOffsetsOwnerWidth()
  let offsetValueWidth = getOffsetsValueWidth()

  const adjustEmissionWidth = () => {
    if (
      emissionProcessWidth < config.minWidth &&
      emissionProcessWidth > 0 &&
      emissionValueWidth < config.minWidth &&
      emissionValueWidth > 0
    ) {
      emissionProcessWidth = config.minWidth
      emissionValueWidth = config.minWidth
      emissionsTotalWidth = config.minWidth * 2
    } else {
      if (emissionProcessWidth < emissionValueWidth) {
        if (emissionProcessWidth < config.minWidth && emissionProcessWidth > 0) {
          const widthDiffAfterAdjust = config.minWidth - emissionProcessWidth
          emissionProcessWidth = config.minWidth
          emissionValueWidth -= widthDiffAfterAdjust
        }
      } else {
        if (emissionValueWidth < config.minWidth && emissionValueWidth > 0) {
          const widthDiffAfterAdjust = config.minWidth - emissionValueWidth
          emissionValueWidth = config.minWidth
          emissionProcessWidth -= widthDiffAfterAdjust
        }
      }
      if (emissionProcessWidth === 0 && emissionValueWidth < config.minWidth) {
        emissionValueWidth = config.minWidth
        emissionsTotalWidth = config.minWidth
      }
      if (emissionValueWidth === 0 && emissionProcessWidth < config.minWidth) {
        emissionProcessWidth = config.minWidth
        emissionsTotalWidth = config.minWidth
      }
    }
  }

  const adjustOffsetWidth = () => {
    if (
      offsetOwnerWidth < config.minWidth &&
      offsetOwnerWidth > 0 &&
      offsetValueWidth < config.minWidth &&
      offsetValueWidth > 0
    ) {
      offsetOwnerWidth = config.minWidth
      offsetValueWidth = config.minWidth
      offsetsTotalWidth = config.minWidth * 2
    } else {
      if (offsetOwnerWidth < offsetValueWidth) {
        if (offsetOwnerWidth < config.minWidth && offsetOwnerWidth > 0) {
          const widthDiffAfterAdjust = config.minWidth - offsetOwnerWidth
          offsetOwnerWidth = config.minWidth
          offsetValueWidth -= widthDiffAfterAdjust
        }
      } else {
        if (offsetValueWidth < config.minWidth && offsetValueWidth > 0) {
          const widthDiffAfterAdjust = config.minWidth - offsetValueWidth
          offsetValueWidth = config.minWidth
          offsetOwnerWidth -= widthDiffAfterAdjust
        }
      }
      if (offsetOwnerWidth === 0 && offsetValueWidth > 0 && offsetValueWidth < config.minWidth) {
        offsetValueWidth = config.minWidth
        offsetsTotalWidth = config.minWidth
      }
      if (offsetValueWidth === 0 && offsetOwnerWidth > 0 && offsetOwnerWidth < config.minWidth) {
        offsetOwnerWidth = config.minWidth
        offsetsTotalWidth = config.minWidth
      }
    }
  }

  const adjustSinksWidth = () => {
    if (
      sinksProcessWidth < config.minWidth &&
      sinksProcessWidth > 0 &&
      sinksValueWidth < config.minWidth &&
      sinksValueWidth > 0
    ) {
      sinksProcessWidth = config.minWidth
      sinksValueWidth = config.minWidth
      sinksTotalWidth = config.minWidth * 2
    } else {
      if (sinksProcessWidth < sinksValueWidth) {
        if (sinksProcessWidth < config.minWidth && sinksProcessWidth > 0) {
          const widthDiffAfterAdjust = config.minWidth - sinksProcessWidth
          sinksProcessWidth = config.minWidth
          sinksValueWidth -= widthDiffAfterAdjust
        }
      } else {
        if (sinksValueWidth < config.minWidth && sinksValueWidth > 0) {
          const widthDiffAfterAdjust = config.minWidth - sinksValueWidth
          sinksValueWidth = config.minWidth
          sinksProcessWidth -= widthDiffAfterAdjust
        }
      }
      if (sinksProcessWidth === 0 && sinksValueWidth > 0 && sinksValueWidth < config.minWidth) {
        sinksValueWidth = config.minWidth
        sinksTotalWidth = config.minWidth
      }
      if (sinksValueWidth === 0 && sinksProcessWidth > 0 && sinksProcessWidth < config.minWidth) {
        sinksProcessWidth = config.minWidth
        sinksTotalWidth = config.minWidth
      }
    }
  }

  if (emissionsTotal) {
    adjustEmissionWidth()
  }
  if (offsetsTotal) {
    adjustOffsetWidth()
  }
  if (sinksTotal) {
    adjustSinksWidth()
  }

  sinksAndOffsetsTotalWidth = sinksTotalWidth + offsetsTotalWidth

  const getEmissionProcessStart = () => {
    let position = 0
    if (sinksAndOffsetsTotalWidth > emissionsTotalWidth) {
      position = sinksAndOffsetsTotalWidth - emissionsTotalWidth
    }
    return position
  }

  const getEmissionValueStart = () => {
    let position = emissionProcessWidth
    if (sinksAndOffsetsTotalWidth > emissionsTotalWidth) {
      position += sinksAndOffsetsTotalWidth - emissionsTotalWidth
    }
    return position
  }

  const getOffsetOwnerStart = () => {
    let position = 0
    if (emissionsTotalWidth > sinksAndOffsetsTotalWidth) {
      position = emissionsTotalWidth - sinksAndOffsetsTotalWidth
    }
    return position
  }

  const getOffsetValueStart = () => {
    let position = offsetOwnerWidth
    if (emissionsTotalWidth > sinksAndOffsetsTotalWidth) {
      position += emissionsTotalWidth - sinksAndOffsetsTotalWidth
    }
    return position
  }

  const getSinksProcessStart = () => {
    let position = offsetsTotalWidth
    if (emissionsTotalWidth > sinksAndOffsetsTotalWidth) {
      position = emissionsTotalWidth - sinksTotalWidth
    }
    return position
  }

  const getSinksValueStart = () => {
    let position = sinksProcessWidth + offsetsTotalWidth
    if (emissionsTotalWidth > sinksAndOffsetsTotalWidth) {
      position = emissionsTotalWidth - sinksTotalWidth + sinksProcessWidth
    }
    return position
  }

  const emissionProcessStart = getEmissionProcessStart()
  const emissionValueStart = getEmissionValueStart()
  const sinksProcessStart = getSinksProcessStart()
  const sinksValueStart = getSinksValueStart()
  const offsetOwnerStart = getOffsetOwnerStart()
  const offsetValueStart = getOffsetValueStart()

  const getVerticalLineStart = () => {
    let position = 0
    if (hasEmissionsOrOffsets()) {
      position = 0
      if (sinksAndOffsetsTotalWidth > emissionsTotalWidth) {
        position = sinksAndOffsetsTotalWidth - emissionsTotalWidth
      }
    } else {
      position = Math.floor(config.containerWidth / 2)
    }
    return position
  }

  const verticalLineStart = getVerticalLineStart()

  const getTopLineStart = () => {
    let position = 0
    if (sinksAndOffsetsTotalWidth > emissionsTotalWidth) {
      position = sinksAndOffsetsTotalWidth - emissionsTotalWidth
    }
    return position
  }

  const topLineStart = getTopLineStart()

  const getBottomLineStart = () => {
    return 0
  }

  const getBottomLineWidth = () => {
    let position = 0
    if (sinksAndOffsetsTotalWidth > emissionsTotalWidth) {
      position = sinksAndOffsetsTotalWidth - emissionsTotalWidth
    } else {
      position = emissionsTotalWidth - sinksAndOffsetsTotalWidth
    }
    return position
  }

  const bottomLineStart = getBottomLineStart()
  const bottomLineWidth = getBottomLineWidth()

  const getBottomLineClass = () => {
    let cls = "line-with-offsets"
    if (sinksAndOffsetsTotalWidth < emissionsTotalWidth) {
      cls += " line-with-offsets-positive"
    }
    return cls
  }

  const bottomLineClass = getBottomLineClass()

  const getBottomBulletClass = () => {
    let cls = "bullet bullet-bottom"
    if (sinksAndOffsetsTotalWidth < emissionsTotalWidth) {
      cls += " bullet-bottom-positive"
    }
    return cls
  }

  const bottomBulletClass = getBottomBulletClass(emissionsTotalWidth, offsetsTotalWidth)

  const getChartLabelCo2Style = (h) => {
    let style = {}
    if (hasEmissionsOrOffsets()) {
      style = { right: "-2px" }
    } else {
      style = { left: Math.floor(config.containerWidth / 2) - 3 + "px" }
    }
    return style
  }

  const chartLabelCo2Style = getChartLabelCo2Style()

  const getChartLabelCo2NetStyle = () => {
    let style = {}
    if (hasEmissionsOrOffsets()) {
      if (sinksAndOffsetsTotalWidth > emissionsTotalWidth) {
        style = { left: bottomLineStart - 3 + "px", color: "#3EAB38" }
      } else {
        style = { right: sinksAndOffsetsTotalWidth + "px" }
      }
    } else {
      style = {
        left: Math.floor(config.containerWidth / 2) - 3 + "px",
        color: "#3EAB38",
      }
    }
    return style
  }

  const chartLabelCo2NetStyle = getChartLabelCo2NetStyle()

  const bulletTopFirstStyle = () => {
    if (hasEmissionsOrOffsets()) {
      return { left: topLineStart - 3 + "px" }
    } else {
      return { left: Math.floor(config.containerWidth / 2) - 3 + "px" }
    }
  }

  const bulletBottomFirstStyle = () => {
    if (hasEmissionsOrOffsets()) {
      return { left: bottomLineStart - 3 + "px" }
    } else {
      return { left: Math.floor(config.containerWidth / 2) - 3 + "px" }
    }
  }

  return (
    <div
      className="emission-chart"
      style={{
        width: config.containerWidth + "px",
        height: config.containerHeight + "px",
      }}
    >
      <div
        className="vertical-dotted-line"
        style={{
          left: verticalLineStart + "px",
          height: config.containerHeight + "px",
        }}
      ></div>

      <div className="bullet bullet-top" style={bulletTopFirstStyle()}></div>
      <div className={bottomBulletClass} style={bulletBottomFirstStyle()}></div>
      {hasEmissionsOrOffsets() ? (
        <>
          <div
            className="line-without-offsets"
            style={{
              left: topLineStart + "px",
              width: emissionsTotalWidth + "px",
            }}
          ></div>
          <div
            className={bottomLineClass}
            style={{
              left: bottomLineStart + "px",
              width: bottomLineWidth + "px",
            }}
          ></div>
          <div
            className="bullet bullet-top"
            style={{ left: topLineStart + emissionsTotalWidth - 6 + "px" }}
          ></div>
          <div
            className={bottomBulletClass}
            style={{ left: bottomLineStart + bottomLineWidth - 4 + "px" }}
          ></div>
        </>
      ) : (
        ""
      )}

      <div
        className="chart-label chart-label-net"
        style={{
          left: verticalLineStart + "px",
          top: config.emissionHeight + 6 + "px",
        }}
      >
        Net 0
      </div>
      <div className="chart-label chart-label-co2" style={chartLabelCo2Style}>
        {hasEmissionsOrOffsets() ? numberFormat.localeString(emissionsWithSinksTotal) : "--"} kg
      </div>
      <div className="chart-label chart-label-co2-net" style={chartLabelCo2NetStyle}>
        {hasEmissionsOrOffsets() ? numberFormat.localeString(emissionsNet) : "--"} kg
      </div>

      <div
        className="emission-chart-emissions emission-chart-section"
        style={{
          height: config.emissionHeight + "px",
          marginBottom: "24px",
        }}
      >
        <div
          style={{
            marginLeft: emissionProcessStart + "px",
            paddingTop: config.linePadding + "px",
          }}
        >
          <Arrow
            color={config.colorEmissionProcess}
            width={emissionProcessWidth}
            height={config.lineHeight}
          />
        </div>
        <div
          style={{
            marginLeft: emissionValueStart + "px",
            paddingTop: config.linePadding + "px",
          }}
        >
          <Arrow
            color={config.colorEmissionValue}
            width={emissionValueWidth}
            height={config.lineHeight}
          />
        </div>
        <div
          style={{
            marginLeft: sinksProcessStart + "px",
            paddingTop: config.linePadding + "px",
          }}
        >
          <Arrow
            color={config.colorSinksProcess}
            width={sinksProcessWidth}
            height={config.lineHeight}
            rotate={true}
          />
        </div>
        <div
          style={{
            marginLeft: sinksValueStart + "px",
            paddingTop: config.linePadding + "px",
          }}
        >
          <Arrow
            color={config.colorSinksValue}
            width={sinksValueWidth}
            height={config.lineHeight}
            rotate={true}
          />
        </div>
      </div>

      <div
        className="emission-chart-offsets emission-chart-section"
        style={{
          height: config.offsetsHeight + "px",
        }}
      >
        <div
          style={{
            marginLeft: offsetOwnerStart + "px",
            paddingTop: config.linePadding + "px",
          }}
        >
          <div>
            <Arrow
              color={config.colorOffsetsOwner}
              width={offsetOwnerWidth}
              height={config.lineHeight}
              rotate={true}
            />
          </div>
        </div>
        <div
          style={{
            marginLeft: offsetValueStart + "px",
            paddingTop: config.linePadding + "px",
          }}
        >
          <div>
            <Arrow
              color={config.colorOffsetsValue}
              width={offsetValueWidth}
              height={config.lineHeight}
              rotate={true}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmissionChart
