import React from 'react'

const Arrow = ({color, width, height, rotate}) => {
  width = parseInt(Math.ceil(width), 10)
  height = parseInt(Math.ceil(height), 10)
  const tailHead = 8;
  if(width > 0) {
    width = (width <= tailHead) ? 1 : (width - tailHead)
  }
  const totalWidth = width + tailHead

  const tailSvg = <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="4" height={height} viewBox="0 0 9 32"
      preserveAspectRatio="none" style={{fill: color, height: height + 'px'}} className="svg-arrow arrow-tail">
        <path d="M-0.003 1.959l10.314 14.024-10.314 14.059h10.314v-28.083z"></path>
    </svg>

  const bodySvg = <svg version="1.1" xmlns="http://www.w3.org/2000/svg"  height={height} width={width}
      viewBox="0 0 27 32" preserveAspectRatio="none"
      style={{fill: color, height: height + 'px', width: width + 'px'}} className="svg-arrow arrow-body">
        <path d="M0 1.956h28.086v28.086h-28.086v-28.086z"></path>
    </svg>

  const headSvg = <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="4" height={height} viewBox="0 0 24 32"
      preserveAspectRatio="none" style={{fill: color, height: height + 'px'}} className="svg-arrow arrow-head">
      <path d="M-0.018 1.959l0.024 28.083 24.302-14.059z"></path>
    </svg>

  return (
    width > 0 ?
    <div style={{height: height + 'px', lineHeight: height + 'px', width: totalWidth + 'px', transform: rotate ? 'rotateY(180deg)' : 'none', display: 'flex'}}>
      {tailSvg}{bodySvg}{headSvg}
    </div>
    :
    <div style={{height: height + 'px', width: totalWidth + '1px'}}></div>
  )
}

export default Arrow
