import React from "react"

const ProductExplorerSidebarCredit = ({ credits, headTitle }) => {
  return (
    <>
      <div className="sidebar-credit-head-title">{headTitle}</div>
      {credits.map((credit, index) => (
        <div key={"credit-" + index}>
          <div className="sidebar-credit-heading">{credit.title}</div>
          <div>
            <div className="sidebar-credit-row d-flex">
              <div className="sidebar-credit-title">Emissions:</div>
              <div className="sidebar-credit-value">
                {credit.co2Emission} kg CO<sub>2</sub>e
              </div>
            </div>
            <div className="sidebar-credit-row d-flex">
              <div className="sidebar-credit-title">Registry:</div>
              <div className="sidebar-credit-value">{credit.distributor}</div>
            </div>
            <div className="sidebar-credit-row d-flex">
              <div className="sidebar-credit-title">Vintage year:</div>
              <div className="sidebar-credit-value">{credit.vintage}</div>
            </div>
            <div className="sidebar-credit-row d-flex">
              <div className="sidebar-credit-title">ID:</div>
              <div className="sidebar-credit-value">ID#{credit.id}</div>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

export default ProductExplorerSidebarCredit
