import React, { useEffect, useState } from "react"
import numberFormat from "src/helpers/NumberFormat"
import apiCallWrapper from "src/helpers/ApiCallWrapper"
import getEmissions from "src/requests/emission/GetEmissions"
import { useDispatch } from "react-redux"

const ProductExplorerSidebarSiteEmissions = ({ subTree }) => {
  const dispatch = useDispatch()
  const [emissions, setEmissions] = useState([])

  const getEmissionsHandler = () => {
    apiCallWrapper.call(getEmissions, dispatch, {
      successHandler: (jsonData) => {
        setEmissions(jsonData)
      },
    })
  }

  const getEmissionDetails = (id) => {
    return emissions?.find((x) => x.id === id)
  }

  useEffect(() => {
    getEmissionsHandler()
  }, [])

  return (
    <>
      {subTree?.process?.groups.map((group, index) => (
        <>
          <div className="sidebar-credit-heading" key={`site-group-${index}`}>
            {group.title}
          </div>
          {group.emissions.map((emission, index2) => (
            <>
              <div key={`site-emission-${index}-${index2}`}>
                <div>
                  <div className="sidebar-credit-row d-flex">
                    <div className="sidebar-credit-title">Emission:</div>
                    <div className="sidebar-credit-value">
                      {getEmissionDetails(emission.emissionId)?.title}
                    </div>
                  </div>
                  <div className="sidebar-credit-row d-flex">
                    <div className="sidebar-credit-title">Formula:</div>
                    <div className="sidebar-credit-value">
                      {getEmissionDetails(emission.emissionId)?.formula}
                    </div>
                  </div>
                  <div className="sidebar-credit-row d-flex">
                    <div className="sidebar-credit-title">
                      CO<sub>2</sub>e:
                    </div>
                    <div className="sidebar-credit-value">
                      {numberFormat.localeString(emission.emissionValue)}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}
        </>
      ))}
    </>
  )
}

export default ProductExplorerSidebarSiteEmissions
