import request from "../Request"

const deleteProcessesSystems = (params, completedCallback, errorCallback) => {
  request.call({
    path: "/inventory/api/system-process-template/delete-all",
    method: "delete",
    completedCallback: completedCallback,
    errorCallback: errorCallback,
    responseType: "text",
    params: params,
    basePath: process.env.REACT_APP_GATEWAY_URL,
  })
}

export default deleteProcessesSystems
