import React  from 'react'

const Attribute = ({value, type}) => {
  if(!type) {
    type = 'text'
  }
  const renderInput = () => {
    switch(type) {
      case 'boolean':
        return value ? 'Yes' : 'No'
      case 'file':
        return <a href={value}>Link</a>
      case 'datetime':
      case 'number':
      case 'text':
      default:
        return value
    }
  }

  return (
    renderInput()
  )
}

export default Attribute
