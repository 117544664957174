import React from "react"
const IOLabel = ({ icon, title, innerRef }) => {
  return (
    <div ref={innerRef} className="display-row input-output-wrapper">
      <div className="input-output-img-wrapper">
        <img src={icon} />
      </div>
      <span className="input-output-label">{title}:</span>
    </div>
  )
}
export default IOLabel
