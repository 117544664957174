import React, { useEffect, useState } from "react"
import User from "src/assets/icons/report-user.svg"
import Signature from "src/assets/icons/report-logo-icon.svg"
import Location from "src/assets/icons/report-location.svg"
import { QRCodeSVG } from "qrcode.react"
import stringLengthHelper from "../../helpers/StringLengthHelper"
import { CTooltip } from "@coreui/react"
import HeadingCarbonLogo from "src/assets/icons/heading-carbon-sig-logo.svg"
import envHelper from "src/helpers/EnvHelper"
import moment from "moment"

const ReportHeader = ({
  id,
  reportId,
  generatedOn,
  confirmedOn,
  tenantTitle,
  tenantId,
  tenantPhone,
  tenantAddress,
  backgroundColor,
  color,
  shareType,
}) => {
  let certsUrl
  if (shareType === 1 || shareType === 2) {
    certsUrl =
      window.location.protocol + "//" + window.location.host + "/#/qr-code-scan/" + reportId
  } else if (shareType === 0 || shareType === 3) {
    certsUrl = window.location.protocol + "//" + window.location.host + "/#/restricted-access"
  }
  const getTimezone = () => {
    const d = new Date()
    const n = d.getTimezoneOffset()
    const timezone = n / -60
    return "GMT" + (timezone > 0 ? "+" : "") + timezone
  }

  const dateFormat = (date) => {
    if (date) {
      const d = new Date(date)
      return moment(d).format("M/D/YY HH:mm UTC")
    } else {
      return ""
    }
  }

  return (
    <div key={"header-" + id} className="report-heading">
      <div className="heading-content display-row">
        <div className="display-column heading-wrapper">
          <div className="d-flex">
            <div className="heading-content-logo">
              <img src={User} />
            </div>
            <div className="heading-content-text-second">
              <CTooltip content={tenantTitle} placement={"top"}>
                <span className="d-flex">{stringLengthHelper(tenantTitle, 40)}</span>
              </CTooltip>
              {/* <span className="d-flex">ID# {tenantId}</span> */}
            </div>
          </div>
          <div className="d-flex">
            <div className="heading-content-logo">
              <img src={Signature} />
            </div>
            <div className="heading-content-text-first display-column">
              <span>
                Carbon attested product generated{" "}
                <span style={{ color: "#444443" }}>at {dateFormat(generatedOn)}</span>
              </span>
              <span>
                Recipient confirmed{" "}
                <span style={{ color: "#444443" }}>{dateFormat(new Date().toLocaleString())}</span>
              </span>
            </div>
          </div>
        </div>
        <div className="d-flex ml-4">
          <div className="heading-content-logo">
            <img src={Location} />
          </div>
          <div className="heading-content-text-second">
            <CTooltip content={tenantAddress} placement={"top"}>
              <span className="d-flex">{stringLengthHelper(tenantAddress, 150)}</span>
            </CTooltip>
            <CTooltip content={tenantPhone} placement={"top"}>
              <span className="d-flex">{stringLengthHelper(tenantPhone, 38)}</span>
            </CTooltip>
          </div>
        </div>
      </div>
      <div className="heading-logo-div" style={{ backgroundColor }}>
        <div className="img-logo-wrapper">
          <QRCodeSVG
            value={certsUrl}
            level={"H"}
            imageSettings={{
              src: { HeadingCarbonLogo },
              x: null,
              y: null,
              height: "55",
              width: "55",
            }}
          />
        </div>
        <div style={{ marginTop: "8px" }} className="heading-logo-text-wrapper">
          {envHelper.isSandbox() ? (
            <span className="heading-logo-div-text" style={{ color }}>
              ID# XXXXX
            </span>
          ) : (
            <span className="heading-logo-div-text" style={{ color }}>
              ID# {reportId}
            </span>
          )}
          <span className="heading-logo-div-text" style={{ color }}>
            {envHelper.isOnPrem() ? "certs.oxy.carbonsig.app" : " certs.carbonsig.app"}
          </span>
        </div>
      </div>
    </div>
  )
}

export default ReportHeader
