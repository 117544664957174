import { CChartPie } from "@coreui/react-chartjs"
import React from "react"

const ProductExplorerSidebarSUVItem = ({
  co2Emission,
  co2EmissionSite,
  co2EmissionProcess,
  co2EmissionValueChain,
  transferred,
  designation,
  leaf,
}) => {
  let siteEmissions = 0
  let utilityEmissions = 0
  let valueChainEmissions = 0
  let totalEmissions = co2Emission
  if (!transferred && !leaf) {
    siteEmissions = co2EmissionSite
    utilityEmissions = co2EmissionProcess - co2EmissionSite
    valueChainEmissions = co2EmissionValueChain
  } else {
    if (designation === "Value Chain") {
      valueChainEmissions = totalEmissions
    } else {
      utilityEmissions = totalEmissions
    }
  }
  let siteEmissionsPercentage = 0
  let utilityEmissionsPercentage = 0
  let valueChainEmissionsPercentage = 0
  
  if (totalEmissions) {
    let array = []
    siteEmissionsPercentage = ((siteEmissions / totalEmissions) * 100)
    array.push(Number(siteEmissionsPercentage))
    utilityEmissionsPercentage = ((utilityEmissions / totalEmissions) * 100)
    array.push(Number(utilityEmissionsPercentage))
    valueChainEmissionsPercentage = ((valueChainEmissions / totalEmissions) * 100)
    array.push(Number(valueChainEmissionsPercentage))

    let total = Number(siteEmissionsPercentage.toFixed(2)) + Number(utilityEmissionsPercentage.toFixed(2)) + Number(valueChainEmissionsPercentage.toFixed(2))
    let max = 0;
    let diff = total - 100
    let index = 0

    array.forEach((el, index) => {
      if(el > max){
        max = el
        index = index
      }
    })
    
    if(index == 0){
      siteEmissionsPercentage = siteEmissionsPercentage - diff
    } else if(index == 1){
      utilityEmissionsPercentage = utilityEmissionsPercentage - diff
    } else if(index == 2){
      valueChainEmissionsPercentage = valueChainEmissionsPercentage - diff
    }

  }


  return (
    <>
      <div className="sidebar-suv-chart">
        <CChartPie
          datasets={[
            {
              backgroundColor: ["#00a8ff", "#0188ce", "#FFFFFF"],
              data: [
                siteEmissionsPercentage,
                utilityEmissionsPercentage,
                valueChainEmissionsPercentage,
              ],
            },
          ]}
          labels={["Scope 1", "Scope 2", "Scope 3, Upstream"]}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
              enabled: true,
              callbacks: {
                label: function (tooltipItem, data) {
                  return (
                    data.labels[tooltipItem.index] +
                    ": " +
                    data.datasets[0].data[tooltipItem.index] +
                    " %"
                  )
                },
              },
            },
            legend: false,
          }}
        />
      </div>
      <div className="sidebar-summary-row d-flex">
        <div className="sidebar-summary-title">Scope 1</div>
        <div className="sidebar-summary-value">
          <div className="sidebar-suv-legend sidebar-suv-legend-scope-1"></div>
          <div className="sidebar-suv-value">{siteEmissionsPercentage.toFixed(2)}%</div>
        </div>
      </div>
      <div className="sidebar-summary-row d-flex">
        <div className="sidebar-summary-title">Scope 2</div>
        <div className="sidebar-summary-value">
          <div className="sidebar-suv-legend sidebar-suv-legend-scope-2"></div>
          <div className="sidebar-suv-value">{utilityEmissionsPercentage.toFixed(2)}%</div>
        </div>
      </div>
      <div className="sidebar-summary-row d-flex">
        <div className="sidebar-summary-title">Scope 3, Upstream</div>
        <div className="sidebar-summary-value">
          <div className="sidebar-suv-legend sidebar-suv-legend-scope-3"></div>
          <div className="sidebar-suv-value">{valueChainEmissionsPercentage.toFixed(2)}%</div>
        </div>
      </div>
    </>
  )
}

export default ProductExplorerSidebarSUVItem
