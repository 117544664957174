import React from "react"
import { CTooltip } from "@coreui/react"

const ReportCertifications = ({ certifications, innerRef }) => {
  const handleClick = (event, filePath) => {
    if (filePath === "") {
      event.preventDefault()
    }
  }
  return (
    <div ref={innerRef}>
      <div className="section-title">
        <span className="titles">Certifications:</span>
      </div>
      <div className="d-flex flex-row">
        {certifications.map((item, index) => {
          const url =
            process.env.REACT_APP_GATEWAY_URL + `/files/api/image-path?imagePath=${item.imagePath}`
          const lastItem = index === 5
          return (
            <CTooltip content={item.title}>
              <div
                className="d-flex align-items-center justify-content-center"
                style={{
                  height: "56px",
                  width: "112px",
                  marginRight: lastItem ? "" : "10px",
                }}
              >
                <a
                  href={item.filePath}
                  target={item.filePath ? "_blank" : ""}
                  onClick={(e) => handleClick(e, item.filePath)}
                >
                  <img src={url} width="56px" height="56px" />
                </a>
              </div>
            </CTooltip>
          )
        })}
      </div>
    </div>
  )
}

export default ReportCertifications
