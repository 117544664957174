import React from "react"
import ProductExplorerSidebarSUVItem from "./ProductExplorerSidebarSUVItem"

const ProductExplorerSidebarSUV = ({ genealogyItem }) => {
  return (
    <>
      <div className="sidebar-credit-head-title">Sources</div>
      <ProductExplorerSidebarSUVItem
        co2Emission={genealogyItem.co2Emission}
        co2EmissionProcess={genealogyItem.co2EmissionProcess}
        co2EmissionValueChain={genealogyItem.co2EmissionValueChain}
        co2EmissionSite={genealogyItem.co2EmissionSite}
        transferred={genealogyItem.transferred}
        leaf={genealogyItem.leaf}
        designation={genealogyItem.designation}
      />
      <div className="sidebar-credit-head-title">Sinks</div>
      <ProductExplorerSidebarSUVItem
        co2Emission={genealogyItem.co2SinksEmission}
        co2EmissionProcess={genealogyItem.co2SinksProcess}
        co2EmissionValueChain={genealogyItem.co2SinksValueChain}
        co2EmissionSite={genealogyItem.co2SinksSite}
        transferred={genealogyItem.transferred}
        leaf={genealogyItem.leaf}
        designation={genealogyItem.designation}
      />
    </>
  )
}

export default ProductExplorerSidebarSUV
