import request from "./Request"

const getSitesForCurrentTenant = (params, completedCallback, errorCallback) => {
  const path = "/auth/api/site/tenant-sites"
  request.call({
    path: path,
    method: "get",
    completedCallback: completedCallback,
    errorCallback: errorCallback,
    params: {},
  })
}

export default getSitesForCurrentTenant
