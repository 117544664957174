import React from "react"
import numberFormat from "src/helpers/NumberFormat"
import stringHelper from "src/helpers/StringHelper"
import SectionSplitLine from "./SectionSplitLine"
import stringLengthHelper from "../../helpers/StringLengthHelper"
import { CTooltip } from "@coreui/react"

const ReportInput = ({ input, index, innerRef, lastInputItem }) => {
  const displayIntensity = (input) => {
    const co2 = input?.co2
    const unit = input?.unit
    const externalLci = input?.externalLci
    if (externalLci) {
      return <span className="data-label">{stringHelper.ucfirst(externalLci)} licensed data</span>
    }
    if (input?.co2 === null) {
      return <span className="data-label">Redacted data</span>
    }
    return (
      <span className="data-label">
        {numberFormat.localeString(co2)} kg CO
        <span style={{ position: "relative", top: "4px", fontSize: "6px" }}>2</span>
        e/{unit}
      </span>
    )
  }

  return (
    <div key={"input-" + index}>
      <div ref={innerRef}>
        <div className="object-label-wrapper">
          <CTooltip content={input.title} placement={"top"}>
            <span className="labels">{stringLengthHelper(input.title, 40)}</span>
          </CTooltip>
        </div>
        <div style={{ height: "14px" }} className="display-row">
          <div className="object-data-wrapper">{displayIntensity(input)}</div>
          {/*<div style={{ backgroundColor: "red" }} className="object-id-wrapper">
            <span className="data-label">&nbsp;</span>
          </div>*/}
        </div>
      </div>
      {lastInputItem === index ? "" : <SectionSplitLine />}
    </div>
  )
}

export default ReportInput
