import React, { useEffect, useRef, useState } from "react"
import ReportHeader from "./ReportHeader"
import getReport from "src/requests/GetReport"
import getReportShare from "src/requests/GetReportShare"
import getReportShareExternal from "src/requests/GetReportShareExternal"
import getReportPreview from "../../requests/GetReportPreview"
import apiCallWrapper from "../../helpers/ApiCallWrapper"
import { useDispatch } from "react-redux"
import { CButton } from "@coreui/react"
import ReportContentBox from "./ReportContentBox"
import ReportFooter from "./ReportFooter"
import ReportInventory from "./ReportInventory"
import ReportDeclaredEmissions from "./ReportDeclaredEmissions"
import ReportProductSpecifications from "./ReportProductSpecifications"
import ReportInputsOutputs from "./ReportInputsOutputs"
import { useHistory, useParams } from "react-router-dom"
import ArrowRight from "../../assets/icons/report-arrow-right.svg"
import ArrowLeft from "../../assets/icons/report-arrow-left.svg"
import ReportClose from "../../assets/icons/report-close.svg"
import SaveToPdf from "../../assets/icons/button-save-to-pdf.svg"
import PreviewBackground from "../../assets/icons/preview-background.svg"
import PreviewBackgroundSandbox from "../../assets/icons/preview-background-sandbox.svg"
import Inputs from "src/assets/icons/report-input.svg"
import ReportAttestationSummary from "./ReportAttestationSummary"
import IOProcessTitle from "./IOProcessTitle"
import ReportSplitLine from "./ReportSplitLine"
import ReportProcess from "./ReportProcess"
import ProcessesIcon from "../../assets/icons/report-processes.svg"
import CarbonCreditIcon from "src/assets/icons/report-carbon-credit.svg"
import ProcessFlowsIcon from "src/assets/icons/report-process-flows.svg"
import ProcessesSplitLine from "./ProcessesSplitLine"
import HeadSectionTitle from "./HeadSectionTitle"
import AttestationSplitLine from "./AttestationSplitLine"
import SectionTitle from "./SectionTitle"
import SectionSplitLine from "./SectionSplitLine"
import CarbonCredit from "./CarbonCredit"
import initialReportData from "./initialReportData"
import ReportInput from "./ReportInput"
import IOSplitLine from "./IOSplitLine"
import IOLabel from "./IOLabel"
import requestHelper from "src/helpers/RequestHelper"
import ReportCertifications from "./ReportCertifications"
import html2canvas from "html2canvas"
import jsPDF from "jspdf"
import { setLoading } from "src/redux/slices/uiSlice"
import carbonSigLoader from "src/assets/carbon-sig-loader.gif"
import envHelper from "src/helpers/EnvHelper"
import objectHelper from "src/helpers/ObjectHelper"

const Report = ({
  showPreview = false,
  previewData = {},
  systemOutputPreview = {},
  isUrlShare = false,
  isExternalShare = false,
  onClose = () => {},
  currentPage,
  onTotalPageChange,
  generate,
  onFinishGenerate,
  watermarkShare,
}) => {
  const { uid, id, shareId } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const [dataReport, setDataReport] = useState({
    credits: [],
    declaredEmissions: {},
    header: {},
    id: "",
    inheritedCredits: [],
    inventory: {},
    processes: [],
    reportCertifications: [],
    reportDocuments: [],
    specifications: [],
    summary: {},
  })

  const [showPage, setShowPage] = useState(currentPage || 0)
  const [totalPages, setTotalPages] = useState(1)
  const printPageRef = useRef(null)
  const isFirefox = typeof InstallTrigger !== "undefined"
  const contentRef = useRef(null)
  const inventoryRef = useRef(null)
  const emissionRef = useRef(null)
  const splitLineRef = useRef(null)
  const processRef = useRef(null)
  const processesSplitLineRef = useRef(null)
  const sectionSplitLineRef = useRef(null)
  const ioSplitLineRef = useRef(null)
  const headTitleRef = useRef(null)
  const summaryFirstRowRef = useRef(null)
  const summarySecondRowRef = useRef(null)
  const attestationSplitLineRef = useRef(null)
  const specificationRef = useRef(null)
  const sectionTitleRef = useRef(null)
  const creditRef = useRef(null)
  const inputOutputItemRef = useRef(null)
  const ioProcessTitleRef = useRef(null)
  const ioLabelRef = useRef(null)
  const certificationsRef = useRef(null)
  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false)
  const isAdminOrPublisher = requestHelper.hasRole("Admin") || requestHelper.hasRole("Publisher")
  const [shareType, setShareType] = useState()

  useEffect(() => {
    if (currentPage === undefined) return
    setShowPage(currentPage)
  }, [currentPage])

  // const handleWindowSizeChange = () => {
  //   setMobile(window.innerWidth <= 768)
  // }

  // useEffect(() => {
  //   handleWindowSizeChange()
  // }, [])

  const style = (
    <style type="text/css" media="print">
      {`
        @media print {
          html body {
            min-width: 612px;
            width: 100%;
            height: 100%;
            print-color-adjust: exact;
            color-adjust: exact;
          }
          
          .print-source {
            overflow: visible;
          }

          .page:last-child .footer-container {
            page-break-after: avoid;
          }

          .report-publish-wrapper {
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
           }

          .page {
            margin: 0px;
            padding: 0px;
            width: 560px;
            transform-origin: top left;
            transform: scale(2);
            padding-top: 65px;
            padding-left: 65px;
          }

          .report-page-background .page,
          .report-page-background-print .page {
            padding-top: 50px;
            padding-left: 50px;
          }
        }
      `}
    </style>
  )

  const setReportData = (jsonData) => {
    setDataReport(jsonData)
    setShareType(jsonData.inventory.shareType)
  }
  const getReportHandler = () => {
    const request = isUrlShare ? getReportShare : getReport
    apiCallWrapper.call(request, dispatch, {
      successHandler: (jsonData) => {
        setReportData(jsonData)
      },
      params: {
        id: uid || id || shareId,
      },
    })
  }

  const getReportShareExternalHandler = (param) => {
    apiCallWrapper.call(getReportShareExternal, dispatch, {
      successHandler: (jsonData) => {
        setReportData(jsonData)
      },
      params: {
        shareId: param,
        password: getPassword(param),
      },
      showErrorMessage: false,
    })
  }

  // DUPLICATED CODE
  const getStorageKey = (shareId) => {
    return `attestation-unit-external-share-${shareId}`
  }
  const getPassword = (shareId) => {
    const storageKey = getStorageKey(shareId)
    return localStorage.getItem(storageKey)
  }
  // ----

  const downloadCertificate = async (report) => {
    const url = window.location.origin + "/#/generate-report"
    const token = localStorage.getItem("jwt-token")

    // const endpointUrl = "http://localhost:8080/generate-pdf"
    const endpointUrl = "https://dev.carbonmetrix.co/report/generate-pdf"

    const response = await fetch(endpointUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ url, token, report }),
    })

    console.log("response", response)

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`)
    }

    const blob = await response.blob()
    const fileUrl = window.URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.href = fileUrl

    const date = new Date()
    const month = date.toLocaleString("default", { month: "long" })
    const year = date.getFullYear()

    const fileName = `Carbon_Footprint_Report_${month}_${year}`

    a.download = `${fileName}.pdf`
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(fileUrl)
    dispatch(setLoading(false))
    setIsGeneratingPdf(false)
    if (typeof onFinishGenerate === "function") {
      onFinishGenerate(true)
    }
  }

  const generatePDF = () => {
    dispatch(setLoading(true))
    setIsGeneratingPdf(true)

    const fullReport = document.querySelector(".full-hidden-report")
    const reportPreview = document.querySelector(".print-source.report-publish-wrapper")

    if (reportPreview) {
      downloadCertificate(reportPreview.innerHTML)
    } else {
      downloadCertificate(fullReport.innerHTML)
    }
  }

  const generatePDFOld = () => {
    const parent = printPageRef.current

    const watermarkImage = new Image()
    const isSandboxEnv = envHelper.isSandbox()
    isSandboxEnv
      ? (watermarkImage.src = PreviewBackgroundSandbox)
      : (watermarkImage.src = PreviewBackground)

    watermarkImage.onload = () => {
      const pages = parent.querySelectorAll(".page")
      const pdf = new jsPDF({
        orientation: "portrait",
        format: "letter",
      })

      const generatePDFPage = async (element, pageNumber) => {
        const canvas = await html2canvas(element, {
          useCORS: true,
          allowTaint: true,
        })

        const watermarkWidth = 770
        const watermarkHeight = 770
        const watermarkCanvas = document.createElement("canvas")
        watermarkCanvas.width = watermarkWidth
        watermarkCanvas.height = watermarkHeight

        const watermarkCtx = watermarkCanvas.getContext("2d")
        if (isSandboxEnv || showPreview) {
          watermarkCtx.drawImage(watermarkImage, 0, 0, watermarkWidth, watermarkHeight)
        }

        const mergedCanvas = document.createElement("canvas")
        mergedCanvas.width = canvas.width
        mergedCanvas.height = canvas.height

        const mergedCtx = mergedCanvas.getContext("2d")
        mergedCtx.drawImage(canvas, 0, 0)
        mergedCtx.drawImage(watermarkCanvas, 24, 174)

        const imageData = mergedCanvas.toDataURL("image/jpeg", 1.0)
        return { canvas: mergedCanvas, imageData: imageData, pageNumber: pageNumber }
      }

      const promiseArray = []
      for (let i = 0; i < pages.length; i++) {
        promiseArray.push(generatePDFPage(pages[i], i))
      }

      Promise.all(promiseArray).then((values) => {
        const pdfPageWidth = 215.9
        const pdfPageHeight = 279.4

        values.forEach((data, pageNumber) => {
          if (pageNumber > 0) {
            pdf.addPage()
          }

          pdf.addImage(data.imageData, 0, 0, pdfPageWidth, pdfPageHeight, "", "NONE")
        })

        pdf.save("Carbon Sig-report.pdf")
        dispatch(setLoading(false))
        setIsGeneratingPdf(false)
        if (typeof onFinishGenerate === "function") {
          onFinishGenerate(true)
        }
      })
    }
  }

  const getReportPreviewHandler = () => {
    apiCallWrapper.call(getReportPreview, dispatch, {
      successHandler: (jsonData) => {
        setReportData(jsonData)
      },
      params: {
        inventoryId: previewData.id,
        quantity: previewData.quantity,
        credits: previewData.credits,
        assignedTo: previewData.assignedTo,
        thirdPartyVerified: previewData.thirdPartyVerified,
        thirdPartyCertifications: previewData.thirdPartyCertifications,
        calculationMethodology: previewData.calculationMethodology,
        obfuscatedItems: previewData.obfuscatedItems,
        obfuscatedProcesses: previewData.obfuscatedProcesses,
      },
    })
  }

  const renderFakeReport = () => {
    const initialElements = []
    initialElements.push(<ReportContentBox id={1} children={[]} innerRef={contentRef} />)
    initialElements.push(renderInventory())
    initialElements.push(renderDeclaredEmissions())
    initialElements.push(renderSplitLine())
    initialElements.push(renderHeadSectionTitle("Processes", ProcessesIcon, "3", "5"))
    initialElements.push(renderProcess(initialReportData.processes[0], 0))
    initialElements.push(renderProcessesSplitLine())
    initialElements.push(renderAttestationSummary(true, true))
    initialElements.push(renderAttestationSplitLine())
    initialElements.push(renderCertifications())
    initialElements.push(renderSectionTitle("Product Attributes"))
    initialElements.push(renderProductSpecification(initialReportData.specifications[0], 0))
    initialElements.push(renderSectionSplitLine())
    initialElements.push(renderCarbonCredit(initialReportData.credits[0], 0))
    initialElements.push(renderIOProcessTitle(initialReportData.processes[0].title))
    initialElements.push(renderIOLabelHeight(Inputs, "Inputs"))
    initialElements.push(renderCarbonCredit(initialReportData.inheritedCredits[0], 0))
    initialElements.push(
      <ReportInput
        index={0}
        input={initialReportData.processes[0].inputs[0]}
        innerRef={inputOutputItemRef}
      />
    )
    initialElements.push(renderIOSplitLine())
    return initialElements
  }

  const renderReport = (page, preview) => {
    const pages = []
    let currentPage = 1
    let elements = []
    let currentHeight = 0
    let processForPage = []
    let lastProcessItem = dataReport.processes.length - 1
    let lastOwnerCreditItem = dataReport.credits.length - 1
    let lastInheritedCreditItem = dataReport.inheritedCredits.length - 1
    let processesTitles = []
    let processes = []
    let certificationsHeight = 24
    let inventoryHeight = 109
    let emissionHeight = 352
    let splitLineHeight = 48
    let processHeight = 48
    let processesSplitLineHeight = 24
    let sectionSplitLineHeight = 16
    let ioSplitLine = 32
    let headTitleHeight = 45
    let summaryFirstRowHeight = 160
    let summarySecondRowHeight = 96
    let contentHeight = 728
    let attestationSplitLineHeight = 32
    let specificationHeight = 16
    let sectionTitleHeight = 24
    let creditHeight = 48
    let inputOutputItemHeight = 32
    let ioProcessTitleHeight = 32
    let ioLabelHeight = ioLabelRef.current?.clientHeight ? outerHeight(ioLabelRef.current) : 32
    currentHeight += inventoryHeight + splitLineHeight + emissionHeight + splitLineHeight
    elements.push(renderInventory())
    elements.push(renderSplitLine())
    elements.push(renderDeclaredEmissions())
    elements.push(renderSplitLine())
    dataReport.processes.length &&
      elements.push(renderHeadSectionTitle("Processes", ProcessesIcon, "3", "5")) &&
      (currentHeight += headTitleHeight)
    dataReport.processes.forEach((process, index) => {
      if (currentHeight + processHeight <= contentHeight) {
        currentHeight += processHeight
        processForPage.push(renderProcess(process, index))
      } else {
        elements.push(processForPage)
        pages.push(renderPage(currentPage, elements, preview))
        currentHeight = processHeight
        elements = []
        processForPage = []
        processForPage.push(renderProcess(process, index))
        currentPage++
      }
      if (index !== lastProcessItem) {
        if (currentHeight + processesSplitLineHeight <= contentHeight) {
          currentHeight += processesSplitLineHeight
          processForPage.push(renderProcessesSplitLine())
        }
      }
    })

    if (processForPage.length) {
      elements.push(processForPage)
    }

    if (dataReport.processes.length) {
      if (currentHeight + splitLineHeight <= contentHeight) {
        currentHeight += splitLineHeight
        elements.push(renderSplitLine())
      } else {
        pages.push(renderPage(currentPage, elements, preview))
        currentHeight = 0
        elements = []
        currentPage++
      }
    }

    if (currentHeight + summaryFirstRowHeight <= contentHeight) {
      currentHeight += summaryFirstRowHeight
      elements.push(renderAttestationSummary(true, false))
    } else {
      //elements.pop()
      pages.push(renderPage(currentPage, elements, preview))
      elements = []
      currentHeight = summaryFirstRowHeight
      currentPage++
      elements.push(renderAttestationSummary(true, false))
    }

    if (currentHeight + summarySecondRowHeight <= contentHeight) {
      currentHeight += summarySecondRowHeight
      elements.push(renderAttestationSummary(false, true))
    } else {
      pages.push(renderPage(currentPage, elements, preview))
      elements = []
      currentHeight = summarySecondRowHeight
      currentPage++
      elements.push(renderAttestationSummary(false, true))
    }

    if (dataReport.reportCertifications.length) {
      if (currentHeight + attestationSplitLineHeight <= contentHeight) {
        currentHeight += attestationSplitLineHeight
        elements.push(renderAttestationSplitLine())
      } else {
        pages.push(renderPage(currentPage, elements, preview))
        elements = []
        elements.push(renderAttestationSplitLine())
        currentHeight = attestationSplitLineHeight
        currentPage++
      }
    }

    if (dataReport.reportCertifications.length) {
      if (currentHeight + certificationsHeight <= contentHeight) {
        currentHeight += certificationsHeight
        elements.push(renderCertifications())
      } else {
        //elements.pop()
        pages.push(renderPage(currentPage, elements, preview))
        elements = []
        elements.push(renderCertifications())
        currentHeight = certificationsHeight
        currentPage++
      }
    }

    if (dataReport.specifications.length) {
      if (currentHeight + attestationSplitLineHeight <= contentHeight) {
        currentHeight += attestationSplitLineHeight
        elements.push(renderAttestationSplitLine())
      } else {
        pages.push(renderPage(currentPage, elements, preview))
        elements = []
        elements.push(renderAttestationSplitLine())
        currentHeight = attestationSplitLineHeight
        currentPage++
      }
    }

    if (dataReport.specifications.length) {
      if (currentHeight + sectionTitleHeight <= contentHeight) {
        currentHeight += sectionTitleHeight
        elements.push(renderSectionTitle("Product Attributes"))
      } else {
        //elements.pop()
        pages.push(renderPage(currentPage, elements, preview))
        elements = []
        elements.push(renderSectionTitle("Product Attributes"))
        currentHeight = sectionTitleHeight
        currentPage++
      }
    }

    if (dataReport.specifications.length) {
      dataReport.specifications.forEach((spec, index) => {
        if (currentHeight + specificationHeight <= contentHeight) {
          currentHeight += specificationHeight
          elements.push(renderProductSpecification(spec, index))
        } else {
          pages.push(renderPage(currentPage, elements, preview))
          elements = []
          elements.push(renderProductSpecification(spec, index))
          currentHeight = specificationHeight
          currentPage++
        }
      })
    }

    if (dataReport.processes.length) {
      if (currentHeight + splitLineHeight <= contentHeight) {
        currentHeight += splitLineHeight
        elements.push(renderSplitLine())
      } else {
        pages.push(renderPage(currentPage, elements, preview))
        currentHeight = 0
        elements = []
        currentPage++
      }
    }

    if (dataReport.processes.length) {
      if (
        currentHeight +
          headTitleHeight +
          ioProcessTitleHeight +
          ioLabelHeight +
          inputOutputItemHeight +
          sectionSplitLineHeight <=
        contentHeight
      ) {
        currentHeight += headTitleHeight
        elements.push(renderHeadSectionTitle("Process Flows", ProcessFlowsIcon, "4", "4"))
      } else {
        //elements.pop()
        pages.push(renderPage(currentPage, elements, preview))
        elements = []
        elements.push(renderHeadSectionTitle("Process Flows", ProcessFlowsIcon, "4", "4"))
        currentHeight = headTitleHeight
        currentPage++
      }
    }

    for (let i = 0; i < dataReport.processes.length; i++) {
      let printTitle = true
      let lastInputItemIndex = dataReport.processes[i].inputs.length - 1
      let lastOutputItemIndex = dataReport.processes[i].outputs.length - 1
      if (!dataReport.processes[i].inputs.length && !dataReport.processes[i].outputs.length) {
        continue
      }
      processesTitles.push(dataReport.processes[i].title)
      let obj = {
        inputs: [],
        outputs: [],
      }
      if (currentHeight + ioProcessTitleHeight + ioLabelHeight <= contentHeight) {
        currentHeight += ioProcessTitleHeight + ioLabelHeight
        if (dataReport.processes[i].inputs.length >= dataReport.processes[i].outputs.length) {
          dataReport.processes[i].inputs.forEach((input, inputIndex) => {
            let lastItem = inputIndex === lastInputItemIndex
            if (
              currentHeight + inputOutputItemHeight + (!lastItem ? sectionSplitLineHeight : 0) <=
              contentHeight
            ) {
              currentHeight += inputOutputItemHeight + (!lastItem ? sectionSplitLineHeight : 0)
              obj.inputs[inputIndex] = input
              if (dataReport.processes[i].outputs[inputIndex] !== undefined) {
                obj.outputs[inputIndex] = dataReport.processes[i]?.outputs[inputIndex]
              }
              processes[i] = obj
              processes[i].title = dataReport.processes[i].title
              processes[i].printTitle = printTitle
            } else {
              printTitle = false
              elements.push(renderInputsOutputs(processes))
              pages.push(renderPage(currentPage, elements, preview))
              processesTitles = []
              elements = []
              processes = []
              obj = {
                inputs: [],
                outputs: [],
              }
              currentHeight = inputOutputItemHeight + (!lastItem ? sectionSplitLineHeight : 0)
              obj.inputs[inputIndex] = input
              if (dataReport.processes[i].outputs[inputIndex] !== undefined) {
                obj.outputs[inputIndex] = dataReport.processes[i]?.outputs[inputIndex]
              }
              processes[i] = obj
              processes[i].title = dataReport.processes[i].title
              processes[i].printTitle = printTitle
              currentPage++
            }
          })
        } else {
          dataReport.processes[i].outputs.forEach((output, outputIndex) => {
            let lastItem = outputIndex === lastOutputItemIndex
            if (
              currentHeight + inputOutputItemHeight + (!lastItem ? sectionSplitLineHeight : 0) <=
              contentHeight
            ) {
              currentHeight += inputOutputItemHeight + (!lastItem ? sectionSplitLineHeight : 0)
              obj.outputs[outputIndex] = output
              if (dataReport.processes[i].inputs[outputIndex] !== undefined) {
                obj.inputs[outputIndex] = dataReport.processes[i]?.inputs[outputIndex]
              }
              processes[i] = obj
              processes[i].title = dataReport.processes[i].title
              processes[i].printTitle = printTitle
            } else {
              printTitle = false
              elements.push(renderInputsOutputs(processes))
              pages.push(renderPage(currentPage, elements, preview))
              processesTitles = []
              elements = []
              processes = []
              obj = {
                inputs: [],
                outputs: [],
              }
              currentHeight = inputOutputItemHeight + (!lastItem ? sectionSplitLineHeight : 0)
              obj.outputs[outputIndex] = output
              if (dataReport.processes[i].inputs[outputIndex] !== undefined) {
                obj.inputs[outputIndex] = dataReport.processes[i]?.inputs[outputIndex]
              }
              processes[i] = obj
              processes[i].title = dataReport.processes[i].title
              processes[i].printTitle = printTitle
              currentPage++
            }
          })
        }
        if (lastProcessItem > i) {
          if (currentHeight + ioSplitLine <= contentHeight) {
            currentHeight += ioSplitLine
          }
        }
      } else {
        elements.push(renderInputsOutputs(processes))
        pages.push(renderPage(currentPage, elements, preview))
        currentHeight = 0
        elements = []
        processes = []
        --i
        currentPage++
      }
    }

    if (processes.length) {
      elements.push(renderInputsOutputs(processes))
    }

    if (dataReport.credits.length || dataReport.inheritedCredits.length) {
      if (currentHeight + splitLineHeight <= contentHeight) {
        currentHeight += splitLineHeight
        elements.push(renderSplitLine())
      } else {
        pages.push(renderPage(currentPage, elements, preview))
        currentHeight = 0
        elements = []
        currentPage++
      }
    }

    if (dataReport.credits.length || dataReport.inheritedCredits.length) {
      if (currentHeight + headTitleHeight + sectionTitleHeight + creditHeight <= contentHeight) {
        currentHeight += headTitleHeight
        elements.push(renderHeadSectionTitle("Carbon Credits", CarbonCreditIcon, "3", "4"))
      } else {
        //elements.pop()
        pages.push(renderPage(currentPage, elements, preview))
        elements = []
        elements.push(renderHeadSectionTitle("Carbon Credits", CarbonCreditIcon, "3", "4"))
        currentHeight = headTitleHeight
        currentPage++
      }
    }

    if (dataReport.credits.length) {
      if (currentHeight + sectionTitleHeight + creditHeight <= contentHeight) {
        currentHeight += sectionTitleHeight
        elements.push(renderSectionTitle("Assigned by product owner"))
      }
    }

    if (dataReport.credits.length) {
      dataReport.credits.forEach((carbon, index) => {
        if (currentHeight + creditHeight <= contentHeight) {
          currentHeight += creditHeight
          elements.push(renderCarbonCredit(carbon, index))
        } else {
          pages.push(renderPage(currentPage, elements, preview))
          elements = []
          elements.push(renderCarbonCredit(carbon, index))
          currentHeight = creditHeight
          currentPage++
        }
        if (index !== lastOwnerCreditItem || dataReport.inheritedCredits.length) {
          if (currentHeight + sectionSplitLineHeight <= contentHeight) {
            currentHeight += sectionSplitLineHeight
            elements.push(renderSectionSplitLine())
          }
        }
      })
    }

    if (dataReport.inheritedCredits.length) {
      if (currentHeight + sectionTitleHeight + creditHeight <= contentHeight) {
        currentHeight += sectionTitleHeight
        elements.push(renderSectionTitle("Inherited by value chain"))
      } else {
        pages.push(renderPage(currentPage, elements, preview))
        elements = []
        elements.push(renderSectionTitle("Inherited by value chain"))
        currentHeight = sectionTitleHeight
        currentPage++
      }
    }

    if (dataReport.inheritedCredits.length) {
      dataReport.inheritedCredits.forEach((carbon, index) => {
        if (currentHeight + creditHeight <= contentHeight) {
          currentHeight += creditHeight
          elements.push(renderCarbonCredit(carbon, index))
        } else {
          pages.push(renderPage(currentPage, elements, preview))
          elements = []
          elements.push(renderCarbonCredit(carbon, index))
          currentHeight = creditHeight
          currentPage++
        }
        if (index !== lastInheritedCreditItem) {
          if (currentHeight + sectionSplitLineHeight <= contentHeight) {
            currentHeight += sectionSplitLineHeight
            elements.push(renderSectionSplitLine())
          }
        }
      })
    }

    if (currentPage > totalPages) {
      setTotalPages(currentPage)
      if (onTotalPageChange) {
        onTotalPageChange(currentPage)
      }
    }

    pages.push(renderPage(currentPage, elements, preview))

    return page || page === 0 ? pages[page] : pages
  }
  const renderPage = (pageNum, elements, preview) => {
    return (
      <div className="page">
        {preview ? (
          <div>
            {envHelper.isSandbox() ? (
              <img
                className={"preview-img-watermark"}
                style={{
                  position: "absolute",
                  marginTop: "150px",
                  zIndex: 1,
                }}
                src={PreviewBackgroundSandbox}
                alt="Sandbox Image"
              />
            ) : (
              !watermarkShare && (
                <img
                  className="preview-img-watermark"
                  style={{
                    position: "absolute",
                    marginTop: "150px",
                    zIndex: 1,
                  }}
                  src={PreviewBackground}
                  alt="Regular Image"
                />
              )
            )}
          </div>
        ) : envHelper.isSandbox() ? (
          <div>
            <img
              className={"preview-img-watermark"}
              style={{
                position: "absolute",
                marginTop: "150px",
                zIndex: 1,
              }}
              src={PreviewBackgroundSandbox}
              alt="Sandbox Image"
            />
          </div>
        ) : null}
        <ReportHeader
          id={pageNum}
          reportId={dataReport.header.reportId}
          confirmedOn={dataReport.header.confirmedOn}
          generatedOn={dataReport.header.generatedOn}
          tenantTitle={dataReport.header.tenantTitle}
          tenantId={dataReport.header.tenantId}
          tenantPhone={dataReport.header.tenantPhone}
          tenantAddress={dataReport.header.tenantAddress}
          shareType={shareType}
          backgroundColor={"#0071AB"}
          color={"#fff"}
        />
        <ReportContentBox key={getId()} id={pageNum}>
          {elements.map((item) => item)}
        </ReportContentBox>
        <ReportFooter
          id={pageNum}
          page={pageNum}
          totalPages={totalPages}
          reportId={dataReport.header.reportId}
        />
      </div>
    )
  }

  const innerDimensions = (node) => {
    let computedStyle = getComputedStyle(node)
    let width = node.clientWidth
    let height = node.clientHeight
    height -= parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom)
    width -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight)
    return [height, width]
  }

  const outerHeight = (node) => {
    let height = node.offsetHeight
    const style = getComputedStyle(node)
    height += parseInt(style.marginTop) + parseInt(style.marginBottom)
    return height
  }

  const dimensionsHandler = (node) => {
    let nodeElement = document.getElementById(node.id)
    let height = innerDimensions(nodeElement)[0]
    return height
  }

  const getId = () => {
    return Date.now().toString(36) + Math.random().toString(36).substr(2)
  }
  const renderInventory = () => {
    return (
      <ReportInventory
        assignedTo={dataReport.inventory.assignedTo}
        owner={dataReport.inventory.owner}
        quantity={dataReport.inventory.quantity}
        quantityUnit={dataReport.inventory.quantityUnit}
        title={dataReport.inventory.title}
        innerRef={inventoryRef}
        companyLogo={
          process.env.REACT_APP_GATEWAY_URL +
          `/files/api/image-path?imagePath=${dataReport.header.companyLogo}`
        }
      />
    )
  }

  const renderDeclaredEmissions = () => {
    return (
      <ReportDeclaredEmissions
        co2EmissionsProcess={dataReport.declaredEmissions.co2EmissionsProcess}
        co2EmissionsValueChain={dataReport.declaredEmissions.co2EmissionsValueChain}
        co2SourcesProcess={dataReport.declaredEmissions.co2SourcesProcess}
        co2SourcesValueChain={dataReport.declaredEmissions.co2SourcesValueChain}
        co2RemovalsProcess={dataReport.declaredEmissions.co2RemovalsProcess}
        co2RemovalsValueChain={dataReport.declaredEmissions.co2RemovalsValueChain}
        co2OffsetsByOwner={dataReport.declaredEmissions.co2OffsetsByOwner}
        co2OffsetsValueChain={dataReport.declaredEmissions.co2OffsetsValueChain}
        co2FromProduction={dataReport.declaredEmissions.co2FromProduction}
        intensity={dataReport.declaredEmissions.intensity}
        co2WithOffsets={dataReport.declaredEmissions.co2WithOffsets}
        intensityWithOffsets={dataReport.declaredEmissions.intensityWithOffsets}
        quantity={dataReport.inventory.quantity}
        unit={dataReport.inventory.quantityUnit}
        innerRef={emissionRef}
      />
    )
  }

  const renderProcess = (process, index) => {
    return (
      <ReportProcess
        process={process}
        index={index}
        id={index}
        co2={dataReport.declaredEmissions.co2FromProduction}
        co2Sinks={
          dataReport.declaredEmissions.co2RemovalsProcess +
          dataReport.declaredEmissions.co2RemovalsValueChain
        }
        innerRef={processRef}
      />
    )
  }

  const renderAttestationSummary = (renderFirstRow, renderSecondRow) => {
    return (
      <ReportAttestationSummary
        gwpEmissionsFactors={dataReport.summary.gwpEmissionsFactors}
        dataCompleteness={dataReport.summary.dataCompleteness}
        carbonCreditProjects={dataReport.summary.carbonCreditProjects}
        greenhouseGasInventory={dataReport.summary.greenhouseGasInventory}
        siteEmissionsTracked={dataReport.summary.siteEmissionsTracked}
        thirdPartyVerified={dataReport.summary.thirdPartyVerified}
        thirdPartyVerifiedName={dataReport.summary.thirdPartyVerifiedName}
        reportedProcesses={dataReport.summary.reportedProcesses}
        declaration={dataReport.summary.declaration}
        allocationRules={dataReport.summary.allocationRules}
        cutOffRules={dataReport.summary.cutOffRules}
        removals={dataReport.summary.removals}
        reductions={dataReport.summary.reductions}
        reportedProductFlows={dataReport.summary.reportedProductFlows}
        renderFirstRow={renderFirstRow}
        renderSecondRow={renderSecondRow}
        firstInnerRef={summaryFirstRowRef}
        secondInnerRef={summarySecondRowRef}
      />
    )
  }

  const renderAttestationSplitLine = () => {
    return <AttestationSplitLine innerRef={attestationSplitLineRef} />
  }

  const renderProductSpecification = (specification, index) => {
    return (
      <ReportProductSpecifications spec={specification} index={index} innerRef={specificationRef} />
    )
  }

  const renderSplitLine = () => {
    return <ReportSplitLine innerRef={splitLineRef} />
  }

  const renderProcessesSplitLine = () => {
    return <ProcessesSplitLine innerRef={processesSplitLineRef} />
  }

  const renderSectionSplitLine = () => {
    return <SectionSplitLine innerRef={sectionSplitLineRef} />
  }

  const renderIOSplitLine = () => {
    return <IOSplitLine innerRef={ioSplitLineRef} />
  }
  const renderHeadSectionTitle = (title, icon, imgPaddingTopBottom, imgPaddingLeftRight) => {
    return (
      <HeadSectionTitle
        title={title}
        icon={icon}
        imgPaddingTopBottom={imgPaddingTopBottom}
        imgPaddingLeftRight={imgPaddingLeftRight}
        innerRef={headTitleRef}
      />
    )
  }

  const renderCertifications = () => {
    return (
      <ReportCertifications
        certifications={dataReport.reportCertifications}
        innerRef={certificationsRef}
      />
    )
  }

  const renderSectionTitle = (title) => {
    return <SectionTitle title={title} innerRef={sectionTitleRef} />
  }

  const renderIOProcessTitle = (title) => {
    return <IOProcessTitle title={title} innerRef={ioProcessTitleRef} />
  }

  const renderIOLabelHeight = (icon, title) => {
    return <IOLabel icon={icon} title={title} innerRef={ioLabelRef} />
  }

  const renderInputsOutputs = (processes) => {
    return <ReportInputsOutputs processes={processes} />
  }

  const renderCarbonCredit = (carbon, index) => {
    return <CarbonCredit carbon={carbon} index={index} innerRef={creditRef} />
  }

  const renderNextPreviousArrows = (show, isGeneratingPdf) => {
    if (currentPage !== undefined) {
      return null
    }
    if (isGeneratingPdf) {
      return (
        <>
          <img
            src={ArrowLeft}
            style={{
              filter: "grayscale(1) brightness(1.5)",
              cursor: "default",
            }}
            alt="left"
            className="footer-images"
          />
          <img
            src={ArrowRight}
            style={{
              filter: "grayscale(1) brightness(1.5)",
              cursor: "default",
              marginLeft: show ? "10px" : "",
            }}
            alt="right"
            className="footer-images"
          />
        </>
      )
    }
    return (
      <>
        <img
          src={ArrowLeft}
          className="footer-images"
          alt="left"
          onClick={() => {
            setShowPage((prev) => (prev !== 0 ? prev - 1 : 0))
          }}
        />
        <img
          src={ArrowRight}
          className="footer-images"
          alt="right"
          style={{ marginLeft: show ? "10px" : "" }}
          onClick={() => {
            setShowPage((prev) => (prev !== totalPages - 1 ? prev + 1 : totalPages - 1))
          }}
        />
      </>
    )
  }

  useEffect(() => {
    if (generate) {
      generatePDF()
    }
  }, [generate])

  useEffect(() => {
    if (showPreview) {
      if (objectHelper.isEmptyObject(systemOutputPreview)) {
        getReportPreviewHandler()
      } else {
        setReportData(systemOutputPreview)
      }
    } else {
      if (!isExternalShare) {
        getReportHandler()
      } else {
        let param = uid || id || shareId
        console.log({ param })
        getReportShareExternalHandler(param)
      }
    }
  }, [])

  return (
    <>
      {isFirefox && style}

      {showPreview && (
        <div
          className="report-preview-modal"
          id={"modal-wrapper"}
          onClick={(e) => e.target.id === "modal-wrapper" && onClose()}
        >
          <div className="report-publish-wrapper">{renderReport(showPage, showPreview)}</div>
          <div className="print-source report-publish-wrapper" ref={printPageRef}>
            {renderReport(false, showPreview)}
          </div>
          <div className="report-preview-buttons-container">
            {renderNextPreviousArrows(false, isGeneratingPdf)}
            {isAdminOrPublisher ? (
              !isGeneratingPdf ? (
                <img
                  src={SaveToPdf}
                  className="footer-images"
                  onClick={generatePDF}
                  alt="Save TO PDF"
                />
              ) : (
                <img
                  src={carbonSigLoader}
                  alt="Loading Indicator"
                  height={30}
                  width={30}
                  style={{ borderRadius: "50%" }}
                />
              )
            ) : (
              <img
                src={SaveToPdf}
                className="footer-images"
                style={{
                  filter: "grayscale(1) brightness(1.5)",
                  cursor: "default",
                }}
                alt="Save TO PDF"
              />
            )}
            {!isGeneratingPdf ? (
              <img src={ReportClose} alt="footer" className={"footer-images"} onClick={onClose} />
            ) : (
              <img
                src={ReportClose}
                alt="footer"
                className={"footer-images"}
                style={{
                  filter: "grayscale(1) brightness(1.5)",
                  cursor: "default",
                }}
              />
            )}
          </div>
        </div>
      )}
      {!showPreview && (
        <div className="report-published-container">
          <div className="report-publish-wrapper">{renderReport(showPage, showPreview)}</div>
          <div className="print-source report-publish-wrapper" ref={printPageRef}>
            {renderReport(false, showPreview)}
          </div>
          <div style={{ marginTop: "40px", marginBottom: "40px" }}>
            {renderNextPreviousArrows(true)}
            {currentPage === undefined ? (
              <div style={{ float: "right" }}>
                <CButton className="report-back-btn" onClick={() => history.goBack()}>
                  Back
                </CButton>
                <CButton
                  className="report-save-btn"
                  onClick={generatePDF}
                  disabled={isGeneratingPdf}
                >
                  {isGeneratingPdf ? (
                    <img
                      src={carbonSigLoader}
                      alt="Loading Indicator"
                      height={24}
                      width={24}
                      style={{ borderRadius: "50%", marginRight: "16px", marginLeft: "-16px" }}
                    />
                  ) : null}
                  Save as PDF
                </CButton>
              </div>
            ) : null}
          </div>
        </div>
      )}
      {/* Full Hidden Report with all pages rendered, required for headless browser print to PDF generation */}
      <div className="full-hidden-report">{renderReport(null, false)}</div>
    </>
  )
}

export default Report
