import { useEffect, useRef } from "react"
import * as d3 from "d3"
import numberFormat from "src/helpers/NumberFormat"

const D3TreeMapChart = ({ width, height, data }) => {
  const svgRef = useRef(null)
  const legendRef = useRef(null)

  function dotme(text) {
    text.each(function () {
      var text = d3.select(this)
      var letters = Array.from(text.text())

      var ellipsis = text.text("").append("tspan").attr("class", "elip").text("...")
      var width = parseFloat(text.attr("width")) - ellipsis.node().getComputedTextLength()
      var numLetters = letters.length

      var tspan = text.insert("tspan", ":first-child").text(letters.join(""))

      while (tspan.node().getComputedTextLength() > width && letters.length) {
        letters.pop()
        tspan.text(letters.join(""))
      }

      if (letters.length === numLetters) {
        ellipsis.remove()
      }
    })
  }

  function renderTreemap(svgRef, legendRef, width, height, data) {
    const svg = d3.select(svgRef.current)
    svg.selectAll("g").remove()

    const legendContainer = d3.select(legendRef.current)
    legendContainer.selectAll("g").remove()

    const values = data.children.flatMap((child) => child.children.map((c) => c.value || 0))
    const highestValue = Math.max(...values)
    const threshold = highestValue * 0.005

    // create root node
    const root = d3.hierarchy(data).sum((d) => {
      return d.value < threshold ? d.value * (threshold / d.value) : d.value
    })

    // create treemap layout
    const treemapRoot = d3.treemap().size([width, height]).padding(1)(root)
    treemapRoot.sum((d) => d.value)

    // create 'g' element nodes based on data
    const nodes = svg
      .selectAll("g")
      .data(treemapRoot.leaves())
      .join("g")
      .attr("transform", (d) => `translate(${d.x0},${d.y0})`)

    // create color scheme and fader
    const colorScale = d3
      .scaleOrdinal()
      .domain(["Source", "Sink", "Credit"])
      .range(["#00a2e6", "#00b995", "#f5a623"])
    // add treemap rects
    nodes
      .append("rect")
      .attr("width", (d) => d.x1 - d.x0)
      .attr("height", (d) => d.y1 - d.y0)
      .attr("fill", (d) => colorScale(d.data.colname))

    const fontSize = 14

    // add text to rects
    nodes
      .append("title")
      .text((d) => `${d.data.name}\n${numberFormat.localeString(d.value)} kg CO2e`)

    nodes
      .append("svg")
      .attr("width", (d) => d.x1 - d.x0)
      .attr("height", (d) => d.y1 - d.y0)
      .append("text")
      .text((d) => d.data?.name)
      .attr("width", (d) => d.x1 - d.x0)
      .attr("height", (d) => d.y1 - d.y0)
      .attr("class", "dotme")
      .attr("text-anchor", "middle")
      .attr("data-width", (d) => d.x1 - d.x0)
      .attr("font-size", `${fontSize}px`)
      .attr("fill", "white")
      .attr("x", fontSize)
      .attr("y", fontSize + 13)
      .call(adjustSize)
      .style("position", "relative")

    function adjustSize(selection) {
      selection.each(function () {
        const node = d3.select(this)
        const nodeSize = d3.select(this.parentNode).datum()
        node.attr("x", (nodeSize.x1 - nodeSize.x0) / 2)
        node.attr("y", (nodeSize.y1 - nodeSize.y0) / 2 + 5)
      })
    }

    // pull out hierarchy categories
    let categories = root.leaves().map((node) => node.data?.colname)
    categories = categories.filter((category, index, self) => self.indexOf(category) === index)

    legendContainer.attr("width", width).style("height", 25)

    // create 'g' elements based on categories
    const legend = legendContainer.selectAll("g").data(categories).join("g")

    // create 'circle' for each category
    const position = 100
    legend
      .append("circle")
      .attr("width", fontSize)
      .attr("height", fontSize)
      .attr("cx", (_, i) => position * i + 6)
      .attr("cy", 13)
      .attr("r", 6)
      .attr("fill", (d) => colorScale(d))

    // add text to each category key
    legend
      .append("text")
      .attr("transform", `translate(0, ${fontSize})`)
      .attr("x", (_, i) => position * i + 20)
      .attr("y", fontSize - 10)
      .style("font-size", fontSize)
      .text((d) => d)

    d3.selectAll(".dotme").call(dotme)
  }

  useEffect(() => {
    renderTreemap(svgRef, legendRef, width, height, data)
  }, [data, width])

  return (
    <div className="treemap__chart">
      <div>
        <svg ref={svgRef} width={width} height={height}></svg>
      </div>
      <div className="treemap__chart-legend mt-4">
        <svg ref={legendRef}></svg>
      </div>
    </div>
  )
}

export default D3TreeMapChart
