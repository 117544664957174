import { createSlice } from "@reduxjs/toolkit"

const initialState = {}

export const lciSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    putLci: (state, action) => {
      state[action.payload.id] = action.payload
    },
  },
})

export const { putLci } = lciSlice.actions

export default lciSlice.reducer
