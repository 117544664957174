import { CFooter } from "@coreui/react"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { setTermsModalShow } from "src/redux/slices/uiSlice"
import TermsOfServiceModal from "src/reusable/TermsOfServiceModal"

const TheFooter = ({ light, color }) => {
  const termsModalShow = useSelector((state) => state.ui.termsModalShow)
  const inventorySidebarExpanded = useSelector((state) => state.ui.inventorySidebarExpanded)
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const isFooterNarrow = pathname.match(/inventory\/details|public\/inventory/g)
  const [isHovered, setIsHovered] = useState(false)
  const [isHoveredCarbon, setIsHoveredCarbon] = useState(false)
  const [isHoveredTerms, setIsHoveredTerms] = useState(false)

  const getFooterPaddingRight = () => {
    return isFooterNarrow ? (inventorySidebarExpanded ? 320 : 60) : 16
  }

  const openModalHandler = () => {
    dispatch(setTermsModalShow(true))
  }

  const closeModalHandler = () => {
    dispatch(setTermsModalShow(false))
  }

  return (
    <>
      <CFooter
        fixed={false}
        className={`footer footer-light`}
        style={{
          paddingRight: getFooterPaddingRight(),
        }}
      >
        <div>
          <span className="ml-1" style={{ color: color ? "white" : "inherit" }}>
            {new Date().getFullYear()}. Carbon Finance Labs, Inc<sup>tm</sup>. All Rights Reserved
          </span>
        </div>
        <div>
          <span className="footer-item mx-2">
            <a
              href="https://help.carbonsig.app/en"
              style={{
                color: color ? "white" : "inherit",

                color: isHoveredCarbon ? "#455561" : color ? "white" : "inherit",
              }}
              target="_blank"
              rel="noopener noreferrer"
              onMouseEnter={() => setIsHoveredCarbon(true)}
              onMouseLeave={() => setIsHoveredCarbon(false)}
            >
              CarbonSig.com
            </a>
          </span>
          <span className="footer-item mx-2">
            {" "}
            <a
              href="https://www.carbonsig.com/privacy-policy"
              style={{
                color: color ? "white" : "inherit",

                color: isHovered ? "#455561" : color ? "white" : "inherit",
              }}
              target="_blank"
              rel="noopener noreferrer"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              Privacy
            </a>
          </span>
          <span
            className="footer-item mx-2"
            style={{
              color: color ? "white" : "inherit",

              color: isHoveredTerms ? "#455561" : color ? "white" : "inherit",
            }}
            target="_blank"
            rel="noopener noreferrer"
            onMouseEnter={() => setIsHoveredTerms(true)}
            onMouseLeave={() => setIsHoveredTerms(false)}
            onClick={openModalHandler}
          >
            Terms of Service
          </span>
        </div>
      </CFooter>
      <TermsOfServiceModal show={termsModalShow} onClose={closeModalHandler} />
    </>
  )
}

export default React.memo(TheFooter)
