import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: null,
}

export const workspacesSlice = createSlice({
  name: "workspaces",
  initialState,
  reducers: {
    setWorkspaces: (state, action) => {
      state.data = action.payload
    },
  },
})

export const { setWorkspaces } = workspacesSlice.actions

export default workspacesSlice.reducer
