import React from "react"
import SummaryIcon from "../../assets/icons/report-attestation-summary.svg"
import Attestation from "./Attestation"
import HeadSectionTitle from "./HeadSectionTitle"

const ReportAttestationSummary = ({
  gwpEmissionsFactors,
  dataCompleteness,
  carbonCreditProjects,
  greenhouseGasInventory,
  siteEmissionsTracked,
  thirdPartyVerified,
  thirdPartyVerifiedName,
  reportedProcesses,
  removals,
  reductions,
  reportedProductFlows,
  renderFirstRow,
  renderSecondRow,
  firstInnerRef,
  secondInnerRef,
  declaration,
  allocationRules,
  cutOffRules
}) => {
  let thirdParty = thirdPartyVerified ? `Yes (${thirdPartyVerifiedName})` : "No"
  return (
    <>
      {renderFirstRow && (
        <div style={{ height: "160px" }} ref={firstInnerRef}>
          <HeadSectionTitle
            title={"Attestation Summary"}
            icon={SummaryIcon}
            imgPaddingTopBottom={"4"}
            imgPaddingLeftRight={"3"}
          />
          <div
            className="display-row justify-content-between"
            style={{ marginBottom: "16px" }}
          >
            <Attestation
              title={"GWP emissions factors:"}
              value={gwpEmissionsFactors}
            />
            <Attestation
              title={"Carbon credit projects:"}
              value={carbonCreditProjects}
              marginLeft={"24px"}
            />
            <Attestation
              title={"Declaration:"}
              value={declaration}
              marginLeft={"24px"}
            />
          </div>
          <div
            className="display-row justify-content-between"
            style={{ marginBottom: "16px" }}
          >
            <Attestation
              title={"Greenhouse gas inventory methodology:"}
              value={greenhouseGasInventory}
            />
            <Attestation
              title={"Direct emissions tracked:"}
              value={
                siteEmissionsTracked
                  ? siteEmissionsTracked.toFixed(2) + "%"
                  : "0%"
              }
              marginLeft={"24px"}
            />
            <Attestation
              title={"Cut Off Rules:"}
              value={cutOffRules}
              marginLeft={"24px"}
            />
          </div>
        </div>
      )}
      {renderSecondRow && (
        <div ref={secondInnerRef}>
          <div
            className="display-row justify-content-between"
            style={{ marginBottom: "16px" }}
          >
            <Attestation title={"Third-party Verified:"} value={thirdParty} />
            <Attestation
              title={"Reported processes:"}
              value={reportedProcesses}
              marginLeft={"24px"}
            />
            <Attestation
              title={"Allocation Rules:"}
              value={allocationRules}
              marginLeft={"24px"}
            />
          </div>
          <div className="display-row justify-content-between">
            <Attestation
              title={"Reported product flows:"}
              value={reportedProductFlows}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default ReportAttestationSummary
