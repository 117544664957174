const ProcessSystemLegend = () => {
    return (
        <div>
           <div style={{display: 'flex', flexDirection: "row", justifyContent: 'left', alignItems: 'center'}}>
            <div style={{backgroundColor: '#118AE7', width: '16px', height: '12px', borderRadius: '20%', marginTop: '3px'}}/><span style={{paddingLeft: '5px'}}> input object</span></div>
            <div style={{display: 'flex', flexDirection: "row", justifyContent: 'left', alignItems: 'center'}}>
            <div style={{backgroundColor: '#FFFFFF', width: '16px', height: '12px', borderRadius: '20%', marginTop: '3px', borderStyle:'solid', borderColor:'black', borderWidth:'1px'}}/><span style={{paddingLeft: '5px'}}> process unit</span></div>
            <div style={{display: 'flex', flexDirection: "row", justifyContent: 'left', alignItems: 'center'}}>
            <div style={{backgroundColor: '#0C2F59', width: '16px', height: '12px', borderRadius: '20%', marginTop: '3px'}}/><span style={{paddingLeft: '5px'}}> output / intermediate object</span></div>
        </div>
    )
}

export default ProcessSystemLegend;