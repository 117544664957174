import React from "react"

const SectionTitle = ({ title, innerRef }) => {
  return (
    <div ref={innerRef} className="section-title">
      <span className="titles">{title}:</span>
    </div>
  )
}

export default SectionTitle
