import { path } from "d3"
import request from "../Request"

const getScaledCreditsShareExternal = (params, completedCallback, errorCallback) => {
  const path = `/inventory/api/attestation-unit/scaled-credits/share-exernal/${params.quantity}`
  delete params.quantity
  request.call({
    path: path,
    method: "post",
    completedCallback: completedCallback,
    errorCallback: errorCallback,
    params: params,
  })
}

export default getScaledCreditsShareExternal
