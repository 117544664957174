import request from "src/requests/Request"

const copyProcessSystem = (params, completedCallback, errorCallback) => {
  request.call({
    path: "/inventory/api/process-system/copy",
    method: "post",
    completedCallback: completedCallback,
    errorCallback: errorCallback,
    params: params,
  })
}

export default copyProcessSystem
