import React from "react"

const ReportContentBox = ({ id, children, innerRef }) => {
  return (
    <div
      ref={innerRef ? innerRef : null}
      key={"content-box-" + id}
      id={id}
      className="content-box-container"
    >
      {children}
    </div>
  )
}

export default ReportContentBox
