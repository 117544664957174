import Big from "big.js"

const numberFormat = (function () {
  return {
    decimal: function (number, decimals) {
      if (!decimals) {
        decimals = 2
      }
      return +Big(number).toFixed(decimals)
    },
    localeString: function (num, locale = "en-US") {
      let number = num

      if (typeof number !== "number") {
        if (typeof number === "string") {
          number = number.replaceAll(",", "")
        }
        number = Number(number)
      }

      if (isNaN(number)) return "NaN"

      if (number === 0) return 0

      if (number > 0) {
        if (number > 0 && number < 1) {
          return number.toLocaleString(locale, {
            maximumFractionDigits: 4,
          })
        }

        if (number < 0.00001 || number >= 10000000000) {
          return number.toExponential(3).toLocaleString(locale)
        }

        if (number >= 0.00001 && number < 100) {
          return number.toLocaleString(locale, {
            maximumFractionDigits: 2,
          })
        }

        if (number >= 100 && number < 1000000000) {
          return number.toLocaleString(locale, {
            useGrouping: true,
            maximumSignificantDigits: 10,
            maximumFractionDigits: 2,
            roundingPriority: "lessPrecision",
          })
        }

        if (number >= 1000000000 && number < 10000000000) {
          return number.toLocaleString(locale, {
            useGrouping: true,
            maximumSignificantDigits: 10,
            roundingMode: "floor",
          })
        }
      } else if (number < 0) {
        if (number < 0 && number > -1) {
          return number.toLocaleString(locale, {
            maximumFractionDigits: 4,
          })
        }

        if (number > -0.00001 || number <= -10000000000) {
          return number.toExponential(3).toLocaleString(locale)
        }

        if (number <= -0.00001 && number > -100) {
          return number.toLocaleString(locale, {
            maximumFractionDigits: 2,
          })
        }

        if (number <= -100 && number > -1000000000) {
          return number.toLocaleString(locale, {
            useGrouping: true,
            maximumSignificantDigits: 10,
            maximumFractionDigits: 2,
            roundingPriority: "lessPrecision",
          })
        }

        if (number <= -1000000000 && number > -10000000000) {
          return number.toLocaleString(locale, {
            useGrouping: true,
            maximumSignificantDigits: 10,
            roundingMode: "floor",
          })
        }
      }
    },
  }
})()

export default numberFormat
