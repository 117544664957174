import request from "src/requests/Request"

const exportProcessSystem = (params, completedCallback, errorCallback) => {
  request.call({
    path: "/inventory/api/process-system/export-process-system-data",
    method: "post",
    completedCallback: completedCallback,
    errorCallback: errorCallback,
    params: params,
  })
}

export default exportProcessSystem
