import { CModal, CModalBody } from "@coreui/react"
import { useSelector } from "react-redux"
import carbonSigLoader from "../assets/carbon-sig-loader.gif"

const TheLoadingIndicator = () => {
  const loading = useSelector((state) => state.ui.loading)

  return (
    <div className="loading-indicator">
      <CModal size="sm" show={loading} centered>
        <CModalBody
          style={{
            display: "flex",
            justifyContent: "center",
            padding: 20,
            zIndex: 9999,
          }}
        >
          <img src={carbonSigLoader} alt="Loading Indicator" height={100} width={100} />
        </CModalBody>
      </CModal>
    </div>
  )
}

export default TheLoadingIndicator
