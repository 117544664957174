import React from 'react'
import UserBackCode from 'src/reusable/userback/UserbackCode'
import TheErrorModal from '../TheErrorModal'
import TheLoadingIndicator from '../TheLoadingIndicator'
import TheSuccessModal from '../TheSuccessModal'
import PublicContent from './PublicContent'
import PublicFooter from './PublicFooter'
import PublicHeader from './PublicHeader'
import PublicSidebar from './PublicSidebar'

const PublicLayout = ({content}) => {

  return (
    <div className="c-app c-default-layout">
      <PublicSidebar />
      <div className="c-wrapper">
        <PublicHeader />
        <div className="c-body">
          <TheLoadingIndicator />
          <TheSuccessModal />
          <TheErrorModal />
          <PublicContent content={content} />
        </div>
        <PublicFooter />
      </div>
      <UserBackCode />
    </div>
  )
}

export default PublicLayout
