import React from "react"
import Arrow from "./Arrow"

const ProcessChart = ({
  emissionTotal,
  emissionProcess,
  emissionValue,
  colorProcess,
  colorValue,
  rotate,
}) => {
  const adjustWidth = (width, minWidth) => {
    if (width && width < minWidth) {
      width = minWidth
    }
    return width
  }

  const maxWidth = 120
  const lineHeight = 12
  const minWidth = 5
  if (!colorProcess) {
    colorProcess = "#779BAA"
  }
  if (!colorValue) {
    colorValue = "#9DC5D1"
  }
  if (!rotate) {
    rotate = false
  }
  const total = emissionTotal
  const processWidth = adjustWidth(
    parseInt(Math.ceil((emissionProcess / total) * maxWidth), 10),
    minWidth
  )
  const valueWidth = adjustWidth(
    parseInt(Math.ceil((emissionValue / total) * maxWidth), 10),
    minWidth
  )
  const valueStart = processWidth - 2

  return (
    <div className="process-chart" style={{ height: lineHeight * 2 + "px" }}>
      <div style={{ height: lineHeight + "px" }}>
        <Arrow color={colorProcess} width={processWidth} height={lineHeight} rotate={rotate} />
      </div>
      <div style={{ marginLeft: valueStart + "px", height: lineHeight + "px" }}>
        <Arrow color={colorValue} width={valueWidth} height={lineHeight} rotate={rotate} />
      </div>
    </div>
  )
}

export default ProcessChart
