import request from "../Request"

const addProcessSystem = (params, completedCallback, errorCallback) => {
  request.call({
    path: "/inventory/api/process-system",
    method: "post",
    params: params,
    completedCallback: completedCallback,
    errorCallback: errorCallback,
  })
}

export default addProcessSystem
