import React from "react"

import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CLabel,
  CInput,
  CButton,
} from "@coreui/react"

import HeadingArchive from "../../../assets/icons/heading-delete.svg"

const ProcessSystemDeleteModal = ({
  show,
  isChecked,
  items,
  onClose,
  item,
  onDeleteOneItem,
  onDeleteItems,
}) => {
  let title = "Delete Process System "

  if (isChecked && items.length > 1) {
    title = "Delete Process Systems"
  }

  return (
    <CModal
      closeOnBackdrop={false}
      style={{
        borderRadius: 0,
        padding: 20,
        paddingLeft: 30,
        paddingRight: 30,
        borderColor: "white",
      }}
      show={show}
      onClose={onClose}
    >
      <CModalHeader className="modal-heading">
        <CModalTitle className="modal-heading-title">
          <img className="modal-heading-img" src={HeadingArchive} alt="heading" />

          {title}
        </CModalTitle>
      </CModalHeader>

      <CModalBody>
        {isChecked &&
          items.map((value, key) => {
            return (
              <React.Fragment key={key}>
                <CLabel>Process System Name</CLabel>

                <CInput
                  className="modal-body-input"
                  disabled
                  id="title"
                  name="title"
                  value={value.title}
                />
              </React.Fragment>
            )
          })}

        {!isChecked && (
          <>
            <CLabel>Process System Name</CLabel>

            <CInput
              className="modal-body-input"
              disabled
              id="title"
              name="title"
              value={item.title}
            />
          </>
        )}

        <div className="text-modal">
          You have selected to delete {isChecked ? "these process systems" : "this process system"}{" "}
          from the process system templates. This option cannot be undone. Do you wish to continue?
        </div>
      </CModalBody>

      <CModalFooter className="modal-footer-border-width">
        <div className="modal-button-container">
          <div className="modal-button-wrapper modal-button-padding-right text-align">
            <CButton className="btn-pill btn-cancel" onClick={onClose}>
              Cancel
            </CButton>
          </div>

          <div className="modal-button-wrapper modal-button-padding-left">
            <CButton
              className="btn-pill btn-delete"
              onClick={() => {
                isChecked ? onDeleteItems(items) : onDeleteOneItem(item.id)

                onClose()
              }}
            >
              Delete
            </CButton>
          </div>
        </div>
      </CModalFooter>
    </CModal>
  )
}

export default ProcessSystemDeleteModal
