import React from "react"
import { Helmet } from "react-helmet"

const UserBackCode = () => {
  const isDisabled = true

  let userBackScript = ""
  if (
    document.location.host === "carbonsig.app" ||
    document.location.host === "certs.carbonsig.app"
  ) {
    userBackScript = "/userback/userback-prod.js"
  } else if (
    document.location.host === "carbonsig-staging.softmetrixgroup.com" ||
    document.location.host === "certs.carbonsig-staging.softmetrixgroup.com"
  ) {
    userBackScript = "/userback/userback-dev.js"
  }

  if (isDisabled) return null

  return (
    <>
      {userBackScript ? (
        <Helmet>
          <script type="text/javascript" src={userBackScript} defer />
        </Helmet>
      ) : (
        ""
      )}
    </>
  )
}

export default UserBackCode
