import {
  CButton,
  CCol,
  CForm,
  CFormGroup,
  CInput,
  CInputGroup,
  CInputGroupAppend,
  CInputGroupText,
  CInputRadio,
  CInvalidFeedback,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react"
import Slider, { createSliderWithTooltip } from "rc-slider"
import "rc-slider/assets/index.css"
import { yupResolver } from "@hookform/resolvers/yup"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import * as Yup from "yup"
import numberFormat from "src/helpers/NumberFormat"
import DecarbonizationIcon from "src/assets/icons/decarbonization-calculator-icon.svg"
import { locales } from "moment/moment"

const ProductExplorerCalculator = ({ showModal, onClose, genealogyItem }) => {
  const SliderWithTooltip = createSliderWithTooltip(Slider)
  const percentFormatter = (v) => {
    return `${v} %`
  }
  const dispatch = useDispatch()

  const validationSchema = Yup.object().shape({
    targetReductionInput: Yup.string()
      .matches(/^\d+$/, "Must be number")
      .trim()
      .required("Reduction is required"),
    carbonCreditCost: Yup.string()
      .matches(/^\d+$/, "Must be number")
      .required("Carbon Credit Cost is required"),
    costOfGoodsSold: Yup.string()
      .matches(/^\d+$/, "Must be number")
      .required("Cost of Goods Sold is required"),
  })

  const { reset, errors, register, watch } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const [targetReduction, setTargetReduction] = useState("")
  const [costOfCarbon, setCostOfCarbon] = useState(0)
  const [costOfGoods, setCostOfGoods] = useState(0)
  const [global, setGlobal] = useState({ current: 1 })

  const [salesPrice, setSalesPrice] = useState(0)
  const [reducedCarbonPremium, setReducedCarbonPremium] = useState(0)

  const handleGlobalChange = (value) => {
    setGlobal({ current: value })
  }

  const handleTargetReductionChange = (event) => {
    const value = event.target.value || 0
    if (/^\d*\.?\d*$/.test(value)) {
      if (value >= 0 && value <= 100) {
        setTargetReduction(value.toString())
      }
    }
  }

  const handleCostOfCarbonChange = (event) => {
    const value = event.target.value || 0
    if (/^\d*\.?\d*$/.test(value)) {
      setCostOfCarbon(value)
    }
  }

  const handleSalesPrice = (event) => {
    const value = event.target.value || 0
    if (/^\d*\.?\d*$/.test(value)) {
      setSalesPrice(value)
    }
  }

  const handleReducedCarbonPremium = (event) => {
    const value = event.target.value || 0
    if (/^\d*\.?\d*$/.test(value)) {
      setReducedCarbonPremium(value)
    }
  }

  const handleCostOfGoodsChange = (event) => {
    const value = event.target.value || 0
    if (/^\d*\.?\d*$/.test(value)) {
      setCostOfGoods(value)
    }
  }

  const handleSliderChange = (value) => {
    setTargetReduction(value)
  }

  const resetReduction = () => {
    setTargetReduction("")
  }
  const resetCostOfCarbon = () => {
    setCostOfCarbon(0)
  }
  const resetCostOfGoods = () => {
    setCostOfGoods()
  }
  const resetSalesPrice = () => {
    setSalesPrice(0)
  }
  const resetReducedCarbonPremium = () => {
    setReducedCarbonPremium(0)
  }

  const decarbonizationUnitCost =
    (costOfCarbon / 1000) * (targetReduction / 100) * genealogyItem.co2Intensity

  const decarbonizationEffort =
    decarbonizationUnitCost && costOfGoods ? (decarbonizationUnitCost / costOfGoods) * 100 : 0

  const decarbonizationPotential = parseFloat(genealogyItem.co2Emission * (targetReduction / 100))

  const salesPriceCarbonPremium =
    salesPrice && reducedCarbonPremium
      ? parseFloat(salesPrice) + parseFloat(reducedCarbonPremium)
      : 0

  const costCarbon = targetReduction
    ? parseFloat(reducedCarbonPremium) /
      (parseFloat(genealogyItem.co2Intensity / 1000) * parseFloat(targetReduction / 100))
    : 0

  const premiumBudget = parseFloat(decarbonizationPotential) * parseFloat(costCarbon / 1000)

  const [budget, setBudget] = useState(0)
  const updateBudget = () => {
    const budget = parseFloat(decarbonizationPotential) * parseFloat(costOfCarbon / 1000)
    setBudget(budget)
  }

  useEffect(() => {
    updateBudget()
  }, [targetReduction, costOfCarbon, decarbonizationPotential])

  const resetGlobal = () => {
    setGlobal({ current: 1 })
  }

  const resetBudget = () => {
    setBudget(0)
  }

  return (
    <>
      <CModal
        closeOnBackdrop={false}
        style={{
          borderRadius: 0,
          padding: 20,
          paddingLeft: 30,
          paddingRight: 30,
          borderColor: "white",
        }}
        show={showModal}
        onClose={() => {
          resetGlobal({})
          resetReduction({})
          resetCostOfCarbon({})
          resetCostOfGoods({})
          resetSalesPrice({})
          resetGlobal({})
          resetReducedCarbonPremium({})
          reset({})
          onClose()
        }}
      >
        <CForm>
          <CModalHeader className="modal-heading">
            <CModalTitle className="modal-heading-title">
              <img src={DecarbonizationIcon} className="heading-img" alt="copy" />
              Decarbonization Calculator
            </CModalTitle>
          </CModalHeader>
          <CModalBody className="modal-body">
            <CFormGroup>
              <CModalHeader
                style={{
                  marginTop: "-20px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CLabel
                  htmlFor="title"
                  style={{ marginLeft: "-10px", marginTop: "10px" }}
                  className="modal-span"
                >
                  {genealogyItem.title} - {numberFormat.localeString(genealogyItem.co2Emission)} kg
                  CO<sub>2</sub>e
                </CLabel>
                <CFormGroup style={{ marginLeft: "-10px", marginTop: "10px" }}>
                  <CLabel htmlFor="title" style={{}}>
                    Target Reduction
                  </CLabel>
                  <CRow>
                    <CCol md="4">
                      <CInput
                        id="targetReductionInput"
                        name="targetReductionInput"
                        style={{ marginRight: "50px", textAlign: "center" }}
                        className="modal-body-input-border-radius"
                        placeholder="0.00%"
                        defaultValue={targetReduction}
                        value={targetReduction}
                        onChange={handleTargetReductionChange}
                        innerRef={register}
                        invalid={!!errors.targetReductionInput}
                      />

                      <CInvalidFeedback className="help-block">
                        {errors.targetReductionInput?.message}
                      </CInvalidFeedback>
                    </CCol>
                    <CCol md="2">
                      <div className="unspecified-slider">
                        <SliderWithTooltip
                          style={{ width: "270px" }}
                          disabled={false}
                          trackStyle={{ backgroundColor: "#0188CE" }}
                          tipFormatter={percentFormatter}
                          defaultValue={targetReduction}
                          onAfterChange={handleSliderChange}
                        />
                      </div>
                    </CCol>
                  </CRow>
                  <CRow style={{ marginTop: "20px" }}>
                    <CCol>
                      <CFormGroup variant="checkbox">
                        <CInputRadio
                          className="form-check-input"
                          id="visibilityGlobal"
                          name="global"
                          defaultChecked={true}
                          onClick={() => handleGlobalChange(1)}
                        />
                        <CLabel variant="checkbox" htmlFor="visibilityGlobal">
                          Cost Of Carbon
                        </CLabel>
                      </CFormGroup>
                    </CCol>
                    <CCol>
                      <CFormGroup variant="checkbox">
                        <CInputRadio
                          className="form-check-input"
                          id="visibilityLocal"
                          name="global"
                          defaultChecked={false}
                          onClick={() => handleGlobalChange(0)}
                        />
                        <CLabel variant="checkbox" htmlFor="visibilityLocal">
                          Price Premium
                        </CLabel>
                      </CFormGroup>
                    </CCol>
                  </CRow>
                  {global.current === 1 ? (
                    <>
                      <CRow style={{ marginTop: "20px" }}>
                        <CCol>
                          <CLabel htmlFor="title">Cost of Carbon</CLabel>
                          <CInputGroup>
                            <CInput
                              id="carbonCreditCost"
                              name="carbonCreditCost"
                              style={{ textAlign: "right" }}
                              className="modal-body-input-border-radius"
                              onChange={handleCostOfCarbonChange}
                              defaultValue="0"
                              value={costOfCarbon}
                            />
                            <CInputGroupAppend>
                              <CInputGroupText
                                className={"input-append"}
                                style={{
                                  backgroundColor: "#0188CE",
                                  marginRight: "-12px",
                                }}
                              >
                                $/mt CO<sub>2</sub>e
                              </CInputGroupText>
                            </CInputGroupAppend>
                          </CInputGroup>
                        </CCol>
                        <CCol>
                          <CLabel htmlFor="title">Cost of Goods Sold</CLabel>
                          <CInputGroup>
                            <CInput
                              id="costOfGoodsSold"
                              name="costOfGoodsSold"
                              style={{ textAlign: "right" }}
                              className="modal-body-input-border-radius"
                              onChange={handleCostOfGoodsChange}
                              defaultValue="0"
                              value={costOfGoods}
                            />
                            <CInputGroupAppend>
                              <CInputGroupText
                                className={"input-append"}
                                style={{
                                  backgroundColor: "#0188CE",
                                  marginRight: "-12px",
                                }}
                              >
                                $/ {genealogyItem.unit}
                              </CInputGroupText>
                            </CInputGroupAppend>
                          </CInputGroup>
                        </CCol>
                      </CRow>
                    </>
                  ) : (
                    <>
                      <CRow style={{ marginTop: "20px" }}>
                        <CCol>
                          <CLabel htmlFor="title">Current Sales Price</CLabel>
                          <CInputGroup>
                            <CInput
                              id="salesPrice"
                              name="salesPrice"
                              style={{ textAlign: "right" }}
                              className="modal-body-input-border-radius"
                              onChange={handleSalesPrice}
                              defaultValue="0"
                              value={salesPrice}
                            />
                            <CInputGroupAppend>
                              <CInputGroupText
                                className={"input-append"}
                                style={{
                                  backgroundColor: "#0188CE",
                                  marginRight: "-12px",
                                }}
                              >
                                $/{genealogyItem.unit}
                              </CInputGroupText>
                            </CInputGroupAppend>
                          </CInputGroup>
                        </CCol>
                        <CCol>
                          <CLabel htmlFor="title">Reduced Carbon Premium</CLabel>
                          <CInputGroup>
                            <CInput
                              id="reducedCarbonPremium"
                              name="reducedCarbonPremium"
                              style={{ textAlign: "right" }}
                              className="modal-body-input-border-radius"
                              onChange={handleReducedCarbonPremium}
                              defaultValue="0"
                              value={reducedCarbonPremium}
                            />
                            <CInputGroupAppend>
                              <CInputGroupText
                                className={"input-append"}
                                style={{
                                  backgroundColor: "#0188CE",
                                  marginRight: "-12px",
                                }}
                              >
                                $/ {genealogyItem.unit}
                              </CInputGroupText>
                            </CInputGroupAppend>
                          </CInputGroup>
                        </CCol>
                      </CRow>
                    </>
                  )}
                </CFormGroup>
              </CModalHeader>
              {global.current === 1 ? (
                <>
                  <CLabel htmlFor="title" style={{ marginTop: "20px" }}>
                    Decarbonization Unit Cost
                  </CLabel>
                  <CInputGroup>
                    <CInput
                      style={{ textAlign: "right" }}
                      className="modal-body-input-border-radius"
                      readOnly={true}
                      value={numberFormat.localeString(decarbonizationUnitCost)}
                    />
                    <CInputGroupAppend>
                      <CInputGroupText
                        className={"input-append"}
                        style={{
                          backgroundColor: "#0188CE",
                        }}
                      >
                        $/ {genealogyItem.unit}
                      </CInputGroupText>
                    </CInputGroupAppend>
                  </CInputGroup>
                  <CInvalidFeedback className="help-block">
                    {errors.title?.message}
                  </CInvalidFeedback>

                  <CLabel htmlFor="title" style={{ marginTop: "20px" }}>
                    Decarbonization Effort
                  </CLabel>
                  <CInputGroup>
                    <CInput
                      style={{ textAlign: "right" }}
                      className="modal-body-input-border-radius"
                      readOnly={true}
                      value={numberFormat.localeString(decarbonizationEffort)}
                    />
                    <CInputGroupAppend>
                      <CInputGroupText
                        className={"input-append"}
                        style={{
                          backgroundColor: "#0188CE",
                        }}
                      >
                        + % Sale Price
                      </CInputGroupText>
                    </CInputGroupAppend>
                  </CInputGroup>
                  <CInvalidFeedback className="help-block">
                    {errors.title?.message}
                  </CInvalidFeedback>
                </>
              ) : (
                <>
                  <CLabel htmlFor="title" style={{ marginTop: "20px" }}>
                    Sales price with low carbon premium
                  </CLabel>
                  <CInputGroup>
                    <CInput
                      style={{ textAlign: "right" }}
                      className="modal-body-input-border-radius"
                      readOnly={true}
                      value={numberFormat.localeString(salesPriceCarbonPremium)}
                    />
                    <CInputGroupAppend>
                      <CInputGroupText
                        className={"input-append"}
                        style={{
                          backgroundColor: "#0188CE",
                        }}
                      >
                        $/ {genealogyItem.unit}
                      </CInputGroupText>
                    </CInputGroupAppend>
                  </CInputGroup>
                  <CInvalidFeedback className="help-block">
                    {errors.title?.message}
                  </CInvalidFeedback>

                  <CLabel htmlFor="title" style={{ marginTop: "20px" }}>
                    Cost of carbon
                  </CLabel>
                  <CInputGroup>
                    <CInput
                      style={{ textAlign: "right" }}
                      className="modal-body-input-border-radius"
                      readOnly={true}
                      value={numberFormat.localeString(costCarbon)}
                    />
                    <CInputGroupAppend>
                      <CInputGroupText
                        className={"input-append"}
                        style={{
                          backgroundColor: "#0188CE",
                        }}
                      >
                        $/mt CO<sub>2</sub>e
                      </CInputGroupText>
                    </CInputGroupAppend>
                  </CInputGroup>
                  <CInvalidFeedback className="help-block">
                    {errors.title?.message}
                  </CInvalidFeedback>
                </>
              )}

              <CLabel htmlFor="title" style={{ marginTop: "20px" }}>
                Reduction Potential (per reporting period)
              </CLabel>
              <CInputGroup>
                <CInput
                  style={{ textAlign: "right" }}
                  className="modal-body-input-border-radius"
                  readOnly={true}
                  value={numberFormat.localeString(decarbonizationPotential)}
                />
                <CInputGroupAppend>
                  <CInputGroupText
                    className={"input-append"}
                    style={{
                      backgroundColor: "#0188CE",
                    }}
                  >
                    kg CO<sub>2</sub>e
                  </CInputGroupText>
                </CInputGroupAppend>
              </CInputGroup>
              <CInvalidFeedback className="help-block">{errors.title?.message}</CInvalidFeedback>

              <CLabel htmlFor="title" style={{ marginTop: "20px" }}>
                Reduction Budget (per reporting period)
              </CLabel>

              <CInputGroup>
                <CInput
                  style={{ textAlign: "right" }}
                  className="modal-body-input-border-radius"
                  readOnly={true}
                  value={numberFormat.localeString(global.current === 1 ? budget : premiumBudget)}
                />
                <CInputGroupAppend>
                  <CInputGroupText
                    className={"input-append"}
                    style={{
                      backgroundColor: "#0188CE",
                      width: "80px",
                      paddingLeft: "17px",
                    }}
                  >
                    $ spent
                  </CInputGroupText>
                </CInputGroupAppend>
              </CInputGroup>
            </CFormGroup>
          </CModalBody>
          <CModalFooter className="modal-footer-border-width">
            <div className="modal-button-container text-center">
              <div className="modal-button-wrapper modal-button-padding-right text-align">
                <CButton
                  className="btn-pill btn-cancel"
                  onClick={() => {
                    resetGlobal({})
                    resetReduction({})
                    resetCostOfCarbon({})
                    resetCostOfGoods({})
                    resetSalesPrice({})
                    resetGlobal({})
                    resetReducedCarbonPremium({})
                    onClose()
                    reset({})
                    resetBudget()
                  }}
                >
                  Cancel
                </CButton>
              </div>
            </div>
          </CModalFooter>
        </CForm>
      </CModal>
    </>
  )
}
export default ProductExplorerCalculator
