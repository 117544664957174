import { useDispatch, useSelector } from "react-redux"
import HeadingError from "src/assets/icons/heading-error.svg"
import NotAllowed from "src/assets/icons/icon-reject.svg"
import { setErrorMessage } from "src/redux/slices/uiSlice"
import EcoInventLimit from "src/assets/icons/heading-ecoinvent-logo.svg"
import HeadingCarbonsigLogo from "src/assets/icons/heading-carbon-sig-logo.svg"
import TheModal from "./TheModal"

const TheErrorModal = () => {
  const dispatch = useDispatch()
  const errorMessage = useSelector((state) => state.ui.errorMessage)
  const errorType = useSelector((state) => state.ui.errorType)
  const handleClose = () => dispatch(setErrorMessage(""))

  let title = "Error!"
  let icon = NotAllowed
  let headerIcon = HeadingError
  if(errorType === "ECOINVENT_LIMIT"){
    title = "Upgrade your subscription";
    icon = EcoInventLimit;
    headerIcon = HeadingCarbonsigLogo
  }


  return (
    <div className="the-error-modal" style={{ zIndex: 9999 }}>
      <TheModal
        show={errorMessage !== ""}
        onClose={handleClose}
        title={title}
        message={errorMessage}
        headerIcon= {headerIcon}
        icon={icon}
        btnTitle="Close"
        btnClasses="btn-pill btn-filter btn-filter-secondary"
      />
    </div>
  )
}

export default TheErrorModal
