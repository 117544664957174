import { useEffect, useState } from "react"

const useWindowHeight = () => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)
  const handleWindowResize = () => {
    if (windowHeight === window.innerHeight) return
    setWindowHeight(window.innerHeight)
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize)

    return () => {
      window.removeEventListener("resize", handleWindowResize)
    }
  })

  return { windowHeight }
}

export default useWindowHeight
