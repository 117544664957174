import React from "react"
import { Redirect, Route } from "react-router-dom"

const Login = React.lazy(() => import("./views/pages/login/Login"))

const PrivateRoute = ({ component: Component, ...rest }) => {
  const token = localStorage.getItem("jwt-token")
  const isLoggedIn = token !== null ? true : false

  return isLoggedIn ? (
    <Route {...rest} />
  ) : (
    <Route render={(props) => <Redirect to={{ pathname: "/login" }} />} />
  )
}

export default PrivateRoute
