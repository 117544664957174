import { CCard, CCardBody } from "@coreui/react"
import dagre from "dagre"
import { useCallback, useEffect, useRef, useState } from "react"
import ReactFlow, { Controls, isNode, useViewport } from "reactflow"
import "reactflow/dist/style.css"
import { debounce } from "src/helpers/Helpers"
import numberFormat from "src/helpers/NumberFormat"
import HeadingGenealogy from "src/assets/icons/heading-genealogy.svg"
import useWindowHeight from "src/hooks/useWindowHeight"
import jwtDecode from "jwt-decode"
import { useDispatch } from "react-redux"
import { setErrorMessage } from "src/redux/slices/uiSlice"
import InventoryGenealogyObfuscateModal from "src/views/inventory/InventoryGenealogyObfuscateModal"
import ShareIcon from "src/reusable/ShareIcon"
import ProcessSystem from "src/views/process-system/list/ProcessSystem"
import ProcessSystemLegend from "src/views/process-system/ProcessSystemLegend"
import GenealogyLegend from "src/views/genealogy/GenealogyLegend"

const ProcessSystemRunPreviewProcessGenealogy = ({
  genealogy,
  title,
  onSelect,
  onDeselect,
  isObfuscation,
  onObfuscateChange,
  initialObfuscationItems,
  initialObfuscationProcesses,
  shareType,
  showShareIcon,
}) => {
  if (!initialObfuscationItems) {
    initialObfuscationItems = []
  }
  if (!initialObfuscationProcesses) {
    initialObfuscationProcesses = []
  }
  const [obfuscatingProcesses, setObfuscatingProcesses] = useState(initialObfuscationProcesses)
  const [obfuscatingItems, setObfuscatingItems] = useState(initialObfuscationItems)
  const [showTitleModal, setShowTitleModal] = useState(false)
  const [obfuscatingId, setObfuscatingId] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    setGenealogyTree(genealogy)
    setCurrentTree(genealogy)
    generateTree(genealogy)
  }, [genealogy])

  if (!title) {
    title = `Product details for ${genealogy.title}`
  }
  if (isObfuscation === undefined) {
    isObfuscation = false
  }
  if (!onObfuscateChange) {
    onObfuscateChange = () => {}
  }

  const dagreGraph = new dagre.graphlib.Graph()
  dagreGraph.setDefaultEdgeLabel(() => ({}))
  const [genealogyTree, setGenealogyTree] = useState({})
  const [currentTree, setCurrentTree] = useState({})
  const [flowElementsInitial, setFlowElementsInitial] = useState([])
  const [flowElements, setFlowElements] = useState([])
  const [flowEdges, setFlowEdges] = useState([])
  const [selectedObjectId, setSelectedObjectId] = useState(null)
  const [focusActive, setFocusActive] = useState(false)
  const [focused, setFocused] = useState(false)
  const [productName, setProductName] = useState("")
  const flowInstance = useRef(false)
  const genealogyRef = useRef()
  let nodeCount = 0

  const getTenantId = () => {
    let tenantId = false
    if (localStorage.getItem("jwt-token")) {
      const decodedToken = jwtDecode(localStorage.getItem("jwt-token"))
      tenantId = parseInt(decodedToken.tenantid, 10)
    }
    return tenantId
  }
  const currentTenantId = getTenantId()

  const getEconomicCategorizationImage = (categoryId) => {
    const images = {
      1: "A. Agriculture; forestry and fishing.svg",
      2: "B. Mining and quarrying.svg",
      3: "C. Manufacturing.svg",
      4: "D. Electricity; gas, steam and air conditioning supply.svg",
      5: "E. Water supply; sewerage, waste management and remediation activities.svg",
      6: "F. Construction.svg",
      7: "G. Wholesale and retail trade; repair of motor vehicles and motorcycles.svg",
      8: "H. Transportation and storage.svg",
      9: "I. Accommodation and food service activities.svg",
      10: "J. Information and communication.svg",
      11: "K. Financial and insurance activities.svg",
      12: "L. Real estate activities.svg",
      13: "M. Professional, scientific and technical activities.svg",
      14: "N. Administrative and support service activities.svg",
      15: "O. Public administration and defense; compulsory social security.svg",
      16: "P. Education.svg",
      17: "Q. Human health and social work activities.svg",
      18: "R. Arts, entertainment and recreation.svg",
      19: "S. Other service activities.svg",
      20: "T. Activities of households as employers; undifferentiated goods- and services-producing activities of households for own use.svg",
      21: "U. Activities of extraterritorial organizations and bodies.svg",
      22: "X. Not elsewhere classified.svg",
    }
    const basePath = "/economic-categorization"
    const image = images[categoryId] ?? images[22]
    return `${basePath}/${image}`
  }

  const elementStyle = {
    padding: "5px",
    borderRadius: "8px",
    display: "inline-block",
    border: "1px solid #999",
    boxShadow: "0px 3px 6px #00000029",
    color: "#444443",
  }
  const focusableStyle = {
    border: "2px solid #4FA87F",
  }
  const nonFocusableStyle = {
    opacity: 0.3,
  }
  const processStyle = {
    borderRadius: "8px",
    display: "inline-block",
    border: "1px solid #0188CE",
    boxShadow: "0px 3px 6px #00000029",
    padding: "10px",
  }
  const edgeStyle = {
    stroke: "#999",
    strokeWidth: "1px",
  }
  const selectedStyle = {
    backgroundColor: "#0071AB",
    border: "1px solid #0071AB",
  }

  const generateElementLabel = (
    title,
    co2Emission,
    co2SinksEmission,
    co2Intensity,
    quantity,
    unit,
    published,
    obfuscating,
    obfuscatingProcess,
    obfuscated,
    obfuscatedProcess,
    externalLci
  ) => {
    const intensityLabel =
      quantity > 0 ? `${numberFormat.localeString(co2Intensity)} kg CO₂e / ${unit}` : ""
    let labelHtml = `<div class='genealogy-title'>${title}</div>
      <div class='genealogy-text'>${numberFormat.localeString(
        co2Emission - co2SinksEmission
      )} kg CO₂e</div>`
    if (((obfuscated || obfuscating) && !obfuscatedProcess && !obfuscatingProcess) || externalLci) {
      labelHtml += `<div class='genealogy-text genealogy-text-intensity'>&nbsp</div>`
    } else {
      labelHtml += `<div class='genealogy-text genealogy-text-intensity'>${intensityLabel}</div>`
    }
    if (published && !obfuscating && !obfuscated) {
      labelHtml += `<div class='genealogy-published'><img src='/icon-published.svg' alt='logo' /></div>`
    }
    if (obfuscating || obfuscated) {
      if (obfuscatingProcess || obfuscatedProcess) {
        labelHtml += `<div class='genealogy-obfuscating-icon genealogy-obfuscating-icon-process'><img src='/obfuscated.svg' alt='logo' /></div>`
      } else {
        labelHtml += `<div class='genealogy-obfuscating-icon'><img src='/obfuscated.svg' alt='logo' /></div>`
      }
    }
    return <div dangerouslySetInnerHTML={{ __html: labelHtml }}></div>
  }

  const generateProcessLabel = (title, co2Emission, co2SinksEmission, categoryId) => {
    const logoUrl = getEconomicCategorizationImage(categoryId)
    const labelHtml = `<div class='genealogy-title'>${title}</div>
    <div class='genealogy-text'>${numberFormat.localeString(
      co2Emission - co2SinksEmission
    )} kg CO₂e</div>
    <div class='genealogy-logo' style='background-image: url("${logoUrl}")'></div>`
    return <div dangerouslySetInnerHTML={{ __html: labelHtml }}></div>
  }

  const generateElement = (
    id,
    uid,
    uniqueId,
    title,
    quantity,
    co2Intensity,
    co2Emission,
    co2EmissionProcess,
    co2EmissionValueChain,
    co2EmissionSite,
    co2SinksEmission,
    co2SinksProcess,
    co2SinksValueChain,
    co2SinksSite,
    unit,
    published,
    tenantId,
    processTitle,
    processTenant,
    designation,
    transferred,
    leaf,
    root,
    obfuscating,
    obfuscatingRootId,
    obfuscated,
    obfuscatedProcess,
    externalLci,
    idPerGenealogy,
    credits,
    attestationUnitId
  ) => {
    let className = "genealogy-element"
    let style = { ...elementStyle }

    if (obfuscatingItems.map((x) => x.id).includes(idPerGenealogy)) {
      className += " obfuscation-selected"
      title = obfuscatingItems.find((x) => x.id === idPerGenealogy).title
    }
    if (obfuscatingProcesses.includes(idPerGenealogy)) {
      className += " obfuscation-selected-process"
    }
    if (obfuscated && !obfuscatedProcess) {
      className += " obfuscated"
    }
    if (obfuscatedProcess) {
      className += " obfuscated-process"
    }
    if (obfuscating) {
      style = { ...style, ...nonFocusableStyle }
    }

    return {
      id: "item-" + uniqueId,
      type: "default",
      data: {
        label: generateElementLabel(
          title,
          co2Emission,
          co2SinksEmission,
          co2Intensity,
          quantity,
          unit,
          published,
          obfuscatingItems.map((x) => x.id).includes(idPerGenealogy) ||
            obfuscatingProcesses.includes(idPerGenealogy),
          obfuscatingProcesses.includes(idPerGenealogy),
          obfuscated,
          obfuscatedProcess,
          externalLci
        ),
        id: id,
        uid: uid,
        type: "inventory",
        quantity: quantity,
        co2Emission: co2Emission,
        co2EmissionProcess: co2EmissionProcess,
        co2EmissionValueChain: co2EmissionValueChain,
        co2EmissionSite: co2EmissionSite,
        co2SinksEmission: co2SinksEmission,
        co2SinksProcess: co2SinksProcess,
        co2SinksValueChain: co2SinksValueChain,
        co2SinksSite: co2SinksSite,
        co2Intensity: co2Intensity,
        published: published,
        tenantId: tenantId,
        processTitle: processTitle,
        processTenant: processTenant,
        designation: designation,
        transferred: transferred,
        leaf: leaf,
        root: root,
        obfuscatingRootId: obfuscatingRootId,
        obfuscated: obfuscated,
        obfuscatedProcess: obfuscatedProcess,
        title: title,
        idPerGenealogy: idPerGenealogy,
        unit: unit,
        credits: credits,
        attestationUnitId: attestationUnitId,
        externalLci: externalLci,
      },
      style: style,
      className: className,
    }
  }

  const generateProcessElement = (
    id,
    uniqueId,
    title,
    co2Emission,
    co2SinksEmission,
    tenantId,
    obfuscating,
    obfuscatingRootId,
    categoryId,
    idPerGenealogy
  ) => {
    let style = { ...processStyle }
    if (focusActive) {
      style = { ...style, ...nonFocusableStyle }
    }
    if (obfuscating) {
      style = { ...style, ...nonFocusableStyle }
    }
    let className = "genealogy-element genealogy-element-non-focusable"
    return {
      id: "process-" + uniqueId,
      type: "default",
      data: {
        label: generateProcessLabel(title, co2Emission, co2SinksEmission, categoryId),
        id: id,
        type: "process",
        co2Emission: co2Emission,
        co2SinksEmission: co2SinksEmission,
        tenantId: tenantId,
        obfuscatingRootId: obfuscatingRootId,
        idPerGenealogy: idPerGenealogy,
      },
      style: style,
      className: className,
    }
  }

  const generateEdge = (id, source, target) => {
    return {
      id: id,
      source: source,
      target: target,
      type: "step",
      style: edgeStyle,
    }
  }

  const getLayoutedElements = (elements, direction = "TB") => {
    const isHorizontal = direction === "LR"
    dagreGraph.setGraph({ rankdir: direction })
    let nodeWidth = 0
    let nodeHeight = 0
    elements.forEach((el) => {
      nodeWidth = 172
      nodeHeight = 36
      if (el.data) {
        let labelLength = 1
        if (el.data.label instanceof Object) {
          labelLength = el.data.label.props.dangerouslySetInnerHTML.__html.length
        } else {
          labelLength = el.data.label.length
        }
        nodeHeight = Math.ceil(labelLength / 100) * 55
      }
      if (isNode(el)) {
        dagreGraph.setNode(el.id, { width: nodeWidth, height: nodeHeight })
      } else {
        dagreGraph.setEdge(el.source, el.target)
      }
    })

    dagre.layout(dagreGraph)

    return elements.map((el) => {
      if (isNode(el)) {
        const nodeWithPosition = dagreGraph.node(el.id)
        el.targetPosition = isHorizontal ? "left" : "top"
        el.sourcePosition = isHorizontal ? "right" : "bottom"

        // unfortunately we need this little hack to pass a slightly different position
        // to notify react flow about the change. Moreover we are shifting the dagre node position
        // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
        el.position = {
          x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000,
          y: nodeWithPosition.y - nodeHeight / 2,
        }
      }

      return el
    })
  }

  const getProcessData = (inventory, isRoot) => {
    let processTitle = ""
    let processTenant = ""
    if (inventory.transferred && !isRoot) {
      processTenant = inventory.tenantTitle
    } else {
      if (inventory.process) {
        processTitle = inventory.process.title
        processTenant = inventory.process.tenantTitle
      } else {
        processTenant = inventory.tenantTitle
      }
    }
    return {
      title: processTitle,
      tenantTitle: processTenant,
    }
  }

  const generateTree = (rootInventory) => {
    const processData = getProcessData(rootInventory, true)
    const elements = []
    const nextElementId = ++nodeCount
    const rootElement = generateElement(
      rootInventory.id,
      rootInventory.id,
      nextElementId,
      rootInventory.title,
      rootInventory.quantity,
      rootInventory.co2Intensity,
      rootInventory.co2Emission,
      rootInventory.co2EmissionProcess,
      rootInventory.co2EmissionValueChain,
      rootInventory.co2EmissionSite,
      rootInventory.co2SinksEmission,
      rootInventory.co2SinksProcess,
      rootInventory.co2SinksValueChain,
      rootInventory.co2SinksSite,
      rootInventory.unit,
      rootInventory.published,
      rootInventory.tenantId,
      processData.title,
      processData.tenantTitle,
      "",
      false,
      false,
      true,
      false,
      false,
      false,
      false,
      rootInventory.externalLci,
      rootInventory.id,
      rootInventory.credits,
      rootInventory.attestationUnitId
    )
    elements.push(rootElement)
    traverse(rootInventory, elements, false, false, nextElementId)
    const layoutedElements = getLayoutedElements(elements, "TB")

    const flowElementsTmp = []
    const flowEdgesTmp = []

    layoutedElements.forEach((el) =>
      isNode(el) ? flowElementsTmp.push(el) : flowEdgesTmp.push(el)
    )

    setFlowElements(flowElementsTmp)
    setFlowElementsInitial(flowElementsTmp)
    setFlowEdges(flowEdgesTmp)
  }

  const traverse = (current, elements, obfuscating, obfuscatingRootId, uniqueId) => {
    if (current.children && current.children.length > 0) {
      const isCurrentElementObfuscating =
        obfuscatingItems.map((x) => x.id).includes(current.id) ||
        obfuscatingProcesses.includes(current.id)
      obfuscating = obfuscating || isCurrentElementObfuscating
      if (!obfuscatingRootId && obfuscating) {
        obfuscatingRootId = current.id
      }
      const nextProcessId = ++nodeCount
      const processElement = generateProcessElement(
        current.process.id,
        nextProcessId,
        current.process.title,
        current.process.co2Emission,
        current.process.co2SinksEmission,
        current.process.tenantId,
        obfuscating,
        obfuscatingRootId,
        current.process.economicCategorizationCategoryId,
        current.id
      )
      elements.push(processElement)
      const processEdge = generateEdge(
        "edge-" + uniqueId + "-" + nextProcessId,
        "item-" + uniqueId,
        "process-" + nextProcessId
      )
      elements.push(processEdge)
      current.children.forEach((element) => {
        const nextElementId = ++nodeCount
        const processData = getProcessData(element, false)
        const leaf = element.process === null
        const currentElement = generateElement(
          element.id,
          element.id,
          nextElementId,
          element.title,
          element.quantity,
          element.co2Intensity,
          element.co2Emission,
          element.co2EmissionProcess,
          element.co2EmissionValueChain,
          element.co2EmissionSite,
          element.co2SinksEmission,
          element.co2SinksProcess,
          element.co2SinksValueChain,
          element.co2SinksSite,
          element.unit,
          element.published,
          element.tenantId,
          processData.title,
          processData.tenantTitle,
          element.designation,
          element.transferred,
          leaf,
          false,
          obfuscating,
          obfuscatingRootId,
          element.obfuscated,
          element.obfuscatedProcess,
          element.externalLci,
          element.id,
          element.credits,
          element.attestationUnitId
        )
        elements.push(currentElement)
        const currentEdge = generateEdge(
          "edge-" + nextProcessId + "-" + nextElementId,
          "process-" + nextProcessId,
          "item-" + nextElementId
        )
        elements.push(currentEdge)
        traverse(element, elements, obfuscating, obfuscatingRootId, nextElementId)
      })
    }
  }

  const searchTree = (element, id, searchByProcessId) => {
    let condition = false
    if (searchByProcessId) {
      condition = element.process && element.process.id == id
    } else {
      condition = element.uid === id
    }
    if (condition) {
      return element
    } else if (element.process) {
      let i
      let result = null
      for (i = 0; result == null && i < element.children.length; i++) {
        result = searchTree(element.children[i], id, searchByProcessId)
      }
      return result
    }
    return null
  }

  const searchProcess = (element, id) => searchTree(element, id, true)

  const setSelectedClass = (id) => {
    let flowElementsTmp = [...flowElementsInitial]
    flowElementsTmp = flowElementsTmp.map((a) => ({ ...a }))
    const selectedElement = flowElementsTmp.find((x) => x.id === id)
    const selectedClassName = "genealogy-selected"
    selectedElement.className = selectedClassName
    if (selectedElement.id.startsWith("process")) {
      selectedElement.style = { ...processStyle, ...selectedStyle }
    } else {
      selectedElement.style = { ...elementStyle, ...selectedStyle }
    }
    setFlowElements(flowElementsTmp)
  }

  const handleGenealogyClick = (event, object, focusActive) => {
    if (focusActive) {
      if (object.data.type === "inventory") {
      }
    } else {
      let subTree = {}
      if (object.data.type === "process") {
        subTree = searchProcess(genealogyTree, object.data.id)
      }
      setSelectedClass(object.id)
      if (onSelect) {
        onSelect(object, subTree)
      }
    }
  }

  const handleObfuscationClick = (event, object) => {
    const objectIdParts = object.id.split("-")
    const id = parseInt(objectIdParts[1], 10)
    if (object.data.obfuscatingRootId) {
      setObfuscatingProcesses(
        obfuscatingProcesses.filter((x) => x !== object.data.obfuscatingRootId)
      )
      setObfuscatingItems(obfuscatingItems.filter((x) => x.id !== object.data.obfuscatingRootId))
    } else if (
      obfuscatingItems.map((x) => x.id).includes(object.data.idPerGenealogy) ||
      obfuscatingProcesses.includes(object.data.idPerGenealogy)
    ) {
      setObfuscatingProcesses(obfuscatingProcesses.filter((x) => x !== object.data.idPerGenealogy))
      setObfuscatingItems(obfuscatingItems.filter((x) => x.id !== object.data.idPerGenealogy))
    } else {
      if (obfuscationAllowed(object)) {
        let subTree = false
        if (object.data.type === "process") {
          subTree = searchProcess(genealogyTree, object.data.id)
        } else {
          subTree = searchTree(genealogyTree, object.data.uid, false)
        }
        if (subTree) {
          removeObfuscationRecursively(subTree)
        }
        if (object.data.type === "process") {
          setObfuscatingProcesses((prev) => [...prev, object.data.idPerGenealogy])
        } else {
          setObfuscatingId(object.data.idPerGenealogy)
          setShowTitleModal(true)
        }
      }
    }
  }
  const removeObfuscationRecursively = (current) => {
    setObfuscatingProcesses((prev) => prev.filter((x) => x !== current.id))
    setObfuscatingItems((prev) => prev.filter((x) => x.id !== current.id))
    if (current.children && Object.keys(current.children).length !== 0) {
      current.children.forEach((element) => {
        removeObfuscationRecursively(element)
      })
    }
  }

  const obfuscationAllowed = (object) => {
    if (object.data.tenantId !== currentTenantId) {
      const errorMessage = "Cannot obfuscate processes/items from another tenant"
      dispatch(setErrorMessage(errorMessage))
      return false
    }
    if (object.data.type === "inventory" && object.data.id === genealogy.id) {
      const errorMessage = "Cannot obfuscate root item"
      dispatch(setErrorMessage(errorMessage))
      return false
    }
    if (object.data.obfuscated) {
      const errorMessage = "Cannot obfuscate already obfuscated item"
      dispatch(setErrorMessage(errorMessage))
      return false
    }
    return true
  }

  const deselect = () => {
    setSelectedObjectId(null)
    setFlowElements(flowElementsInitial)
    if (onDeselect) {
      onDeselect()
    }
  }

  useEffect(() => {
    if (flowElementsInitial.length) {
      setSelectedObjectId(flowElements[0].id)
      setSelectedClass(flowElements[0].id)
      onSelect(flowElements[0], {})
    }
  }, [flowElementsInitial])

  useEffect(() => {
    if (currentTree.title) {
      setProductName(currentTree.title)
    }
  }, [currentTree])

  useEffect(() => {
    if (flowElements.length && flowElementsInitial.length) {
      generateTree(currentTree)
    }
  }, [obfuscatingItems, obfuscatingProcesses])

  useEffect(() => {
    onObfuscateChange([...obfuscatingItems], [...obfuscatingProcesses])
  }, [obfuscatingItems, obfuscatingProcesses])

  const { windowHeight } = useWindowHeight()

  const debouncedFitView = useCallback(
    debounce(() => setTimeout(() => flowInstance.current.fitView(), 10), 100),
    []
  )

  useEffect(() => {
    if (flowInstance.current) {
      debouncedFitView()
    }
  }, [windowHeight])

  const genealogyHeight = windowHeight - 287

  const getPageStyle = () => {
    return `@media print {
    .flow-controls, .genealogy-action {
      display: none;
    }`
  }

  const ViewportComp = () => {
    const { x, y, zoom } = useViewport()

    return (
      <style type="text/css">
        {`.react-flow__viewport {
            width: calc(100% / ${zoom});
            height: ${genealogyHeight / zoom}px;
          }`}
      </style>
    )
  }

  return (
    <>
      <CCard className="cs-card">
        <CCardBody>
          <div className="heading d-flex align-items-center justify-content-between mb-2">
            <div style={{ flex: 2, lineHeight: 1 }}>
              <img src={HeadingGenealogy} className="heading-img" alt="icon-input" />
              {title}
            </div>
            {shareType !== false && showShareIcon ? (
              <div style={{ marginRight: "20px" }}>
                <ShareIcon shareType={shareType} width={20} />
              </div>
            ) : (
              ""
            )}
          </div>

          {flowElements.length ? (
            <div
              style={{
                width: "100%",
                height: genealogyHeight,
                position: "relative",
              }}
              className={`genealogy-flow ${focused} ? "genealogy-container-focused" : ""`}
              ref={genealogyRef}
            >
              <style type="text/css" media="print">
                {getPageStyle()}
              </style>
              <div style={{ position: "absolute", top: "8px", left: "8px" }}>
                <GenealogyLegend />
              </div>
              <ReactFlow
                nodes={flowElements}
                edges={flowEdges}
                onInit={(instance) => {
                  flowInstance.current = instance
                }}
                onNodeClick={(event, object) => {
                  if (selectedObjectId === object.id) {
                    deselect()
                  } else {
                    if (isObfuscation) {
                      handleObfuscationClick(event, object)
                    } else {
                      handleGenealogyClick(event, object, focusActive)
                    }
                    setSelectedObjectId(object.id)
                  }
                }}
                fitView
                zoomOnPinch={false}
                zoomOnScroll={false}
                nodesDraggable={false}
                elementsSelectable={false}
                panOnScroll={false}
                selectNodesOnDrag={false}
                nodesConnectable={false}
                minZoom={0.1}
                className={`genealogy-flow ${focusActive && "focusActive"}`}
                proOptions={{ hideAttribution: true }}
              >
                <div className="flow-controls">
                  <Controls showInteractive={false} className="genealogy-controls" />
                </div>
                {<ViewportComp />}
              </ReactFlow>
            </div>
          ) : (
            ""
          )}
        </CCardBody>
      </CCard>
      <InventoryGenealogyObfuscateModal
        onClosed={() => {
          setShowTitleModal(false)
        }}
        onChange={(title) => {
          setObfuscatingItems((prev) => [...prev, { id: obfuscatingId, title: title }])
          setObfuscatingId(false)
          setShowTitleModal(false)
        }}
        showModal={showTitleModal}
      />
    </>
  )
}

export default ProcessSystemRunPreviewProcessGenealogy
