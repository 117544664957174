import React from "react"
import ProductExplorerSidebarCredit from "./ProductExplorerSidebarCredit"

const ProductExplorerSidebarCredits = ({ ownerCredits, inheritedCredits, tenantData }) => {
  let hasOwn = ownerCredits.length > 0
  let hasInherited = inheritedCredits.length > 0

  const checkIfCraditsExists = () => {
    if(!hasOwn && !hasInherited) {
      return <>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <p>No data available.</p>
      </div>
      </>
    }
  }
  return (
    <div className="sidebar-credit-container">
      {checkIfCraditsExists()}
      {hasOwn && (
        <ProductExplorerSidebarCredit
          credits={ownerCredits}
          headTitle={"Assigned by product owner:"}
        />
      )}
      {hasInherited && (
        <ProductExplorerSidebarCredit
          credits={inheritedCredits}
          headTitle={"Inherited by value chain:"}
        />
      )}
    </div>
  )
}

export default ProductExplorerSidebarCredits
