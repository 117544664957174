import {
  setErrorMessage,
  setLoading,
  setSuccessMessage,
} from "src/redux/slices/uiSlice"

const apiCallWrapper = (function () {
  const beforeCall = (dispatch, config) => {
    if (config.showLoader) {
      dispatch(setLoading(true))
    }
    dispatch(setErrorMessage(""))
  }
  const afterSuccess = (dispatch, message) => {
    if (message) {
      dispatch(setSuccessMessage(message))
    }
    dispatch(setLoading(false))
  }
  const afterError = (dispatch, message, showErrorMessage, errorType) => {
    if (showErrorMessage && message) {
      dispatch(setErrorMessage({message:message,errorType: errorType}))
    }
    dispatch(setLoading(false))
  }
  const configMerge = (config) => {
    const defaults = {
      successMessage: "",
      successHandler: () => {},
      errorHandler: () => {},
      params: {},
      showErrorMessage: true,
      showLoader: true,
    }
    return Object.assign({}, defaults, config)
  }
  return {
    call: function (apiCall, dispatch, config) {
      config = configMerge(config)
      beforeCall(dispatch, config)
      return apiCall(
        config.params,
        (jsonData, status) => {
          config.successHandler(jsonData, status)
          afterSuccess(dispatch, config.successMessage)
        },
        (e, status, jsonData) => {
          config.errorHandler(e, status, jsonData)
          afterError(dispatch, e, config.showErrorMessage,jsonData.errorType)
        }
      )
    },
  }
})()
export default apiCallWrapper
