import React from "react"

const HeadSectionTitle = ({
  title,
  icon,
  additionalMarginBottom,
  imgPaddingLeftRight,
  imgPaddingTopBottom,
  innerRef,
}) => {
  return (
    <div
      style={{
        marginBottom: `${additionalMarginBottom}px`,
      }}
      ref={innerRef}
      className="display-row head-title-container"
    >
      <img
        style={{
          padding: `${imgPaddingTopBottom}px ${imgPaddingLeftRight}px`,
        }}
        src={icon}
      />
      <span className="content-head-box-title">{title}:</span>
    </div>
  )
}

export default HeadSectionTitle
