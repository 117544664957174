import React from "react"

const IOProcessTitle = ({ title, innerRef }) => {
  return (
    <div ref={innerRef} className="input-output-process-title">
      <span className="titles">{title}:</span>
    </div>
  )
}

export default IOProcessTitle
